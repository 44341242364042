import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { Garden, Dimensions } from './parts/winter_garden';

import { WinterGardenActiveConfiguration } from './WinterGardenActiveConfiguration';

export class WinterGardenConfiguration implements Configuration {
    $version = 5;
    type: 'winter_garden' = 'winter_garden';
    name = '';
    price: number | null = null;
    listPrice: number | null = null;
    priceNoMargin: number | null = null;
    listPriceNoMargin: number | null = null;
    priceAfterDiscountsInCurrency: number | null = null;
    listPriceAfterDiscountsInCurrency: number | null = null;
    priceBeforePromotions: number | null = null;
    listPriceBeforePromotions: number | null = null;
    priceGross: number | null = null;
    listPriceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    listPriceGrossAfterDiscounts: number | null = null;
    priceGrossBeforePromotions: number | null = null;
    listPriceGrossBeforePromotions: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    listPriceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    listPriceSegmentsNoMargin: PriceSegment[] = [];
    priceSegmentsBeforePromotions: PriceSegment[] = [];
    listPriceSegmentsBeforePromotions: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    listPriceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    listPricePartsNoMargin: PricePart[] = [];
    pricePartsBeforePromotions: PricePart[] = [];
    listPricePartsBeforePromotions: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    garden: Garden = null;
    dimensions: Dimensions = {};
    handleFacet: string = null;
    description: string = '';

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (WinterGardenConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (WinterGardenActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.listPrice = configuration.ListPrice;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.listPriceNoMargin = configuration.ListPriceNoMargin;
                this.priceAfterDiscountsInCurrency = configuration.PriceAfterDiscountsInCurrency || configuration.Price;
                this.listPriceAfterDiscountsInCurrency = configuration.PriceAfterDiscountsInCurrency || configuration.ListPrice;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.listPriceGross = configuration.PriceGross || configuration.ListPrice;
                this.priceGrossAfterDiscounts = configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.listPriceGrossAfterDiscounts = configuration.PriceGrossAfterDiscounts || configuration.ListPrice;
                this.priceBeforePromotions = configuration.PriceBeforePromotions;
                this.listPriceBeforePromotions = configuration.ListPriceBeforePromotions;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.listPriceSegments = configuration.ListPriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.listPriceSegmentsNoMargin = configuration.ListPriceSegmentsNoMargin;
                this.priceSegmentsBeforePromotions = configuration.PriceSegmentsBeforePromotions;
                this.listPriceSegmentsBeforePromotions = configuration.ListPriceSegmentsBeforePromotions;
                this.priceParts = configuration.PriceParts;
                this.listPriceParts = configuration.ListPriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.listPricePartsNoMargin = configuration.ListPricePartsNoMargin;
                this.pricePartsBeforePromotions = configuration.PricePartsBeforePromotions;
                this.listPricePartsBeforePromotions = configuration.ListPricePartsBeforePromotions;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.attributes = configuration.attributes;

                this.garden = {
                    id: Number(configuration.Garden.id),
                    name: configuration.Garden.name,
                };
                for (const label in configuration.Garden.dimensions) {
                    if (configuration.Garden.dimensions.hasOwnProperty(label)) {
                        this.dimensions[label] = Number(configuration.Garden.dimensions[label]);
                    }
                }
                this.handleFacet = configuration.Garden.handle_facet;
                this.description = configuration.Description;
            }
        }
    }

    public static is(configuration): configuration is WinterGardenConfiguration {
        return configuration instanceof WinterGardenConfiguration || configuration.$version;
    }
}
