<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'guideRail'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'profile'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'endslat'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'boxInner'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'boxGuideOuter'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'box'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'revision'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'boxSideSurface'}"></ng-container>

<ng-template #colorBoxTemplate let-type="type">
  <ng-container *ngTemplateOutlet="colorBox;context:{
    color: (colors$[type] | async),
    type: type,
    show: (colorsShow$[type]),
    title: colorsTitle[type] || ''
   }"></ng-container>
</ng-template>

<ng-template #colorBox let-color="color" let-type="type" let-show="show" let-title="title" >
    <icc-select-box
      [title]="title | translate"
      [description]="color?.name"
      [color]="'#' + (color?.color || '')"
      [imageUrl]="'/files/color/' + (color?.color_img || '')"
      [buttonText]="'INTERFACE|Zmień' | translate"
      [colorSelect]="true"
      *ngIf="show | async"
      (select)="openModalRollerColor(type)"
  ></icc-select-box>
</ng-template>
