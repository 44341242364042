import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { WindowFacade } from '../+state/window.facade';
import { IccFilling } from '@icc/common/data-types';
import { IccDoorPanelTrim } from '@icc/common/data-types/DoorPanelTrim';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { Subscription } from 'rxjs';
import { PriceBaseService } from '@icc/price/b2b';

@Component({
  selector: 'icc-panel-trims-list-page',
  templateUrl: './panel-trims-list-page.component.html',
  styleUrls: ['./panel-trims-list-page.component.scss']
})
export class PanelTrimsListPageComponent extends PageComponent implements OnInit {

    public title = _('DOOR|Listwy dekoracyjne');
    public options = [];

    doorPanelTrims: (iccListItem & {
        price: number;
        listPrice: number;
    })[] = [];
    selectedTrim: number | undefined;

    listPriceEnable = this.config().IccConfig.Offer.listPrice;
    listPriceEnableInMarket = this.config().listPriceEnableInMarket;

    currency: any = null;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            doorPanelTrims: IccDoorPanelTrim[];
            selectedTrim: IccDoorPanelTrim;
        },
        private priceBaseService: PriceBaseService,
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(this.sharedFacade.currency$.subscribe(currency => {
            this.currency = currency;
        }));

        this.doorPanelTrims = this.pageData.doorPanelTrims.map(trim => ({
            id: (trim.id),
            title: trim.name,
            imageUrl: `/files/doorpaneltrim/${trim.image}`,
            price: trim.price,
            listPrice: trim.list_price,
        }));
        this.selectedTrim = Number(this.pageData.selectedTrim && this.pageData.selectedTrim.id);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    select(item: iccListItem) {
        const trim = this.pageData.doorPanelTrims.find(f => (f.id) == (item.id));
        this.sharedFacade.closePage({
            trim,
        });
    }

    priceMarket(price: number) {
        return this.priceBaseService.addMarginMarketFactor(price);
    }

}
