import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';
import { Injectable, Inject } from '@angular/core';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { UserService } from '@icc/common/user.service';
import { core } from '@icc/common/Core';
import { PriceSegment } from '@icc/price';
import { ConfigurationsService } from '@icc/common';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { ExternalBlindActiveConfiguration } from '@icc/common/configurations/ExternalBlindActiveConfiguration';


@Injectable()
export class DriveEngineService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfigFactory,
    private userService: UserService,
    private parametersService: ParametersService,
    private configurationsService: ConfigurationsService
  ) {}

    separateEngines(drives, conf = this.configurationsService.conf.Current as RollerShutterActiveConfiguration | ExternalBlindActiveConfiguration) {
        let engineGroups = [];
        const roller = conf?.RollerShutter;
        if (drives?.length > 0){
            if (this.config().IccConfig.Configurators.roller_shutter.separateDrivesAboveWidthThreshold){

                let engineGroupCounter = 0;

                roller.shutters.forEach((shutter,index) => {
                    const nextWidth = roller.shutters.length > index + 1 ? roller.shutters[index + 1].realWidth : null;
                    engineGroups[engineGroupCounter]?.length > 0 ? engineGroups[engineGroupCounter].push(index) : engineGroups[engineGroupCounter] = [index];
                    if (!nextWidth || !roller.drive.one_drive_width_threshold || roller.drive.one_drive_width_threshold == 0 || Math.abs((nextWidth - shutter.realWidth)) / nextWidth > Number(roller.drive.one_drive_width_threshold) / 100 || roller.drive.type === 'manual') {
                        engineGroupCounter+=1;
                    }
                })
                conf.RollerShutter.drive.engineGroups = engineGroups;
                return engineGroups;
            } else {
                let engineGroupCounter = 0;
                roller.shutters.forEach((shutter,i) => {
                    if (
                        i !== 0 &&
                        !((shutter.commonRail
                        && (roller.system.common_guide_drive === 'one'
                            || (roller.system.common_guide_drive === 'one_same_width'
                                && (!roller.shutters[i - 1]
                                    || roller.shutters[i - 1].realWidth === shutter.realWidth)
                                && (!roller.shutters[i + 1]
                                    || roller.shutters[i + 1].realWidth === shutter.realWidth))))
                        || (!shutter.commonRail
                            && (roller.system.not_common_guide_drive === 'one'
                                || (roller.system.not_common_guide_drive === 'one_same_width'
                                    && (!roller.shutters[i - 1]
                                        || roller.shutters[i - 1].realWidth === shutter.realWidth)
                                    && (!roller.shutters[i + 1]
                                        || roller.shutters[i + 1].realWidth === shutter.realWidth)))))
                    ) {
                        engineGroupCounter+=1;
                    }
                    engineGroups[engineGroupCounter]?.length > 0 ? engineGroups[engineGroupCounter].push(i) : engineGroups[engineGroupCounter] = [i];
                });
                conf.RollerShutter.drive.engineGroups = engineGroups;
                return engineGroups;
            }
        }
        engineGroups = drives?.map((item,index) => [index]);
        roller.drive.engineGroups = engineGroups;
        return engineGroups;
    }

  separatedEnginesIndicators(engineGroupsIndicators){
        engineGroupsIndicators = engineGroupsIndicators?.map(e=>e.map(item=>{
            return ['A','B','C'][item];
        }).join(','))
        return engineGroupsIndicators;
  }

  getDriveEngine(rollerObject) {
        const priceSegments: PriceSegment[]  = [];
        rollerObject.drive.prices = core.objToArray(rollerObject.drive.prices || []);
        rollerObject.drive.prices.sort(function sortDrivePrices(a, b) {
            return (
                parseFloat(a.price.replace(',', '.')) - parseFloat(b.price.replace(',', '.'))
            );
        });
        let width = 0;
        let height = 0;
        let weight = 0;
        let pricesList = [];
        const roller = core.copy(rollerObject);
        roller.shutters.forEach((shutter, index) => {
            const shutterWeight = this.parametersService.getRollerWeight(roller, shutter);
            let drivePrice = 0;
            let engineName = '';

            weight += shutterWeight;
            width += shutter.realWidth;
            if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                height = shutter.realHeight + roller.realBoxHeight;
            } else {
                height = shutter.realHeight;
            }
            const nextWidth = roller.shutters.length > index + 1 ? roller.shutters[index + 1].realWidth : null;
                if (roller.drive.type === 'manual') {
                    drivePrice = parseFloat(roller.drive.price) * 1;
                } else {
                    pricesList.push(roller.drive.prices.filter(function filterDrivePrices(e) {
                        return (
                            weight <= Number(e.to.replace(',', '.'))
                            && (e.reel === roller.roundReel?.id || e.reel === '')
                            && ((roller.driveType === 'override' && ~~e.override)
                                || (roller.driveType !== 'override' && !~~e.override))
                            && width >= Number(e.from.replace(',', '.'))
                            && height <= Number(e.height.replace(',', '.'))
                        );
                    })[0]);
                }
        });
        if (pricesList.length > 0){
          return pricesList;
        } else {
          return null;
        }
  }
}

