import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StepComponent, _, ConfiguratorOptions, isDefined } from '@icc/configurator/shared';
import { Subscription } from 'rxjs';
import { APP_CONFIG, AppConfigFactory, EventBusService, ConfigurationsService } from '@icc/common';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { ShutterFacade } from '../+state/shutter.facade';
import { SchemasService } from '@icc/legacy/configurator/steps/roller_shutter/schemas.service';
import { DrivesService } from '@icc/legacy/configurator/steps/roller_shutter/drives.service';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';

@Component({
    selector: 'icc-montage',
    templateUrl: './montage.component.html',
    styleUrls: ['./montage.component.scss'],
})
export class MontageComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('CONFIGURATOR|Montaż');
    static stepIcon = {
        ligature: 'apps',
    };


    public configurator = 'roller_shutter';
    public stepId = 'montage';
    public title = _('CONFIGURATOR|Montaż');

    public options = [
        {
            title: _('ROLLERSHUTTER|Informacja o montażu'),
            callback: () => this.openModalMontagesInfo(),
            show: () => this.availableMontagesInfo(),
            icon: {
                ligature: 'info'
            },
        },
    ];

    extraDimensionsOptions = this.config().IccConfig.Configurators.roller_shutter
        .extraDimensionsOptions;
    hangerLockAvailable =
        this.config().IccConfig.Configurators.roller_shutter.hangerLock
        && !this.config().IccConfig.Configurators.roller_shutter.hangerLockLinkedOverloadLock;
    railsBottomCutAvailable = this.config().IccConfig.Configurators.roller_shutter.railsBottomCut;

    railCutAngles: any[] = [];
    hangers: any[] = [];
    hanger$ = this.shutterFacade.hanger$;
    driveSide$ = this.shutterFacade.driveSide$;
    railsBottomCut$ = this.shutterFacade.railsBottomCut$;

    private subscriptions: Subscription[] = [];

    static stepEnable = (conf: RollerShutterActiveConfiguration, options: ConfiguratorOptions) =>
        options.config.Configurators.roller_shutter.hangerLock
        && !options.config.Configurators.roller_shutter.hangerLockLinkedOverloadLock
        || options.config.Configurators.roller_shutter.railsBottomCut;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private rollerDimensionsService: RollerDimensionsService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind'>,
        private shutterFacade: ShutterFacade,
        private drivesService: DrivesService
    ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.railCutAngles = this.rollerDimensionsService.railCutAngles;
        this.hangers = this.drivesService.hangers;
    }

    changeHanger(hangerId) {
        this.drivesService.changeHanger(hangerId);
    }

    updateRailsBottomCut(railsBottomCut: string) {
        this.configurationsService.conf.Current.RollerShutter.railsBottomCut = railsBottomCut;
        this.rollerDimensionsService.updateRailsBottomCut(true);
    }

    changeDriveSide(side: 'L' | 'R') {
        this.configurationsService.conf.Current.RollerShutter.driveSide = side;
        this.eventBusService.post({
            key: 'montageOptionsChanged',
            value: null
        });
    }

    openModalMontagesInfo() {
        this.rollerDimensionsService.openModalMontagesInfo(this.rollerDimensionsService.montagesInfo[this.configurationsService.conf.Current.RollerShutter.type.id]);
    }

    availableMontagesInfo() {
        return isDefined(this.rollerDimensionsService.montagesInfo[this.configurationsService.conf.Current.RollerShutter.type.id]) && !this.extraDimensionsOptions;
    }
}
