import { Injectable, Inject, NgZone } from '@angular/core';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { core } from '@icc/common/helpers';
import { PriceService } from '@icc/price';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { StateService } from '@icc/common/state.service';
import { Common } from '@icc/common/Common';
import { PositionsDetailsService } from '@icc/common/PositionsDetailsService';
import { GlazingSizesService } from '@icc/common/configurators/glazing-sizes.service';
import { DrawService } from '@icc/common/configurators/draw.service';
import { FillingsService } from '../glazings/fillings.service';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import { Location } from '@angular/common';
import { EventBusService } from "@icc/common/event-bus.service";
import { GlazingBeadsService, OfferGroupCodeService, ValidationService } from "libs/common/src/lib";
import { WindowConfiguration } from "@icc/common/configurations/WindowConfiguration";
import { APP_CONFIG, AppConfigFactory } from "@icc/common/config";
import { OfferGroupService } from '@icc/legacy/panel/offer-group.service';
import { BrowserShapeService } from '@icc/legacy/configurator/layout/shape.service';
import { SashesLayoutService } from '@icc/legacy/configurator/layout/sashes-layout.service';
import { InfoService, ModalService, currencyExchange } from '@icc/helpers';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { NewColorsService } from 'libs/configurator/window/src/lib/colors/new-colors.service';
import { ColorsComponent } from '@icc/configurator/window';
import { Store } from '@ngrx/store';
import { SetConfiguratorLoaded } from '@icc/configurator/shared';
import { PositionDetailedSummaryService } from '@icc/common/PositionDetailedSummaryService';
import PositionsFactory from '@icc/legacy/panel/PositionsFactory';
import { HandlesService } from '../handles/handles.service';
import { ColorsPositionService } from '@icc/legacy/panel/colors-position.service';

@Injectable()
export class SystemsComparisonService {
    systems;
    edit: { value: boolean } = { value: false };
    editId: number | null = null;
    drawOptions = {};
    onOffer = false;
    inSystemsComparison: { value: boolean } = { value: false};
    groups;
    originalGroups;
    option;
    changedGroups = [];
    invalidPositions = [];
    options;
    finish = false;

    private key = '';
    private objectId = 'id';
    private objectIds = {
        'mulions.astragalFrame': 'profileId',
        'mulions.movable': 'profileId',
        'mulions.movableCentral': 'profileId',
        'mulions.movableDouble': 'profileId',
        'mulions.static': 'profileId',
        'mulions.intMullions': 'profileId',
        'mulions.intMullions.astragalFrame': 'profileId',
        'mulions.intMullions.movable': 'profileId',
        'mulions.intMullions.movableCentral': 'profileId',
        'mulions.intMullions.movableDouble': 'profileId',
        'mulions.intMullions.static': 'profileId',
        'frames.frames': 'profileId',
        'couplings.couplings': 'couplingId',
        sashFrames: 'profileId',
        'sashFrames.sashes': 'profileId',
        'sashFrames.sashes.profiles': 'profileId',
    };
    private originalConfig: any = {};
    private configs = [];
    private changes: any = {};

    constructor(
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        @Inject('EditPositionFactory') private editPositionFactory,
        @Inject('OffersFactory') private offersFactory,
        @Inject('PositionsFactory') private positionsFactory: ReturnType<typeof PositionsFactory>,
        @Inject('$rootScope') private $rootScope,
        @Inject('$translate') private $translate: ng.translate.ITranslateService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private systemsService: SystemsService,
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService,
        private priceService: PriceService,
        private stateService: StateService,
        private glazingSizesService: GlazingSizesService,
        private drawService: DrawService,
        private eventBusService: EventBusService,
        private fillingsService: FillingsService,
        private location: Location,
        private offerGroupService: OfferGroupService,
        private shapeService: BrowserShapeService,
        private sashesLayoutService: SashesLayoutService,
        private infoService: InfoService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private newColorsService: NewColorsService,
        private modalService: ModalService,
        private store: Store,
        private ngZone: NgZone,
        private validationService: ValidationService,
        private glazingBeadsService: GlazingBeadsService,
        private handlesService: HandlesService,
        private colorsPositionService: ColorsPositionService,
    ) {
        this.options = {
            system: {
                name: this.$translate.instant('OFFER|Linii produktowej'),
                icon: 'apps',
            },
            color: {
                name: this.$translate.instant('OFFER|Kolorystyki'),
                icon: 'palette',
            },
            filling: {
                name: this.$translate.instant('OFFER|Szklenia'),
                class: 'icc-icons icc-icons-glazing',
            },
        };
    }

    async systemsComparison() {
        this.onOffer = false;
        const systems = await this.openSystemsSelectModal(
            (this.configurationsService.conf.Current as WindowActiveConfiguration).System.id,
            (this.configurationsService.conf.Current as WindowActiveConfiguration).type,
            true
        );
        if (systems && systems.length) {
            this.$rootScope.loader = true;
            setTimeout(async () => {
                this.configs = this.calculateSystems(systems);
                this.$rootScope.loader = false;
                const data = await this.openSystemsComparisonModal();
                this.saveOrEditConfig(data);
            });
        }
    }

    saveOrEditConfig(data) {
        if (data.config) {
            this.configurationsService.conf.Current = Object.assign(
                this.configurationsService.conf.Current,
                core.copy(data.config.conf)
            );
            this.configurationsService.conf.Default = Object.assign(
                this.configurationsService.conf.Default,
                core.copy(data.config.defaultConf)
            );
            this.priceService.count();
            if (data.edit) {
                this.edit.value = true;
                this.editId = data.config.id;
            }
        }
    }

    openSystemsComparisonModal() {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsComparisonModal',
            resolve: {
                conf: () => this.configurationsService.conf.Current,
                currency: () => this.stateService.state.offers[0].doc.currency,
                configs: () => this.configs,
                onOffer: () => this.onOffer,
                drawOptions: () => this.drawOptions,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        modalInstance.closed.then(() => {
            if (!this.edit.value) {
                this.configs = [];
            }
        });

        return modalInstance.result;
    }

    openSystemsComparisonDetailsModal(
        config,
        currentConfig = this.configurationsService.conf.Current,
        allowEdit = true,
        showPrevButton = true,
        showNextButton = true
    ) {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsComparisonDetailsModal',
            resolve: {
                config: () => config,
                currentConfig: () => currentConfig,
                currency: () => this.stateService.state.offers[0].doc.currency,
                onOffer: () => this.onOffer,
                drawOptions: () => this.drawOptions,
                allowEdit: () => allowEdit,
                showPrevButton: () => showPrevButton,
                showNextButton: () => showNextButton,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    calculateSystems(
        systems,
        configuration = this.configurationsService.conf.Current,
        defaultConfiguration = this.configurationsService.conf.Default,
        position = {
            doc: {
                configuration: {},
                details: {},
                detailsForView: {},
                detailsTree: {},
                tmp_id: null,
                listNumber: null
            }
        },
        resetConfigs = true,
    ): {
        id: number;
        conf: WindowActiveConfiguration;
        defaultConf: WindowActiveConfiguration;
        details: WindowConfiguration;
        detailsForView: any;
        positionId?: string;
        positionIndex?: number;
    }[] {
        if (resetConfigs) {
            this.configs = [];
        }
        let conf = core.copy(configuration) as WindowActiveConfiguration;
        let defaultConf = core.copy(defaultConfiguration) as WindowActiveConfiguration;

        this.originalConfig = { conf, defaultConf, details: {}, detailsForView: {}, detailsTree: {} };
        this.getDetails(this.originalConfig, false);

        for (let i = 0; i < systems.length; i++) {
            const id = this.configs.length ? this.configs[this.configs.length - 1].id + 1 : 0;
            const system = this.systems.find(el => el.id == systems[i]);
            conf = core.copy(configuration) as WindowActiveConfiguration;
            defaultConf = core.copy(defaultConfiguration) as WindowActiveConfiguration;
            this.currentConfiguratorService.setConfigurator(conf.type);
            conf.systemComparison = true;
            this.sashesLayoutService.rebuildRefs(conf);

            this.systemsService.selectSystem(
                conf,
                defaultConf,
                system,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true
            );
            const config: {
                id: number;
                originalConf: WindowActiveConfiguration | any;
                originalDetails: WindowConfiguration | any;
                originalDetailsForView: any;
                originalDetailsTree: any;
                conf: WindowActiveConfiguration;
                defaultConf: WindowActiveConfiguration;
                details: WindowConfiguration | any;
                detailsForView: any;
                detailsTree: any;
                positionId?: string;
                positionIndex?: number;
            } = { id, originalConf: core.copy(position.doc.configuration), originalDetails: core.copy(position.doc.details), originalDetailsForView: core.copy(position.doc.detailsForView), originalDetailsTree: core.copy(position.doc.detailsTree), conf, defaultConf, details: {}, detailsForView: {}, detailsTree: {} };
            if (position.doc.tmp_id.length) {
                config.positionId = position.doc.tmp_id;
            }
            if (position.doc.listNumber) {
                config.positionIndex = position.doc.listNumber;
            }
            this.getDetails(config);

            this.configs.push(config);
        }

        return this.configs;
    }

    getDetails(config, getChanges = true) {
        let details;
        if (getChanges) {
            this.priceService.count(config.conf);
            details = this.configurationsService.createSimpleConfiguration(config.conf);
            config.conf.drawData = this.drawService.getData(details);
            if (config.conf.type !== 'coupled_window') {
                this.glazingSizesService.count(config.conf);
            }
            this.priceService.count(config.conf);
        }
        details = this.configurationsService.createSimpleConfiguration(config.conf);
        details.timestamp = Date.now();
        const position = new this.positionsFactory.Position({
            configuration: config.conf,
            details,
            summary: {
                dealer: {
                    components: {},
                },
                client: {
                    components: {},
                },
            },
            listNumber: config.positionIndex,
            confType: details.type,
            price: currencyExchange(details.price, this.stateService.state.offers[0].doc.currency),
            price_no_margin: currencyExchange(details.priceNoMargin, this.stateService.state.offers[0].doc.currency),
            offer: this.stateService.state.offers[0].doc,
            quantity: config.quantity,
        });
        const positions = new PositionsDetailsService(
            IccConfig,
            [position].map((el: any) => {
                el.summary = PositionDetailedSummaryService.detailedSummary(el, IccConfig, this.stateService.state.offers[0].doc.currency);
                return el
            }),
            this.stateService.getCurrentOffer(),
            this.$rootScope.user.access,
            true,
            this.$rootScope.user.access,
            this.$rootScope.curLang,
            this.$translate.instant.bind(this.$translate),
            false,
            false,
            false,
            false,
            null,
            false,
            [],
            false,
            this.$rootScope.user,
        );
        const detailsForView = positions[0].detailsForView;
        const detailsTree = positions[0].detailsTree;

        if (getChanges) {
            this.changes = [];
            if (!this.onOffer) {
                detailsForView.allWidth = true;
            }
            this.extract(detailsForView, this.originalConfig.detailsForView);
        }

        config.details = Object.assign(config.details, details);
        config.detailsForView = Object.assign(config.detailsForView, detailsForView);
        config.detailsTree = Object.assign(config.detailsForView, detailsTree);
        config.changes = core.copy(this.changes);
    }

    // eslint-disable-next-line max-statements
    extract(elems, currentConfElems, path = '', id = null) {
        for (const k in elems) {
            if (['prices', 'system'].indexOf(k) === -1) {
                if (Common.isArray(elems[k])) {
                    for (let i = 0; i < elems[k].length; i++) {
                        if (path === 'Sashes' || path === 'Frames') {
                            id = i;
                        }
                        if (Common.isObject(elems[k][i])) {
                            this.key = path ? path + '.' + k : k;
                            this.objectId = Common.isDefined(this.objectIds[this.key])
                                ? this.objectIds[this.key]
                                : 'id';
                            // eslint-disable-next-line max-depth
                            if (
                                elems[k][i][this.objectId] != null
                                && (!currentConfElems
                                    || !currentConfElems[k]
                                    || !currentConfElems[k][i]
                                    || !currentConfElems[k][i][this.objectId]
                                    || elems[k][i][this.objectId]
                                        !== currentConfElems[k][i][this.objectId])
                            ) {
                                const currentConfigId =
                                    currentConfElems
                                    && currentConfElems[k]
                                    && currentConfElems[k][i]
                                    && Common.isDefined(currentConfElems[k][i][this.objectId])
                                        ? currentConfElems[k][i][this.objectId]
                                        : null;
                                const currentConfigName =
                                    currentConfElems
                                    && currentConfElems[k]
                                    && currentConfElems[k][i]
                                    && Common.isDefined(currentConfElems[k][i][this.objectId])
                                        ? currentConfElems[k][i].name
                                        : null;
                                elems[k][i].changed = true;
                                elems[k][i].currentConfigId = currentConfigId;
                                elems[k][i].currentConfigName = currentConfigName;
                                this.changes.push({
                                    key: this.key,
                                    id: {
                                        before: currentConfigId,
                                        after: elems[k][i][this.objectId],
                                    },
                                    name: {
                                        before: currentConfigName,
                                        after: elems[k][i].name,
                                    },
                                });
                            }
                            this.extract(
                                elems[k][i],
                                currentConfElems
                                    && Common.isDefined(currentConfElems[k])
                                    && Common.isDefined(currentConfElems[k][i])
                                    ? currentConfElems[k][i]
                                    : null,
                                this.key,
                                id
                            );
                        }
                    }
                } else if (Common.isObject(elems[k])) {
                    this.key = path ? path + '.' + k : k;
                    this.objectId = Common.isDefined(this.objectIds[this.key])
                        ? this.objectIds[this.key]
                        : 'id';
                    if (
                        elems[k][this.objectId] != null
                        && (!currentConfElems
                            || !currentConfElems[k]
                            || !currentConfElems[k][this.objectId]
                            || elems[k][this.objectId] !== currentConfElems[k][this.objectId])
                    ) {
                        const currentConfigId =
                            currentConfElems
                            && currentConfElems[k]
                            && Common.isDefined(currentConfElems[k][this.objectId])
                                ? currentConfElems[k][this.objectId]
                                : null;
                        const currentConfigName =
                            currentConfElems
                            && currentConfElems[k]
                            && Common.isDefined(currentConfElems[k][this.objectId])
                                ? currentConfElems[k].name
                                : null;
                        elems[k].changed = true;
                        elems[k].currentConfigId = currentConfigId;
                        elems[k].currentConfigName = currentConfigName;
                        this.changes.push({
                            key: this.key,
                            id: {
                                before: currentConfigId,
                                after: elems[k][this.objectId],
                            },
                            name: {
                                before: currentConfigName,
                                after: elems[k].name,
                            },
                        });
                    }
                    this.extract(
                        elems[k],
                        currentConfElems && Common.isDefined(currentConfElems[k])
                            ? currentConfElems[k]
                            : null,
                        this.key,
                        id
                    );
                }
            }
        }
    }

    // eslint-disable-next-line max-statements
    saveConfig(save = true, conf) {
        if (this.onOffer) {
            this.location.go('/app/offers_view/' + this.stateService.state.offer_id);
        }
        if (save) {
            let config: any = {
                details: {},
                detailsForView: {},
                detailsTree: {},
            };
            config = this.configs.find(el => el.positionId === this.editId);
            config.conf = core.copy(conf);
            config.defaultConf = core.copy(this.configurationsService.conf.Default);
            if ((config.conf.Issues.length === 0 && Number.isFinite(config.conf.Price)) && this.invalidPositions.some(el => el === this.editId)) {
                this.invalidPositions.splice(this.invalidPositions.findIndex(el => el === this.editId), 1);
            }
            this.getDetails(config);
            if (this.onOffer) {
                for (const groupCode in this.groups) {
                    if (Object.prototype.hasOwnProperty.call(this.groups, groupCode)) {
                        for (let i = 0; i < this.groups[groupCode].rows.length; i++) {
                            // eslint-disable-next-line max-depth
                            if (this.groups[groupCode].rows[i].id === this.editId) {
                                Object.assign(
                                    this.groups[groupCode].rows[i].doc,
                                    {
                                        configuration: core.copy(config.conf),
                                        details: core.copy(config.details),
                                        detailsForView: core.copy(config.detailsForView),
                                        detailsTree: core.copy(config.detailsTree),
                                    }
                                );
                            }
                        }
                        this.updateGroups(this.groups, groupCode, this.configs);
                        const changedGroup = this.changedGroups.find(el => el.groupKey === Number(groupCode));
                        if (changedGroup) {
                            changedGroup.price = this.groups[groupCode].price;
                        } else {
                            this.changedGroups.push({
                                groupKey: groupCode,
                                name: this.groups[groupCode].groupData.Name,
                                originalPrice: this.groups[groupCode].originalPrice,
                                price: this.groups[groupCode].price,
                            })
                        }
                    }
                }
                this.fixCoupledWindow(this.groups, config, this.editId);
            }
        }
        this.configurationsService.conf.Current = Object.assign(
            this.configurationsService.conf.Current,
            core.copy(this.originalConfig.conf)
        );
        this.configurationsService.conf.Default = Object.assign(
            this.configurationsService.conf.Default,
            core.copy(this.originalConfig.defaultConf)
        );
        delete (this.configurationsService.conf.Current as WindowActiveConfiguration)
            .systemComparison;
        this.edit.value = false;
        this.editId = null;

        if (!this.onOffer) {
            this.openSystemsComparisonModal();
        }
    }

    async changeFilling(config, sashId = null, intSashId = null, getDetails = true) {
        await this.ngZone.run(async () => {
            const modalInstance = this.$uibModal.open({
                component: 'configuratorPageModalComponent',
                resolve: {
                    type: () => 'filling',
                },
            })

            let sash;
            let intSash;
            config.conf.valid.frameProfiles = true;
            config.conf.valid.sashesProfiles = true;
            config.conf.valid.loadedFillings = true;
            config.conf.valid.loadedGlazingBeads = true;
            config.conf.valid.sashes = true;
            this.validationService.valid(config.conf as WindowActiveConfiguration, 'loadedFillings');
            this.validationService.valid(config.conf as WindowActiveConfiguration, 'loadedGlazingBeads');
            this.glazingBeadsService.loadGlazingBeads(config.conf as WindowActiveConfiguration);
            this.handlesService.findHandlesBySystem(false, false, config.conf as WindowActiveConfiguration);
            if (sashId !== null) {
                sash = config.conf.Sashes.find(el => el.id === sashId);
                if (intSashId !== null) {
                    intSash = sash.intSashes.find(el => el.id === intSashId);
                }
            }
            this.fillingsService.loadMatchingFillings(config.conf);
            this.fillingsService.openModal(
                intSash ? intSash : sash ? sash : 'default',
                config.conf,
                config.defaultConf ?? config.conf,
                sash ? sash : undefined,
                () => {
                    if (getDetails) {
                        this.getDetails(config);
                        this.fillingsService.loadMatchingFillings(this.originalConfig.conf);
                    }
                    modalInstance.close();
                }
            );
            await modalInstance.result;
            return modalInstance.result;
        });
    }

    async changeColor(config, returnColor = false) {
        return await this.ngZone.run(async () => {
            this.handlesService.findHandlesBySystem(false, false, config.conf as WindowActiveConfiguration);
            this.configurationsService.conf.Current = core.copy(config.conf);
            this.configurationsService.conf.Default = core.copy(config.defaultConf);
            const modalInstance = this.$uibModal.open({
                component: 'configuratorPageModalComponent',
                resolve: {
                    type: () => 'color',
                },
            })

            this.modalService.open({
                pageComponent: ColorsComponent
            });

            await modalInstance.result;
            const pvcPanelColor = (this.configurationsService.conf.Current as WindowActiveConfiguration).Sashes.reduce((prev, sash) => {
                if (!prev) {
                    if (sash.intSashes.length === 1) {
                        if (sash.glazing.type === 'pvc_panels') {
                            return sash.glazing.selectedColor;
                        }
                    } else {
                        const intSash = sash.intSashes.find(el => el.glazing.type === 'pvc_panels');
                        if (intSash) {
                            return intSash.glazing.selectedColor;
                        }
                    }
                }
                return prev;
            }, null);
            if (returnColor) {
                return {
                    ...(this.configurationsService.conf.Current as WindowActiveConfiguration).Colors,
                    ColorsSashExt: (this.configurationsService.conf.Current as WindowActiveConfiguration).ColorsSashExt,
                    pvcPanelColor
                };
            } else {
                config.conf = core.copy(this.configurationsService.conf.Current);
                config.defaultConf = core.copy(this.configurationsService.conf.Default);
                this.getDetails(config);

                return modalInstance.result;
            }
        });
    }

    // eslint-disable-next-line max-statements
    async systemsComparisonInOffer(groups, groupKey, drawOptions, changeOption = true) {
        this.onOffer = true;
        this.drawOptions = drawOptions;
        this.configs = [];
        const positions = groups[groupKey].rows;
        let system;
        const confData = {
            nextPosition: false,
            finish: false,
            prevStep: false,
        };
        const fillings = {};
        const colors = {};
        const systems = {};
        let iStart = 1;
        if (positions) {
            this.store.dispatch(new SetConfiguratorLoaded());
            this.newColorsService.loadData();
            if (changeOption) {
                this.option = await this.openOptionsModal();
            }
            if (!this.option) {
                return false;
            }
            const firstPositionConf = this.configurationsService.createActiveConfiguration(positions[0].doc.details, this.configuratorsDataService);
            firstPositionConf.drawData = positions[0].doc.configuration.drawData;
            this.shapeService.setShapes(firstPositionConf);
            this.configurationsService.conf.Current = core.copy(firstPositionConf);
            this.configurationsService.conf.Default = core.copy(firstPositionConf);
            this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);
            if (this.option === 'system') {
                positions.forEach(position => {
                    const conf = position.doc.configuration;
                    if (
                        !Object.prototype.hasOwnProperty.call(
                            systems,
                            conf.System.id
                        )
                    ) {
                        systems[conf.System.id] = {
                            name: conf.System.name,
                            config: {conf: core.copy(conf)},
                            sashTypesIds: [...new Set(conf.Sashes.map(sash => sash.type.id))]
                        };
                    } else {
                        systems[conf.System.id].sashTypesIds = [...new Set([...systems[conf.System.id].sashTypesIds, ...conf.Sashes.map(sash => sash.type.id)])];
                    }
                });
                const changeData = await this.openSystemsModal(systems);
                confData.nextPosition = changeData;
                iStart = 0;
            } else if (this.option === 'color') {
                positions.forEach(position => {
                    const conf = position.doc.configuration;
                    const pvcPanelColor = conf.Sashes.reduce((prev, sash) => {
                        if (!prev) {
                            if (sash.intSashes.length === 1) {
                                if (sash.glazing.type === 'pvc_panels') {
                                    return sash.glazing.selectedColor;
                                }
                            } else {
                                const intSash = sash.intSashes.find(el => el.glazing.type === 'pvc_panels');
                                if (intSash) {
                                    return intSash.glazing.selectedColor;
                                }
                            }
                        }
                        return prev;
                    }, null);
                    if (
                        !Object.prototype.hasOwnProperty.call(
                            colors,
                            conf.System.id + '_' +
                            conf.Colors.frame.alushell?.id + '_' +
                            conf.Colors.frame.core?.id + '_' +
                            conf.Colors.frame.inner?.id + '_' +
                            conf.Colors.frame.outer?.id + '_' +
                            conf.Colors.sash.alushell?.id + '_' +
                            conf.Colors.sash.core?.id + '_' +
                            conf.Colors.sash.inner?.id + '_' +
                            conf.Colors.sash.outer?.id + '_' +
                            pvcPanelColor?.inner?.id + '_' +
                            pvcPanelColor?.outer?.id
                        )
                    ) {
                        colors[
                            conf.System.id + '_' +
                            conf.Colors.frame.alushell?.id + '_' +
                            conf.Colors.frame.core?.id + '_' +
                            conf.Colors.frame.inner?.id + '_' +
                            conf.Colors.frame.outer?.id + '_' +
                            conf.Colors.sash.alushell?.id + '_' +
                            conf.Colors.sash.core?.id + '_' +
                            conf.Colors.sash.inner?.id + '_' +
                            conf.Colors.sash.outer?.id + '_' +
                            pvcPanelColor?.inner?.id + '_' +
                            pvcPanelColor?.outer?.id
                        ] = {
                            config: {conf: core.copy(conf), defaultConf: core.copy(conf), pvcPanelColor},
                        }
                    }
                });
                const changeData = await this.openColorsModal(colors);
                confData.nextPosition = changeData;
                iStart = 0;
            } else if (this.option === 'filling') {
                positions.forEach(position => {
                    position.doc.configuration.Sashes.forEach(sash => {
                        if (Common.isArray(sash.intSashes) && sash.intSashes.length > 1) {
                            sash.intSashes.forEach((intSash, index) => {
                                if (!Object.prototype.hasOwnProperty.call(fillings, intSash.glazing.id)) {
                                    fillings[intSash.glazing.id] = {
                                        name: intSash.glazing.name,
                                        config: {conf: core.copy(position.doc.configuration)},
                                        sashId: sash.id,
                                        intSashId: intSash.id,
                                        intSashIndex: index,
                                    }
                                }
                            });
                        } else {
                            if (!Object.prototype.hasOwnProperty.call(fillings, sash.glazing.id)) {
                                fillings[sash.glazing.id] = {
                                    name: sash.glazing.name,
                                    config: {conf: core.copy(position.doc.configuration)},
                                    sashId: sash.id,
                                };
                            }
                        }
                    });
                });
                const changeData = await this.openFillingsModal(fillings);
                confData.nextPosition = changeData;
                iStart = 0;
            }


            if (confData.nextPosition) {
                let changes = this.getChanges(
                    firstPositionConf,
                    this.configs[0] ? this.configs[0].conf : false
                );
                if (this.option === 'system') {
                    changes = this.getSystemChanges(
                        systems,
                        changes
                    );
                } else if (this.option === 'filling') {
                    changes = this.getFillingChanges(
                        fillings,
                        changes
                    );
                } else if (this.option === 'color') {
                    changes = this.getColorChanges(
                        colors,
                        changes
                    );
                }

                if (positions.length > 1 || iStart === 0) {
                    for (let i = iStart; i < positions.length; i++) {
                        const conf = this.configurationsService.createActiveConfiguration(positions[i].doc.details, this.configuratorsDataService);
                        conf.drawData = positions[i].doc.configuration.drawData;
                        this.shapeService.setShapes(conf);
                        this.configurationsService.conf.Current = core.copy(
                            conf
                        );
                        this.configurationsService.conf.Default = core.copy(
                            conf
                        );
                        this.eventBusService.setCurrentConfiguration(
                            this.configurationsService.conf
                        );
                        const config: any = {
                            id: i,
                            conf: this.configurationsService.conf.Current,
                            defaultConf: this.configurationsService.conf.Default,
                            details: {},
                            detailsForView: {},
                            detailsTree: {},
                            positionId: positions[i].doc.tmp_id,
                            positionIndex: positions[i].doc.listNumber,
                            quantity: positions[i].doc.quantity,
                        }
                        this.originalConfig = { conf: core.copy(this.configurationsService.conf.Current), details: {}, detailsForView: {}, detailsTree: {} };
                        this.getDetails(this.originalConfig, false);
                        this.getDetails(config);
                        if (this.option === 'system') {
                            config.originalConf = core.copy(this.originalConfig.conf);
                            config.originalDetails = core.copy(this.originalConfig.details);
                            config.originalDetailsForView = core.copy(this.originalConfig.detailsForView);
                            config.originalDetailsTree = core.copy(this.originalConfig.detailsTree);
                        }
                        this.configs.push(config);
                        await this.changeSystemsColorsAndFillingsFromChanges(
                            this.configs[i],
                            positions[i].doc,
                            changes,
                            this.option === 'system' && this.finish ? false : true,
                            i === positions.length - 1 ? false : true,
                        );
                        if ((this.configs[i].conf.Issues.length > 0 || !Number.isFinite(this.configs[i].conf.Price)) && !this.invalidPositions.some(el => el === this.configs[i].positionId)) {
                            this.invalidPositions.push(this.configs[i].positionId);
                        }
                    }
                }
                if (this.finish) {
                    this.finish = false;
                }
                this.infoService.dismissInfoModal();
                this.updateGroups(groups, groupKey, this.configs);
                this.groups = groups;
                const changedGroup = this.changedGroups.find(el => el.groupKey === groupKey);
                if (changedGroup) {
                    changedGroup.price = this.groups[groupKey].price;
                } else {
                    this.changedGroups.push({
                        groupKey,
                        name: this.groups[groupKey].groupData.Name,
                        originalPrice: this.groups[groupKey].originalPrice,
                        price: this.groups[groupKey].price,
                    })
                }
                return groups;
            }
        }
        return false;
    }

    getSystemsComparisonColorPosition(pos, colorsPositions) {
        const colorPosition = colorsPositions.find(el =>
            el.system.id === pos.detailsForView.system.id &&
            el.colors.frame.core.id === pos.detailsForView.colors.frame.core.id &&
            el.colors.frame.inner.id === pos.detailsForView.colors.frame.inner.id &&
            el.colors.frame.outer.id === pos.detailsForView.colors.frame.outer.id &&
            el.colors.sash.core.id === pos.detailsForView.colors.sash.core.id &&
            el.colors.sash.inner.id === pos.detailsForView.colors.sash.inner.id &&
            el.colors.sash.outer.id === pos.detailsForView.colors.sash.outer.id
        );

        return colorPosition ? colorPosition : false;
    }

    // eslint-disable-next-line max-statements
    async changeSystemsColorsAndFillingsFromChanges(config, position, changes, nextPositionEdit = false, showNextButton = true) {
        let toEdit = false;
        this.currentConfiguratorService.setConfigurator(config.conf.type);
        this.configurationsService.conf.Current = config.conf;
        this.configurationsService.conf.Default = config.defaultConf;
        this.glazingBeadsService.loadGlazingBeads(config.conf as WindowActiveConfiguration);
        this.fillingsService.loadMatchingFillings(config.conf);
        this.handlesService.findHandlesBySystem(false, false, config.conf as WindowActiveConfiguration);
        const pvcPanelColor = config.conf.Sashes.reduce((prev, sash) => {
            if (!prev) {
                if (sash.intSashes.length === 1) {
                    if (sash.glazing.type === 'pvc_panels') {
                        return sash.glazing.selectedColor;
                    }
                } else {
                    const intSash = sash.intSashes.find(el => el.glazing.type === 'pvc_panels');
                    if (intSash) {
                        return intSash.glazing.selectedColor;
                    }
                }
            }
            return prev;
        }, null);
        const colorId = config.conf.System.id + '_' +
            config.conf.Colors.frame.alushell?.id + '_' +
            config.conf.Colors.frame.core?.id + '_' +
            config.conf.Colors.frame.inner?.id + '_' +
            config.conf.Colors.frame.outer?.id + '_' +
            config.conf.Colors.sash.alushell?.id + '_' +
            config.conf.Colors.sash.core?.id + '_' +
            config.conf.Colors.sash.inner?.id + '_' +
            config.conf.Colors.sash.outer?.id + '_' +
            pvcPanelColor?.inner?.id + '_' +
            pvcPanelColor?.outer?.id;

        if (
            Object.prototype.hasOwnProperty.call(
                changes.systems,
                config.conf.System.id
            )
        ) {

            config.conf.systemComparison = true;
            this.sashesLayoutService.rebuildRefs(config.conf);

            this.systemsService.selectSystem(
                config.conf,
                config.defaultConf,
                changes.systems[config.conf.System.id],
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true
            );
        }

        if (
            Object.prototype.hasOwnProperty.call(
                changes.colors,
                colorId
            )
        ) {
            ['frame', 'sash'].forEach(element =>
                ['core', 'outer', 'inner', 'alushell'].forEach(side => {
                    const color = this.newColorsService.colors[changes.colors[colorId]?.[element]?.[side]?.colorId ?? changes.colors[colorId]?.[element]?.[side]?.id];
                    if (color) {
                        this.newColorsService.setElementColor(color, false, config.conf, {element: element as 'frame' | 'sash', side: side as 'inner' | 'outer' | 'alushell' | 'core'})
                    } else {
                        toEdit = true;
                    }
                })
            );
            if (changes.colors[colorId]?.pvcPanelColor) {
                ['inner', 'outer'].forEach(side => {
                    const color = this.newColorsService.colors[changes.colors[colorId]?.pvcPanelColor?.[side]?.colorId ?? changes.colors[colorId]?.pvcPanelColor?.[side]?.id];
                    if (color) {
                        this.newColorsService.setElementColor(color, false, config.conf, {element: 'filling', side: side as 'inner' | 'outer' | 'alushell' | 'core', sash: 'window'})
                    } else {
                        toEdit = true;
                    }
                });
            }

            if (this.option === 'color') {
                config.conf.ColorType = changes.colors[colorId]?.type;
                config.conf.ColorsSashExt = changes.colors[colorId]?.ColorsSashExt;
            }
        }

        config.conf.Sashes.forEach((sash, index) => {
            let filling = changes.fillings[position.configuration.Sashes[index].glazing.id];
            if (filling) {
                this.fillingsService.setFillingInSash(
                    sash,
                    sash,
                    filling,
                    config.conf
                );
            } else {
                toEdit = true;
            }
            if (sash.intSashes.length) {
                sash.intSashes.forEach((intSash, i) => {
                    filling =
                        position.configuration.Sashes[index].intSashes[i]
                        && changes.fillings[
                            position.configuration.Sashes[index].intSashes[i]
                                .glazing.id
                        ];
                    if (filling) {
                        this.fillingsService.setFillingInSash(
                            intSash,
                            sash,
                            filling,
                            config.conf
                        );
                    } else {
                        toEdit = true;
                    }
                });
            }
        });
        this.getDetails(config);
        if (nextPositionEdit && this.option === 'system') {
            const data = await this.openSystemsComparisonDetailsModal(
                config,
                position.configuration,
                false,
                false,
                showNextButton
            );
            if (data.nextPosition) {
                this.getChanges(position.configuration, config.conf);
            }
            if (data.finish) {
                this.finish = data.finish;
            }
        }
    }

    getChanges(
        originalData,
        changedData,
        changes = {
            colors: {},
            fillings: {},
            systems: {}
        }
    ) {

        if (changedData) {
            for (const type in changes.colors) {
                if (Object.prototype.hasOwnProperty.call(changes.colors, type) && type !== 'type') {
                    for (const side in changes.colors[type]) {
                        if (originalData.Colors[type][side]?.id !== changedData.Colors[type][side]?.id) {
                            changes.colors[type][side][originalData.Colors[type][side].id] =
                                core.copy(changedData.Colors[type][side]);
                        }
                    }
                }
            }

            originalData.Sashes.forEach((sash, index) => {
                if (sash.glazing.id !== changedData.Sashes[index].glazing.id) {
                    changes.fillings[sash.glazing.id] =
                        core.copy(changedData.Sashes[index].glazing);
                }
                if (sash.intSashes.length) {
                    sash.intSashes.forEach((intSash, i) => {
                        if (changedData.Sashes[index].intSashes[i]) {
                            changes.fillings[intSash.glazing.id] =
                                core.copy(changedData.Sashes[index].intSashes[i].glazing);
                        }
                    });
                }
            });
        }

        return changes;
    }

    getSystemChanges(systems, changes) {
        for (const systemId in systems) {
            if (Object.prototype.hasOwnProperty.call(systems, systemId) && systems[systemId].changedSystem) {
                changes.systems[systemId] = systems[systemId].changedSystem;
            }
        }

        return changes;
    }

    getFillingChanges(fillings, changes) {
        for (const fillingId in fillings) {
            if (Object.prototype.hasOwnProperty.call(fillings, fillingId) && fillings[fillingId].changedFilling) {
                changes.fillings[fillingId] = fillings[fillingId].changedFilling;
            }
        }

        return changes;
    }

    getColorChanges(colors, changes) {
        for (const colorId in colors) {
            if (Object.prototype.hasOwnProperty.call(colors, colorId) && colors[colorId].changedColor) {
                changes.colors[colorId] = colors[colorId].changedColor;
            }
        }

        return changes;
    }

    openOptionsModal() {
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'optionsModal',
            resolve: {},
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    openFillingsModal(fillings) {
        const modalFillings: ng.ui.bootstrap.IModalSettings = {
            component: 'fillingsModal',
            resolve: {
                fillings
            },
        };
        const modalInstance = this.$uibModal.open(modalFillings);

        return modalInstance.result;
    }

    openColorsModal(colors) {
        const modalColors: ng.ui.bootstrap.IModalSettings = {
            component: 'colorsModal',
            resolve: {
                colors
            },
        };
        const modalInstance = this.$uibModal.open(modalColors);

        return modalInstance.result;
    }

    openSystemsModal(systems) {
        const modalSystems: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsModal',
            resolve: {
                systems
            },
        };
        const modalInstance = this.$uibModal.open(modalSystems);

        return modalInstance.result;
    }

    openSystemsSelectModal(systemId, confType, multiple = true, sashTypesIds = []) {
        this.systems = this.systemsService.getSystems(confType);
        if (sashTypesIds.length) {
            this.systems = this.systems.filter(system => sashTypesIds.every(id => system.available_sash_types.includes(id)));
        }
        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'systemsSelectModal',
            resolve: {
                systems: () => this.systems.filter(el => Number(el.id) !== Number(systemId)),
                multiple: () => multiple,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    openGroupSelectModal() {
        const groups = this.getPositionsGroups();

        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'positionsGroupsModal',
            resolve: {
                groups: () => groups,
                drawOptions: () => this.drawOptions,
            },
        };
        const modalInstance = this.$uibModal.open(modalOptions);

        return modalInstance.result;
    }

    getPositionsGroups(forOffer = false) {
        let positions = core.copy(
            this.stateService
                .getKey('positions')
                .filter(
                    el =>
                        ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(
                            el.doc.configuration.type
                        ) > -1 &&
                        el.doc.coupled_position_id == null
                )
        );
        const tmpGroups = {};
        const schema = {
            window: [
                'configuration.System.id',
                'configuration.Colors.frame.alushell.id',
                'configuration.Colors.frame.core.id',
                'configuration.Colors.frame.inner.id',
                'configuration.Colors.frame.outer.id',
                'configuration.Colors.sash.alushell.id',
                'configuration.Colors.sash.core.id',
                'configuration.Colors.sash.inner.id',
                'configuration.Colors.sash.outer.id',
                'configuration.HasAlushell',
                'configuration.Wood.id'
            ],
            hs: 'window',
            door: 'window',
            folding_door: 'window',
            sliding_door: 'window'
        };
        positions = new PositionsDetailsService(
            IccConfig,
            positions,
            this.stateService.getCurrentOffer(),
            this.$rootScope.user.access,
            true,
            this.$rootScope.user.access,
            this.$rootScope.curLang,
            this.$translate.instant.bind(this.$translate),
            false,
            false,
            false,
            false,
            null,
            false,
            [],
            false,
            this.$rootScope.user,
        );
        if (!forOffer) {
            positions.forEach(position => {
                position.doc.groupCode = OfferGroupCodeService.generateGroupCode(
                    position.doc,
                    this.config().IccConfig,
                    schema
                );
                if (!tmpGroups[position.doc.groupCode]) {
                    tmpGroups[position.doc.groupCode] = [];
                }
                tmpGroups[position.doc.groupCode].push(forOffer ? position : position.doc);
            });
            return tmpGroups;
        }
        const groups = [{
            groupCode: 'window',
            confType: 'window',
            rows: positions,
            groupData: {},
            groupEdition: 0,
        }];

        this.groups = groups;

        return this.groups;
    }

    updateGroups(groups, groupKey, configs) {
        let price = 0;
        let originalPrice = 0;
        Object.assign(
            groups[groupKey].groupData,
            this.offerGroupService.getPositionGroupData({
                doc: {
                    configuration: configs[0].conf,
                    confType: groups[groupKey].confType
                }
            })
        );
        for (let i = 0; i< groups[groupKey].rows.length; i++) {
            const config = configs.find(el => el.positionId == groups[groupKey].rows[i].doc.tmp_id);
            if (config.detailsForView.prices.noPrice) {
                price = null;
            }
            if (price !== null) {
                price += config.detailsForView.prices.client.price * config.detailsForView.quantity;
            }
            if (groups[groupKey].rows[i].doc.detailsForView.prices.noPrice) {
                originalPrice = null;
            }
            if (originalPrice !== null) {
                originalPrice += groups[groupKey].rows[i].doc.detailsForView.prices.client.price * groups[groupKey].rows[i].doc.detailsForView.quantity;
            }
            Object.assign(
                groups[groupKey].rows[i].doc,
                {
                    configuration: core.copy(config.conf),
                    details: core.copy(config.details),
                    detailsForView: core.copy(config.detailsForView),
                    detailsTree: core.copy(config.detailsTree),
                }
            );
        }
        groups[groupKey].originalPrice = originalPrice;
        groups[groupKey].price = price;
        const colorsPositions = this.colorsPositionService.groupPositionsByColors(groups.reduce((prev, curr) => prev = [...prev, ...curr.rows], []), this.stateService.state.offers[0].doc);
        const originalColorsPositions = this.colorsPositionService.groupPositionsByColors(this.originalGroups.reduce((prev, curr) => prev = [...prev, ...curr.rows], []), this.stateService.state.offers[0].doc);
        originalColorsPositions.forEach(originalColorsPosition => groups[groupKey].originalPrice += (originalColorsPosition ? (originalColorsPosition.price * (1 - this.stateService.state.offers[0].doc.client_discount_position / 100)) : 0));
        colorsPositions.forEach(colorsPosition => groups[groupKey].price += (colorsPosition ? (colorsPosition.price * (1 - this.stateService.state.offers[0].doc.client_discount_position / 100)) : 0));

        return groups;
    }

    createSubOffer(groups, draws) {
        const positions = {};
        let issues = false;
        groups.forEach(group => {
            group.rows.forEach(position => {
                if (position.doc.configuration.Issues.length) {
                    issues = true;
                }
                positions[position.doc.tmp_id] = {
                    configuration: position.doc.configuration,
                    details: position.doc.details,
                    image: core.svgToPngData(draws[position.doc.tmp_id])
                }
            });
        });
        if (issues) {
            this.infoService.openInfoModal({
                title: this.$translate.instant('OFFER|Błędy w pozycjach'),
                message: this.$translate.instant('OFFER|W pozycjach występują błędy. Należy je wyeliminować aby utworzyć podofertę.'),
            });
        } else {
            this.inSystemsComparison.value = false;
            this.onOffer = false;
            this.offersFactory.addSubOffer(this.stateService.state.offer_id, false, false, false, positions);
        }
    }

    fixCoupledWindow(groups, positionConfig = null, positionId = null) {
        groups.forEach(group => {
            for (let i = 0; i< group.rows.length; i++) {
                if (group.rows[i].doc.confType === 'coupled_window' && group.rows[i].doc.configuration) {
                    if (group.rows[i].doc.configuration.windows) {
                        group.rows[i].doc.configuration.windows.forEach(window => {
                            const config =
                                positionId
                                && positionId === window.positionId
                                    ? positionConfig
                                    : this.configs.find(
                                          el =>
                                              el.positionId
                                              === window.positionId
                                      );
                            if (config) {
                                Object.assign(
                                    window,
                                    {
                                        details: core.copy(config.details),
                                    }
                                );
                            }
                        })
                    }
                    if (group.rows[i].doc.details.windows) {
                        group.rows[i].doc.details.windows.forEach(window => {
                            const config =
                                positionId
                                && positionId === window.positionId
                                    ? positionConfig
                                    : this.configs.find(
                                          el =>
                                              el.positionId
                                              === window.positionId
                                      );
                            if (config) {
                                Object.assign(
                                    window,
                                    {
                                        details: core.copy(config.details),
                                    }
                                );
                            }
                        })
                    }
                    const coupledConfig = {
                        details: {},
                        detailsForView: {},
                        detailsTree: {},
                        conf: core.copy(group.rows[i].doc.configuration),
                        defaultConf: core.copy(group.rows[i].doc.configuration)
                    };
                    this.getDetails(coupledConfig);
                    Object.assign(
                        group.rows[i].doc,
                        {
                            configuration: core.copy(coupledConfig.conf),
                            details: core.copy(coupledConfig.details),
                            detailsForView: core.copy(coupledConfig.detailsForView),
                            detailsTree: core.copy(coupledConfig.detailsTree),
                        }
                    );
                }
            }
        });
    }

    selectOption(option) {
        this.option = option;
    }
}
