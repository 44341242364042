import { PriceFunc, PriceElemsData, PriceSegment, price, getListPrice } from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import { FrameProfile, Profile, CouplingActive } from '@icc/window';
import { SashTypes } from '@icc/window';
import { IccDrawMathService } from '@icc/draw';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { ProfilesService } from '@icc/common/profiles.service';
import { ProfileSetsService } from '@icc/common/profile-sets.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Injectable, Inject } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ConfigurationsService } from '@icc/common';
import { PriceColorsService } from './price-colors.service';
import { IccWindowColors } from '@icc/common/data-types';

@Injectable()
export class PriceProfileService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private profilesService: ProfilesService,
        private profileSetsService: ProfileSetsService,
        private profilesPriceService: ProfilesPriceService,
        private configurationsService: ConfigurationsService,
        private priceColorsService: PriceColorsService,
    ) {}

    /**
     * Dolicza doplate za slupki
     * @param  {number} price       Cena wejściowa
     * @param  {object} PriceElems  Wycena
     * @param  {string} type        Typ
     * @param  {array}  sashes      tablica skrzydel
     * @param  {object} prices      Ceny slupkow
     * @param  {object} colors      Kolory
     * @return {number}             Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'internalDividers',
        data: {
            drawData: 'conf.drawData',
            type: 'conf.type',
            sashes: 'conf.Sashes',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            colors: 'conf.Colors',
            system: 'conf.System',
            alushells: 'data.alushells',
            hasAlushell: 'conf.HasAlushell',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppInteralDividers(
        { PriceElems, listPrice }: PriceElemsData,
        {
            drawData,
            type,
            sashes,
            prices,
            profiles,
            colors,
            system,
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }
    ): PriceSegment[] {
        let supp = 0;
        const priceSegments: PriceSegment[] = [];
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        sashes.forEach(sash => {
            // niepoprawny kąt
            if (sash.intMullions.length && sash.intMullions.some(div => !div.valid)) {
                supp = NaN;
                priceSegments.push({
                    type: 'intMullions',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {},
                });
                // cena slupkow za pole
            } else if (sash.intMullions.length) {
                const areas = this.genDividersAreas(
                    {
                        width: sash.rWidth,
                        height: sash.rHeight,
                        x: 0,
                        y: 0,
                    },
                    sash,
                    profiles
                );
                sash.intMullions.forEach((mullion, index) => {
                    const mullionData = drawData.mullion.find(o => o.mullionId === mullion.id);
                    const profilePrice = this.profilesPriceService.getProfilePrice(
                        mullion.profileId,
                        'structured_muntin_sash',
                        system,
                        colors.sash,
                        prices,
                        customPrice
                    );
                    const profileAlushellPrice = this.profilesPriceService.getProfileAlushellPrice(
                        mullion.profileId,
                        'structured_muntin_sash',
                        hasAlushell,
                        alushells,
                        colors.sash,
                        alushellPrices,
                        customPrice
                    );
                    let length =
                        (mullion.direction === 'vertical' ? mullion.rHeight : mullion.rWidth)
                        / 1000;
                    if (
                        mullionData
                        && this.config().IccConfig.Configurators.price.internalMullionsLength
                            === 'real'
                    ) {
                        const sides =
                            mullion.direction === 'horizontal'
                                ? { multiAlignRightDiv: 'left', multiAlignLeftDiv: 'right' }
                                : { multiAlignBottomDiv: 'top', multiAlignTopDiv: 'bottom' };

                        let mullionLength = Number(mullionData.length) || 0;
                        let profileId;
                        let profile;

                        Object.keys(sides).forEach(side => {
                            const perpendicularMullion = sash.intMullions.find(div =>
                                div[side].some(o => o.id === mullion.id)
                            );

                            if (perpendicularMullion) {
                                // odstęp na słupek w słupku
                                profileId = perpendicularMullion.profileId;
                                profile = profiles.find(o => o.id === profileId);
                                mullionLength +=
                                    Number(profile.width) / 2 - Number(profile.spaceMullion);
                            } else if (sash.frame[sides[side]]) {
                                // odstęp na słupek w skrzydle
                                profileId = sash.frame[sides[side]].profileId;
                                profile = profiles.find(o => o.id === profileId);
                                mullionLength +=
                                    Number(profile.width)
                                    - Number(profile.rebateWidth)
                                    - Number(profile.spaceMullion);
                            }
                        });

                        length = mullionLength / 1000;
                    }
                    if (profilePrice) {
                        priceSegments.push({
                            type: 'intMullions',
                            baseValue: price(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket)),
                            value: price(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket)),
                            valueType: 'value',
                            data: {
                                profileId: mullion.profileId,
                                divId: mullion.id,
                                sashId: sash.id,
                                length,
                            },
                        });
                        priceSegments.push({
                            type: 'intMullions',
                            baseValue: price(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket), length),
                            value: price(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket), length),
                            valueType: 'value',
                            data: {
                                profileId: mullion.profileId,
                                divId: mullion.id,
                                sashId: sash.id,
                                length,
                            },
                        });
                        priceSegments.push({
                            type: 'intMullions',
                            baseValue:
                                price(getListPrice(profilePrice, 'price_field', listPrice, listPriceEnableInMarket), areas && areas[mullion.profileId + '_' + mullion.id]
                                    ? areas[mullion.profileId + '_' + mullion.id].length
                                    : 0),
                            value:
                                price(getListPrice(profilePrice, 'price_field', listPrice, listPriceEnableInMarket), areas && areas[mullion.profileId + '_' + mullion.id]
                                    ? areas[mullion.profileId + '_' + mullion.id].length
                                    : 0),
                            valueType: 'value',
                            data: {
                                profileId: mullion.profileId,
                                divId: mullion.id,
                                sashId: sash.id,
                                length,
                            },
                        });
                    }
                    if (profileAlushellPrice && hasAlushell) {
                        priceSegments.push({
                            type: 'alushell',
                            baseValue: price(getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket)),
                            value: price(getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket)),
                            valueType: 'value',
                            data: {
                                profileId: mullion.profileId,
                                divId: mullion.id,
                                sashId: sash.id,
                                length,
                            },
                        });
                        priceSegments.push({
                            type: 'alushell',
                            baseValue: price(getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket), length),
                            value: price(getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket), length),
                            valueType: 'value',
                            data: {
                                profileId: mullion.profileId,
                                divId: mullion.id,
                                sashId: sash.id,
                                length,
                            },
                        });
                    }
                    if (!profilePrice && (!hasAlushell || !profileAlushellPrice)) {
                        priceSegments.push({
                            type: 'intMullions',
                            baseValue: null,
                            value: null,
                            valueType: 'value',
                            data: {
                                profileId: mullion.profileId,
                                divId: mullion.id,
                                sashId: sash.id,
                                length,
                            },
                        });
                    }
                });
            }
        });
        return priceSegments;
    }

    /**
     * Dolicza doplate za slupki
     * @param  {number} price       Cena wejściowa
     * @param  {object} PriceElems  Wycena
     * @param  {object} conf        Konstrukcja
     * @param  {object} prices      Ceny slupkow
     * @param  {object} colors      Kolory
     * @return {number}             Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'dividers',
        data: {
            drawData: 'conf.drawData',
            type: 'conf.type',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            frames: 'conf.Frames',
            sashes: 'conf.Sashes',
            colors: 'conf.Colors',
            dividers: 'conf.Mullions',
            width: 'conf.Width',
            height: 'conf.Height',
            system: 'conf.System',
            profileSets: 'data.profileSets',
            reinforcements: 'data.reinforcements',
            alushells: 'data.alushells',
            hasAlushell: 'conf.HasAlushell',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppDividers(
        { PriceStack, PriceElems, listPrice }: PriceElemsData,
        {
            drawData,
            dividers,
            type,
            width,
            height,
            prices,
            profiles,
            frames,
            sashes,
            colors,
            system,
            profileSets,
            reinforcements,
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        // niepoprawny kąt
        if (dividers.length && dividers.some(div => !div.valid)) {
            priceSegments.push({
                type: 'dividers',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {},
            });
        } else if (sashes.some(sash => sash.type && (['HS', 'S'].includes(sash.type.type)))) {
            // cena slupkow za pole
        } else if (dividers.length) {
            const areas = this.genDividersAreas(
                {
                    width,
                    height,
                    x: 0,
                    y: 0,
                },
                dividers,
                profiles
            );
            const sashesSegments = PriceStack.filter(
                s => s.type === 'sashes' && s.value !== null && s.data && s.data.divIds
            );
            const mullionProfileSetMap = sashesSegments.reduce((map, seg) => {
                seg.data.divIds.forEach(id => {
                    map[id] = seg.data.profileSetId;
                });
                return map;
            }, {});
            dividers.forEach((mullion, index) => {
                const profileSetForSash = this.profileSetsService.getProfileSet(
                    mullionProfileSetMap[mullion.id],
                    profileSets
                );
                const mullionData = drawData.mullion.find(o => o.mullionId === mullion.id);
                const mullionPriceType = this.profilesService.getMullionPriceType(
                    mullion,
                    dividers,
                    type,
                    profiles
                );
                const mullionSetType = this.profilesService.getMullionSetType(
                    mullion,
                    dividers,
                    type,
                    profiles
                );
                const mullionProfile = profiles.find(o => o.id === mullion.profileId);
                const profilePrice = this.profilesPriceService.getProfilePrice(
                    mullion.profileId,
                    mullionPriceType,
                    system,
                    colors.frame,
                    prices,
                    customPrice
                );
                const profileAlushellPrice = this.profilesPriceService.getProfileAlushellPrice(
                    mullion.profileId,
                    mullionPriceType,
                    hasAlushell,
                    alushells,
                    colors.frame,
                    alushellPrices,
                    customPrice
                );
                const frame = frames.find(f => f.id === mullion.frameId);
                const reinforcement = reinforcements
                    ? reinforcements.find(
                          o =>
                              mullion.reinforcement
                              && Number(o.id) === Number(mullion.reinforcement.id)
                      )
                    : null;
                const profileFromSetPrice = profileSetForSash
                    ? this.profilesPriceService.getProfilePrice(
                          profileSetForSash[mullionSetType],
                          mullionPriceType,
                          system,
                          colors.frame,
                          prices,
                          customPrice
                      )
                    : null;
                const profileAlushellFromSetPrice = profileSetForSash
                    ? this.profilesPriceService.getProfileAlushellPrice(
                          profileSetForSash[mullionSetType],
                          mullionPriceType,
                          hasAlushell,
                          alushells,
                          colors.frame,
                          alushellPrices,
                          customPrice
                      )
                    : null;
                let length =
                    (mullion.direction === 'vertical' ? mullion.rHeight : mullion.rWidth) / 1000;
                if (
                    mullionData
                    && this.config().IccConfig.Configurators.price.mullionsLength === 'real'
                ) {
                    const sides =
                        mullion.direction === 'horizontal'
                            ? { multiAlignRightDiv: 3, multiAlignLeftDiv: 1 }
                            : { multiAlignBottomDiv: 2, multiAlignTopDiv: 0 };

                    let mullionLength = Number(mullionData.length) || 0;
                    let profileId, profile;

                    Object.keys(sides).forEach(side => {
                        const perpendicularMullion = dividers.find(div =>
                            div[side].some(o => o.id === mullion.id)
                        );

                        if (perpendicularMullion) {
                            // odstęp na słupek w słupku
                            profileId = perpendicularMullion.profileId;
                            profile = profiles.find(o => o.id === profileId);
                            mullionLength +=
                                Number(profile.width) / 2 - Number(profile.spaceMullion);
                        } else if (frame.frame[sides[side]]) {
                            // odstęp na słupek w ramie
                            profileId = frame.frame[sides[side]].profileId;
                            profile = profiles.find(o => o.id === profileId);
                            mullionLength += Number(profile.width) - Number(profile.spaceMullion);
                        }
                    });

                    length = mullionLength / 1000;
                }

                let joinFixAddAstragals = this.config().IccConfig.Configurators.window
                    .joinFixAddAstragals;
                if (Common.isObject(joinFixAddAstragals)) {
                    const normalizedType = type === 'door' ? 'door' : 'window';
                    joinFixAddAstragals = this.config().IccConfig.Configurators.window
                        .joinFixAddAstragals[normalizedType];
                }

                if (profilePrice) {
                    let pricePiece = parseFloat(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket));
                    let priceLength = parseFloat(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket));
                    let priceField = parseFloat(getListPrice(profilePrice, 'price_field', listPrice, listPriceEnableInMarket));
                    if (
                        profileFromSetPrice
                        && mullionPriceType !== 'structured_muntin_frame'
                        && !joinFixAddAstragals
                    ) {
                        pricePiece -= parseFloat(getListPrice(profileFromSetPrice, 'price_piece', listPrice, listPriceEnableInMarket));
                        priceLength -= parseFloat(getListPrice(profileFromSetPrice, 'price_length', listPrice, listPriceEnableInMarket));
                        priceField -= parseFloat(getListPrice(profileFromSetPrice, 'price_field', listPrice, listPriceEnableInMarket));
                    }
                    priceSegments.push({
                        type: 'dividers',
                        baseValue: price(pricePiece),
                        value: price(pricePiece),
                        valueType: 'value',
                        data: {
                            profileId: mullion.profileId,
                            divId: mullion.id,
                            length,
                        },
                    });
                    priceSegments.push({
                        type: 'dividers',
                        baseValue: price(priceLength, length),
                        value: price(priceLength, length),
                        valueType: 'value',
                        data: {
                            profileId: mullion.profileId,
                            divId: mullion.id,
                            length,
                        },
                    });
                    priceSegments.push({
                        type: 'dividers',
                        baseValue:
                            price(priceField, areas && areas[mullion.profileId + '_' + mullion.id]
                                ? areas[mullion.profileId + '_' + mullion.id].length
                                : 0),
                        value:
                            price(priceField, areas && areas[mullion.profileId + '_' + mullion.id]
                                ? areas[mullion.profileId + '_' + mullion.id].length
                                : 0),
                        valueType: 'value',
                        data: {
                            profileId: mullion.profileId,
                            divId: mullion.id,
                            length,
                        },
                    });
                    if (reinforcement) {
                        let reinforcementLength = length * 1000;
                        if (mullionProfile) {
                            reinforcementLength =
                                length * 1000
                                - mullionProfile.spaceReinforcement * 2
                                - reinforcement.length_correction * 2;
                        }
                        if (reinforcement.length_step) {
                            reinforcementLength =
                                Math.floor(reinforcementLength / reinforcement.length_step)
                                * reinforcement.length_step;
                        }
                        priceSegments.push({
                            type: 'dividers',
                            baseValue: price(getListPrice(reinforcement, 'price', listPrice, listPriceEnableInMarket), reinforcementLength / 1000),
                            value: price(getListPrice(reinforcement, 'price', listPrice, listPriceEnableInMarket), reinforcementLength / 1000),
                            valueType: 'value',
                            data: {
                                profileId: mullion.profileId,
                                divId: mullion.id,
                                length: reinforcementLength / 1000,
                            },
                        });
                    }
                }
                if (profileAlushellPrice && hasAlushell) {
                    let pricePiece = parseFloat(getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket));
                    let priceLength = parseFloat(getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket));
                    if (
                        profileAlushellFromSetPrice
                        && this.config().IccConfig.Configurators.price
                            .alushellProfileSetPriceSubtraction
                    ) {
                        pricePiece -= parseFloat(getListPrice(profileAlushellFromSetPrice, 'price_piece', listPrice, listPriceEnableInMarket));
                        priceLength -= parseFloat(getListPrice(profileAlushellFromSetPrice, 'price_length', listPrice, listPriceEnableInMarket));
                    }
                    priceSegments.push({
                        type: 'alushell',
                        baseValue: price(pricePiece),
                        value: price(pricePiece),
                        valueType: 'value',
                        data: {
                            profileId: mullion.profileId,
                            divId: mullion.id,
                            length,
                        },
                    });
                    priceSegments.push({
                        type: 'alushell',
                        baseValue: price(priceLength, length),
                        value: price(priceLength, length),
                        valueType: 'value',
                        data: {
                            profileId: mullion.profileId,
                            divId: mullion.id,
                            length,
                        },
                    });
                }
                if (!profilePrice && (!hasAlushell || !profileAlushellPrice) && (mullion.type !== 'no_mullion' || !mullion.valid)) {
                    priceSegments.push({
                        type: 'dividers',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {
                            profileId: mullion.profileId,
                            divId: mullion.id,
                            length,
                        },
                    });
                }
            });
        }

        return priceSegments;
    }

    /**
     * Dolicza doplate za laczenie
     * @param  {number} price       Cena wejściowa
     * @param  {object} PriceElems  Wycena
     * @param  {array}  sashes      Skrzydła
     * @param  {object} system      System
     * @return {number}             Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'crossDividers',
        data: {
            sashes: 'conf.Sashes',
            system: 'conf.System',
        },
    })
    suppCrossDividers({ PriceElems }: PriceElemsData, { sashes, system }): PriceSegment[] {
        let supp = 0;
        let i = 0;
        let counter;
        const priceSegments: PriceSegment[] = [];

        for (i; i < sashes.length; i++) {
            counter = this.crossCounter(sashes[i]);
            supp += counter.length * (system.cross_supp * 1 || 0);
            PriceElems.dividersCross.push({
                sashId: sashes[i].id,
                sashIndex: sashes[i].index,
                name: counter.length,
                price: counter.length * (system.cross_supp * 1 || 0),
            });
            priceSegments.push({
                type: 'dividersCross',
                baseValue: counter.length * (system.cross_supp * 1 || 0),
                value: counter.length * (system.cross_supp * 1 || 0),
                valueType: 'value',
                data: {
                    name: counter.length,
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                },
            });
        }

        return priceSegments;
    }

    /**
     * Dolicza doplate za rame konstrukcji
     * @param  {number} price         Cena wejściowa
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Eleenty bez wyceny
     * @param  {object} conf          Konfiguracja
     * @param  {object} prices        Ceny ram
     * @param  {object} colors        Kolory okna
     * @return {number}               Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'frame',
        data: {
            drawData: 'conf.drawData',
            frames: 'conf.Frames',
            system: 'conf.System',
            width: 'conf.Width',
            height: 'conf.Height',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            colors: 'conf.Colors',
            sashes: 'conf.Sashes',
            profileSets: 'data.profileSets',
            alushells: 'data.alushells',
            hasAlushell: 'conf.HasAlushell',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppFrame(
        { PriceStack, PriceElems, NoPriceCauses, listPrice }: PriceElemsData,
        {
            drawData,
            frames,
            system,
            width,
            height,
            prices,
            profiles,
            colors,
            sashes,
            profileSets,
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];

        const sashesSegments = PriceStack.filter(
            s => s.type === 'sashes' && s.value !== null && s.data && s.data.sashIds
        );
        const sashProfileSetMap = sashesSegments.reduce((map, seg) => {
            seg.data.sashIds.forEach(id => {
                map[id] = seg.data.profileSetId;
            });
            return map;
        }, {});
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        sashes.forEach(sash => {
            const sashData = drawData && drawData.sash.find(o => o.sashId === sash.id);
            const frame = frames && frames.find(f => f.id === sash.frameId);
            const mainProfileId = frame && this.getMainFrameProfile(frame.frame);
            const frameData =
                drawData && drawData.frame && drawData.frame.find(f => f.frameId === frame.id);
            if (sashData && frameData && sashData) {
                const profileSetForSash = this.profileSetsService.getProfileSet(
                    sashProfileSetMap[sash.id],
                    profileSets
                );
                const profilesPrice = sashData.parallel.poly.reduce(
                    (prev, frameIndex, sashIndex) => {
                        if (Common.isNumber(frameIndex)) {
                            const frameProfileId = sashData.parallel.profiles[sashIndex];
                            const profile = this.getProfile(frameProfileId, profiles);
                            const profilePrice = this.profilesPriceService.getProfilePrice(
                                frameProfileId,
                                'frame',
                                system,
                                colors.frame,
                                prices,
                                customPrice
                            );
                            const profileAlushellPrice = this.profilesPriceService.getProfileAlushellPrice(
                                frameProfileId,
                            'frame',
                                hasAlushell,
                                alushells,
                                colors.frame,
                                alushellPrices,
                                customPrice
                            );
                            const profileFromSetPrice = profileSetForSash
                                ? this.profilesPriceService.getProfilePrice(
                                      profileSetForSash.frameSide,
                                      'frame',
                                      system,
                                      colors.frame,
                                      prices,
                                      customPrice
                                  )
                                : null;
                            const profileAlushellFromSetPrice = profileSetForSash
                                ? this.profilesPriceService.getProfileAlushellPrice(
                                      profileSetForSash.frameSide,
                                      'frame',
                                      hasAlushell,
                                      alushells,
                                      colors.frame,
                                      alushellPrices,
                                      customPrice
                                  )
                                : null;
                            const frameEdgeLength = IccDrawMathService.getLineInfo([
                                frameData.outer.poly[frameIndex],
                                frameData.outer.poly[
                                    (frameIndex + 1) % frameData.outer.poly.length
                                ],
                            ]).length;
                            const sashEdgeLength = IccDrawMathService.getLineInfo([
                                sashData.outer.poly[sashIndex],
                                sashData.outer.poly[(sashIndex + 1) % sashData.outer.poly.length],
                            ]).length;
                            const fractionOfProfile = sashEdgeLength / frameEdgeLength;
                            if (profilePrice) {
                                let pricePiece =
                                    parseFloat(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket)) * fractionOfProfile;
                                let priceLength =
                                    parseFloat(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket)) * fractionOfProfile;
                                let pricePercent = parseFloat(getListPrice(profilePrice, 'price_percent', listPrice, listPriceEnableInMarket));
                                let priceField = parseFloat(getListPrice(profilePrice, 'price_field', listPrice, listPriceEnableInMarket));
                                let priceConstruction = parseFloat(getListPrice(profilePrice, 'price_construction', listPrice, listPriceEnableInMarket));
                                if (profileFromSetPrice && this.config().IccConfig.Configurators.price.profileFromSetPrice) {
                                    pricePiece -=
                                        parseFloat(getListPrice(profileFromSetPrice, 'price_piece', listPrice, listPriceEnableInMarket))
                                        * fractionOfProfile;
                                    priceLength -=
                                        parseFloat(getListPrice(profileFromSetPrice, 'price_length', listPrice, listPriceEnableInMarket))
                                        * fractionOfProfile;
                                    pricePercent -= parseFloat(getListPrice(profileFromSetPrice, 'price_percent', listPrice, listPriceEnableInMarket));
                                    priceField -= parseFloat(getListPrice(profileFromSetPrice, 'price_field', listPrice, listPriceEnableInMarket));
                                    priceConstruction -= parseFloat(getListPrice(profileFromSetPrice, 'price_construction', listPrice, listPriceEnableInMarket));
                                }
                                if (
                                    this.config().IccConfig.Configurators.price.frameProfilesAlt
                                    && frameProfileId !== mainProfileId
                                ) {
                                    priceLength = priceField;
                                }
                                priceSegments.push({
                                    type: 'frame',
                                    baseValue: price(pricePiece),
                                    value: price(pricePiece),
                                    valueType: 'value',
                                    data: {
                                        profileId: frameProfileId,
                                        frameId: frame.id,
                                        unit: 'piece',
                                    },
                                });
                                priceSegments.push({
                                    type: 'frame',
                                    baseValue: price(priceLength, frameEdgeLength / 1000),
                                    value: price(priceLength, frameEdgeLength / 1000),
                                    valueType: 'value',
                                    data: {
                                        profileId: frameProfileId,
                                        frameId: frame.id,
                                        unit: 'length',
                                        length: frameEdgeLength / 1000,
                                    },
                                });
                                if (
                                    pricePercent !== null
                                    && (Number(pricePercent) > prev.percent
                                        || prev.percent === null)
                                ) {
                                    prev.percent = Number(pricePercent);
                                }
                                if (
                                    priceField !== null
                                    && (Number(priceField) > prev.field || prev.field === null)
                                ) {
                                    prev.field = Number(priceField);
                                }
                                if (
                                    priceConstruction !== null
                                    && (Number(priceConstruction) > prev.construction || prev.construction === null)
                                ) {
                                    prev.construction = Number(priceConstruction);
                                }
                                if (
                                    profile.priceFinConstruction !== null
                                    && (Number(profile.priceFinConstruction) > prev.finConstruction
                                        || prev.finConstruction === null)
                                ) {
                                    prev.finConstruction = Number(profile.priceFinConstruction);
                                }
                                if (
                                    frame.frame[frameIndex]
                                    && profile.finWidth !== frame.frame[frameIndex].finWidth
                                    && profile.options.indexOf('renovation_frame') > -1
                                ) {
                                    prev.croppedEdges += 1;
                                    priceSegments.push({
                                        type: 'renoFrame',
                                        baseValue: price(
                                            profile.priceFinLength,
                                            frameEdgeLength / 1000
                                        ),
                                        value: price(
                                            profile.priceFinLength,
                                            frameEdgeLength / 1000
                                        ),
                                        valueType: 'value',
                                        data: {
                                            profileId: frameProfileId,
                                            frameId: frame.id,
                                        },
                                    });
                                    priceSegments.push({
                                        type: 'renoFrame',
                                        baseValue: price(profile.priceFinEdge, fractionOfProfile),
                                        value: price(profile.priceFinEdge, fractionOfProfile),
                                        valueType: 'value',
                                        data: {
                                            profileId: frameProfileId,
                                            frameId: frame.id,
                                        },
                                    });
                                }
                            }
                            if (profileAlushellPrice) {
                                let pricePiece =
                                    parseFloat(getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket))
                                    * fractionOfProfile;
                                let priceLength =
                                    parseFloat(getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket))
                                    * fractionOfProfile;
                                if (
                                    profileAlushellFromSetPrice
                                    && this.config().IccConfig.Configurators.price
                                        .alushellProfileSetPriceSubtraction
                                ) {
                                    pricePiece -=
                                        parseFloat(getListPrice(profileAlushellFromSetPrice, 'price_piece', listPrice, listPriceEnableInMarket))
                                        * fractionOfProfile;
                                    priceLength -=
                                        parseFloat(getListPrice(profileAlushellFromSetPrice, 'price_length', listPrice, listPriceEnableInMarket))
                                        * fractionOfProfile;
                                }
                                priceSegments.push({
                                    type: 'alushell',
                                    baseValue: price(pricePiece),
                                    value: price(pricePiece),
                                    valueType: 'value',
                                    data: {
                                        profileId: frameProfileId,
                                        frameId: frame.id,
                                        unit: 'piece',
                                    },
                                });
                                priceSegments.push({
                                    type: 'alushell',
                                    baseValue: price(priceLength, frameEdgeLength / 1000),
                                    value: price(priceLength, frameEdgeLength / 1000),
                                    valueType: 'value',
                                    data: {
                                        profileId: frameProfileId,
                                        frameId: frame.id,
                                        unit: 'length',
                                        length: frameEdgeLength / 1000,
                                    },
                                });
                            }
                        }
                        return prev;
                    },
                    {
                        field: null,
                        percent: null,
                        construction: null,
                        finConstruction: null,
                        croppedEdges: 0,
                    }
                );

                const profilesIds = frame.frame
                    .filter(this.noThreshold.bind(this, profiles))
                    .filter(frameProfile => frameProfile)
                    .map(profile => profile.profileId);

                const currentConstructionSegment = priceSegments.find(
                    el =>
                        el.type === 'frame'
                        && el.valueType === 'value'
                        && el.data.unit === 'construction'
                );

                if (!currentConstructionSegment) {
                    priceSegments.push({
                        type: 'frame',
                        baseValue: profilesPrice.construction,
                        value: profilesPrice.construction,
                        valueType: 'value',
                        data: {
                            profileId: profilesIds,
                            frameId: frame.id,
                            unit: 'construction',
                        },
                    });
                } else if (profilesPrice.construction > currentConstructionSegment.value) {
                    currentConstructionSegment.baseValue = profilesPrice.construction;
                    currentConstructionSegment.value = profilesPrice.construction;
                }

                if (
                    sash.nearMullions.bottom === -1
                    && ([
                        'DK',
                        'D',
                        'DS',
                        'DSC',
                        'DRA',
                        'DRP',
                        'OD',
                        'DOA',
                        'DOP',
                        'ODS',
                        'FD',
                        'FDO',
                        'DSH',
                        'ODSH',
                    ].indexOf(sash.type.type) > -1
                        || (this.config().IccConfig.Configurators.price
                            .includeFixOverLowThreshold
                            && sash.type.type === 'F'))
                    && sashData.parallel.poly.reduce(
                        (prev, curr) => (Common.isNumber(curr) ? prev + 1 : prev),
                        0
                    ) === 1
                ) {
                    return;
                }

                if (
                    sashData.parallel.poly
                        .reduce((prev, curr) => Common.isNumber(curr) ? prev + 1 : prev, 0) === 0
                ) {
                    return;
                }

                if (!this.config().IccConfig.Configurators.price.frameProfilesAlt) {
                    priceSegments.push({
                        type: 'frame',
                        baseValue: profilesPrice.field,
                        value: profilesPrice.field,
                        valueType: 'value',
                        data: {
                            profilesId: profilesIds,
                            frameId: frame.id,
                            unit: 'field',
                        },
                    });
                }

                const currentPercentSegment = priceSegments.find(
                    el =>
                        el.type === 'frame'
                        && el.valueType === 'percent'
                        && core.stringJson(el.data.profileId) === core.stringJson(profilesIds)
                );

                if (!currentPercentSegment) {
                    priceSegments.push({
                        type: 'frame',
                        baseValue: (profilesPrice.percent + 100) / 100,
                        value: (profilesPrice.percent + 100) / 100,
                        valueType: 'percent',
                        data: {
                            profileId: profilesIds,
                            frameId: frame.id,
                            unit: 'percent',
                        },
                    });
                } else if ((profilesPrice.percent + 100) / 100 > currentPercentSegment.value) {
                    currentPercentSegment.baseValue = (profilesPrice.percent + 100) / 100;
                    currentPercentSegment.value = (profilesPrice.percent + 100) / 100;
                }

                if (profilesPrice.croppedEdges > 0) {
                    const currentFinConstructionSegment = priceSegments.find(
                        el =>
                            el.type === 'renoFrame'
                            && el.valueType === 'value'
                            && core.stringJson(el.data.profileId) === core.stringJson(profilesIds)
                    );

                    if (!currentFinConstructionSegment) {
                        priceSegments.push({
                            type: 'renoFrame',
                            baseValue: profilesPrice.finConstruction,
                            value: profilesPrice.finConstruction,
                            valueType: 'value',
                            data: {
                                profileId: profilesIds,
                                frameId: frame.id,
                            },
                        });
                    } else if (profilesPrice.finConstruction > currentFinConstructionSegment.value) {
                        currentFinConstructionSegment.baseValue = profilesPrice.finConstruction;
                        currentFinConstructionSegment.value = profilesPrice.finConstruction;
                    }

                }
            } else {
                priceSegments.push({
                    type: 'frame',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {},
                });
                NoPriceCauses.push('no prices for frame');
            }
        });
        return priceSegments;
    }

    /**
     * Dolicza doplate za rame skrzydla
     * @param  {number} price         Cena wejściowa
     * @param  {object} PriceElems    Wycena
     * @param  {array}  NoPriceCauses Powody niewycenienia
     * @param  {array}  sashes        Skrzydla
     * @param  {object} prices        Ceny ram
     * @param  {object} colors        Kolory okna
     * @return {number}               Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'sashFrame',
        data: {
            drawData: 'conf.drawData',
            sashes: 'conf.Sashes',
            colors: 'conf.Colors',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            system: 'conf.System',
            profileSets: 'data.profileSets',
            reinforcements: 'data.reinforcements',
            alushells: 'data.alushells',
            hasAlushell: 'conf.HasAlushell',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppSashFrames(
        { PriceStack, NoPriceCauses, listPrice }: PriceElemsData,
        {
            drawData,
            sashes,
            prices,
            profiles,
            colors,
            system,
            profileSets,
            reinforcements,
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }
    ): PriceSegment[] {
        const i = 0;
        const rate = 0;
        const sashPrice = 0;
        const priceSegments: PriceSegment[] = [];
        const elem = 'sashFrames';
        const sashesSegments = PriceStack.filter(
            s => s.type === 'sashes' && s.value !== null && s.data && s.data.sashIds
        );
        const sashProfileSetMap = sashesSegments.reduce((map, seg) => {
            seg.data.sashIds.forEach(id => {
                map[id] = seg.data.profileSetId;
            });
            return map;
        }, {});
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        sashes
            .filter(sash => sash.type.type !== 'F')
            .forEach(sash => {
                const profilesDimensionsData = drawData.sashFrame.find(o => o.sashId === sash.id);
                if (Common.isObject(sash.frame) && profilesDimensionsData) {
                    const profileSetForSash = this.profileSetsService.getProfileSet(
                        sashProfileSetMap[sash.id],
                        profileSets
                    );
                    const profilesPrice = ['left', 'right', 'top', 'bottom']
                        .filter(side => sash.frame[side])
                        .reduce(
                            (prev, side) => {
                                const sashProfile = profiles.find(
                                    o => o.id === sash.frame[side].profileId
                                );
                                const type = sashProfile && sashProfile.type || 'sash';
                                const profileDimensions = profilesDimensionsData.sides.filter(
                                    sideDimensions => sideDimensions.outerEdge.side === side
                                );
                                const profilePrice = this.profilesPriceService.getProfilePrice(
                                    sash.frame[side].profileId,
                                    type,
                                    system,
                                    colors.sash,
                                    prices,
                                    customPrice
                                );
                                const profileAlushellPrice = this.profilesPriceService.getProfileAlushellPrice(
                                    sash.frame[side].profileId,
                                    type,
                                    hasAlushell,
                                    alushells,
                                    colors.sash,
                                    alushellPrices,
                                    customPrice
                                );
                                const profileFromSetPrice = profileSetForSash
                                    ? this.profilesPriceService.getProfilePrice(
                                          profileSetForSash[
                                              SashTypes.OUTWARD_OPENING.indexOf(sash.type.type) > -1
                                                  ? 'sashOutward'
                                                  : 'sash'
                                          ],
                                          type,
                                          system,
                                          colors.sash,
                                          prices,
                                          customPrice
                                      )
                                    : null;
                                const profileAlushellFromSetPrice = profileSetForSash
                                    ? this.profilesPriceService.getProfileAlushellPrice(
                                          profileSetForSash[
                                              SashTypes.OUTWARD_OPENING.indexOf(sash.type.type) > -1
                                                  ? 'sashOutward'
                                                  : 'sash'
                                          ],
                                          type,
                                          hasAlushell,
                                          alushells,
                                          colors.frame,
                                          alushellPrices,
                                          customPrice
                                      )
                                    : null;

                                if (profilePrice) {
                                    let pricePiece = parseFloat(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket));
                                    let priceLength = parseFloat(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket));
                                    let priceField = parseFloat(getListPrice(profilePrice, 'price_field', listPrice, listPriceEnableInMarket));
                                    if (profileFromSetPrice) {
                                        pricePiece -= parseFloat(getListPrice(profileFromSetPrice, 'price_piece', listPrice, listPriceEnableInMarket));
                                        priceLength -= parseFloat(getListPrice(profileFromSetPrice, 'price_length', listPrice, listPriceEnableInMarket));
                                        priceField -= parseFloat(getListPrice(profileFromSetPrice, 'price_field', listPrice, listPriceEnableInMarket));
                                    }
                                    priceSegments.push({
                                        type: 'sashFrames',
                                        baseValue: price(pricePiece),
                                        value: price(pricePiece),
                                        valueType: 'value',
                                        data: {
                                            profileId: sash.frame[side].profileId,
                                            sashId: sash.id,
                                        },
                                    });
                                    let profileLength = 0;
                                    if (profileDimensions && profileDimensions.length > 0) {
                                        profileDimensions.forEach(
                                            s => (profileLength += s.outerEdge.length)
                                        );
                                        priceSegments.push({
                                            type: 'sashFrames',
                                            baseValue: price(priceLength, profileLength / 1000),
                                            value: price(priceLength, profileLength / 1000),
                                            valueType: 'value',
                                            data: {
                                                profileId: sash.frame[side].profileId,
                                                sashId: sash.id,
                                                length: Math.round(profileLength) / 1000,
                                            },
                                        });
                                        const reinforcement = reinforcements
                                            ? reinforcements.find(
                                                  o =>
                                                      profileDimensions.some(
                                                          s => !s.outerEdge.circle
                                                      )
                                                      && sash.frame[side].reinforcement
                                                      && Number(o.id)
                                                          === Number(
                                                              sash.frame[side].reinforcement.id
                                                          )
                                              )
                                            : null;
                                        if (reinforcement) {
                                            let reinforcementLength = profileDimensions.reduce(
                                                (p, s) =>
                                                    !s.outerEdge.circle
                                                        ? (p += s.outerEdge.length)
                                                        : p,
                                                0
                                            );
                                            if (sashProfile) {
                                                reinforcementLength =
                                                    reinforcementLength
                                                    - sashProfile.spaceReinforcement * 2
                                                    - reinforcement.length_correction * 2;
                                            }
                                            if (reinforcement.length_step) {
                                                reinforcementLength =
                                                    Math.floor(
                                                        reinforcementLength
                                                            / reinforcement.length_step
                                                    ) * reinforcement.length_step;
                                            }
                                            priceSegments.push({
                                                type: 'sashFrames',
                                                baseValue: price(
                                                    getListPrice(reinforcement, 'price', listPrice, listPriceEnableInMarket),
                                                    reinforcementLength / 1000
                                                ),
                                                value: price(
                                                    getListPrice(reinforcement, 'price', listPrice, listPriceEnableInMarket),
                                                    reinforcementLength / 1000
                                                ),
                                                valueType: 'value',
                                                data: {
                                                    profileId: sash.frame[side].profileId,
                                                    reinforcementId: reinforcement.id,
                                                    sashId: sash.id,
                                                    length: reinforcementLength / 1000,
                                                },
                                            });
                                        }
                                    }
                                    if (
                                        priceField !== null
                                        && (Number(priceField) > prev.field || prev.field === null)
                                    ) {
                                        prev.field = Number(priceField);
                                    }
                                    if (
                                        profileAlushellPrice
                                        && profileDimensions
                                        && profileDimensions.length > 0
                                    ) {
                                        let priceAlushellPiece = parseFloat(
                                            getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket)
                                        );
                                        let priceAlushellLength = parseFloat(
                                            getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket)
                                        );
                                        if (
                                            profileAlushellFromSetPrice
                                            && this.config().IccConfig.Configurators.price
                                                .alushellProfileSetPriceSubtraction
                                        ) {
                                            priceAlushellPiece -= parseFloat(
                                                getListPrice(profileAlushellFromSetPrice, 'price_piece', listPrice, listPriceEnableInMarket)
                                            );
                                            priceAlushellLength -= parseFloat(
                                                getListPrice(profileAlushellFromSetPrice, 'price_length', listPrice, listPriceEnableInMarket)
                                            );
                                        }
                                        priceSegments.push({
                                            type: 'alushell',
                                            baseValue: price(priceAlushellPiece),
                                            value: price(priceAlushellPiece),
                                            valueType: 'value',
                                            data: {
                                                profileId: sash.frame[side].profileId,
                                                sashId: sash.id,
                                            },
                                        });
                                        priceSegments.push({
                                            type: 'alushell',
                                            baseValue: price(
                                                priceAlushellLength,
                                                profileLength / 1000
                                            ),
                                            value: price(priceAlushellLength, profileLength / 1000),
                                            valueType: 'value',
                                            data: {
                                                profileId: sash.frame[side].profileId,
                                                sashId: sash.id,
                                            },
                                        });
                                    }
                                } else {
                                    return {
                                        field: null,
                                        percent: null,
                                    };
                                }
                                return prev;
                            },
                            {
                                field: null,
                                percent: null,
                            }
                        );
                    priceSegments.push({
                        type: 'sashFrames',
                        baseValue: profilesPrice.field,
                        value: profilesPrice.field,
                        valueType: 'value',
                        data: {
                            profilesId: ['left', 'right', 'top', 'bottom']
                                .filter(side => sash.frame[side])
                                .map(side => sash.frame[side].profileId),
                            sashId: sash.id,
                        },
                    });
                } else {
                    priceSegments.push({
                        type: 'sashFrames',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {},
                    });
                    NoPriceCauses.push('no prices for sash frame');
                }
            });

        return priceSegments;
    }

    @PriceFunc({
        shortName: 'sideProfiles',
        data: {
            drawData: 'conf.drawData',
            sideProfiles: 'conf.SideProfiles',
            system: 'conf.System',
            width: 'conf.Width',
            height: 'conf.Height',
            prices: 'data.profilesPrices',
            pricesForLength: 'data.profilesPricesForLength',
            allProfiles: 'data.profiles',
            colors: 'conf.Colors',
            alushells: 'data.alushells',
            hasAlushell: 'conf.HasAlushell',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppSideProfiles(
        { PriceElems, NoPriceCauses, listPrice }: PriceElemsData,
        {
            drawData,
            sideProfiles,
            system,
            width,
            height,
            prices,
            pricesForLength,
            allProfiles,
            colors,
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }: {
            drawData,
            sideProfiles: WindowActiveConfiguration['SideProfiles'],
            system,
            width,
            height,
            prices,
            pricesForLength,
            allProfiles,
            colors: WindowActiveConfiguration['Colors'],
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        if (Common.isObject(prices) && Common.isArray(sideProfiles)) {
            const dimension = {
                height,
                width
            }

            // eslint-disable-next-line max-statements
            sideProfiles.forEach((sideProfile, index) => {
                const profileColors = sideProfile.color
                    ? sideProfile.color
                    : colors.frame;
                const profileDimensions = drawData.extension.find(
                    o => o.extensionId === sideProfile.id
                );
                const profile = this.getProfile(sideProfile.profileId, allProfiles);
                const profilePrice = this.profilesPriceService.getProfilePrice(
                    sideProfile.profileId,
                    profile ? profile.type : null,
                    system,
                    profileColors,
                    prices,
                    customPrice,
                    pricesForLength,
                    profileDimensions?.length
                );
                const profileAlushellPrice = this.profilesPriceService.getProfileAlushellPrice(
                    sideProfile.profileId,
                    profile ? profile.type : null,
                    hasAlushell,
                    alushells,
                    profileColors,
                    alushellPrices,
                    customPrice
                );

                if (profilePrice) {
                    if (this.isPricingBasedOnLength(profile, profilePrice)) {
                        let profilePriceBasedOnLength = 0;
                        let count = 0;
                        if (profile?.options?.includes('bottom') || profile?.options?.includes('top')) {
                            const constructionWidth = dimension.width += sideProfiles.reduce((p, c) => c.side === 'left' || c.side === 'right' ? (p + c.width) : 0, 0)
                            profilePriceBasedOnLength = Math.ceil(constructionWidth / profile.length * sideProfile.count) * getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket);
                            count = Math.ceil(dimension.width / profile.length * sideProfile.count);
                        } else {
                            profilePriceBasedOnLength = Math.ceil(dimension.height / profile.length * sideProfile.count) * getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket);
                            count = Math.ceil(dimension.height / profile.length * sideProfile.count);
                        }

                        priceSegments.push({
                            type: 'sideProfile',
                            baseValue: profilePriceBasedOnLength,
                            value: profilePriceBasedOnLength,
                            valueType: 'value',
                            data: {
                                profileId: sideProfile.profileId,
                                priceLevelId: profile.priceLevelId,
                                id: sideProfile.id,
                                count
                            },
                        })
                    } else {
                        priceSegments.push({
                            type: 'sideProfile',
                            baseValue: price(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket),sideProfile.count),
                            value: price(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket), sideProfile.count),
                            valueType: 'value',
                            data: {
                                profileId: sideProfile.profileId,
                                priceLevelId: profile.priceLevelId,
                                id: sideProfile.id,
                                count: sideProfile.count,
                            },
                        });
                        if (profileDimensions) {
                            priceSegments.push({
                                type: 'sideProfile',
                                baseValue: price(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket), profileDimensions.length / 1000 * sideProfile.count),
                                value: price(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket), profileDimensions.length / 1000 * sideProfile.count),
                                valueType: 'value',
                                data: {
                                    profileId: sideProfile.profileId,
                                    priceLevelId: profile.priceLevelId,
                                    id: sideProfile.id,
                                    count: sideProfile.count,
                                },
                            });
                            if (profile.type === 'sandwich') {
                                priceSegments.push({
                                    type: 'sideProfile',
                                    baseValue: price(getListPrice(profilePrice, 'price_area', listPrice, listPriceEnableInMarket), profileDimensions.polyArea
                                        * sideProfile.count),
                                    value: price(getListPrice(profilePrice, 'price_area', listPrice, listPriceEnableInMarket), profileDimensions.polyArea
                                        * sideProfile.count),
                                    valueType: 'value',
                                    data: {
                                        profileId: sideProfile.profileId,
                                        priceLevelId: profile.priceLevelId,
                                        id: sideProfile.id,
                                        count: sideProfile.count,
                                    },
                                });
                            }
                            if (sideProfile.reinforcement?.id) {
                                priceSegments.push({
                                    type: 'sideProfile',
                                    baseValue: price(
                                        getListPrice(sideProfile.reinforcement, 'price', listPrice, listPriceEnableInMarket),
                                        profileDimensions.length / 1000
                                    ),
                                    value: price(
                                        getListPrice(sideProfile.reinforcement, 'price', listPrice, listPriceEnableInMarket),
                                        profileDimensions.length / 1000
                                    ),
                                    valueType: 'value',
                                    data: {
                                        id: sideProfile.id,
                                        profileId: sideProfile.profileId,
                                        reinforcementId: sideProfile.reinforcement.id,
                                        length: profileDimensions.length / 1000,
                                    },
                                });
                            }
                        }
                        if (profileAlushellPrice && profileDimensions) {
                            priceSegments.push({
                                type: 'alushell',
                                baseValue: price(getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket), sideProfile.count),
                                value: price(getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket), sideProfile.count),
                                valueType: 'value',
                                data: {
                                    profileId: sideProfile.profileId,
                                    id: sideProfile.id,
                                    count: sideProfile.count,
                                },
                            });
                            priceSegments.push({
                                type: 'alushell',
                                baseValue:
                                    price(getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket), profileDimensions.length / 1000 * sideProfile.count),
                                value:
                                    price(getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket), profileDimensions.length / 1000 * sideProfile.count),
                                valueType: 'value',
                                data: {
                                    profileId: sideProfile.profileId,
                                    id: sideProfile.id,
                                    count: sideProfile.count,
                                },
                            });
                        }
                    }
                } else {
                    priceSegments.push({
                        type: 'sideProfile',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {},
                    });
                    NoPriceCauses.push('no prices for sideProfile');
                }
            });
        } else {
            priceSegments.push({
                type: 'sideProfile',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {},
            });
            NoPriceCauses.push('no prices for sideProfile');
        }
        return priceSegments;
    }

    @PriceFunc({
        shortName: 'sideProfileColors',
        data: {
            drawData: 'conf.drawData',
            sideProfiles: 'conf.SideProfiles',
            system: 'conf.System',
            colors: 'conf.Colors',
            customPrice: 'price.Profile',
            colorGroups: 'data.windowColorGroups',
            colorGroupsPrices: 'data.windowColorGroupsPrices',
        },
    })
    suppSideProfileColors(
        { PriceStack, NoPriceCauses }: PriceElemsData,
        {
            drawData,
            sideProfiles,
            system,
            colors,
            customPrice,
            colorGroups,
            colorGroupsPrices,
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (Common.isArray(sideProfiles) && sideProfiles.length && this.config().IccConfig.Configurators.price.colorFactorToSideProfile) {
            const priceSideProfiles = PriceStack.filter(
                s => s.type === 'sideProfile'
            );

            if (!priceSideProfiles.length) {
                return [];
            }

            sideProfiles.forEach((sideProfile, index) => {
                const profileColors = sideProfile.color
                    ? { frame: sideProfile.color, sash: sideProfile.color }
                    : colors;


                let colorFactor = 0;

                if (colorGroups?.length) {
                    if (colorGroupsPrices == null) {
                        colorGroupsPrices = [];
                    }
                    colorFactor = this.priceColorsService.getColorFactor({
                        NoPriceCauses,
                        isAlushell: false,
                        colorGroups,
                        colorGroupsPrices,
                        colors: profileColors,
                        system,
                        wood: sideProfile.wood,
                        sash: [],
                        sashId: [],
                        divId: [],
                        drawData,
                        sashes: [],
                        mullions: [],
                        customPrice,
                    });
                }

                priceSegments.push({
                    type: 'sideProfileColor',
                    baseValue: !isNaN(colorFactor) ? 1 + colorFactor / 100 : null,
                    value: !isNaN(colorFactor) ? 1 + colorFactor / 100 : null,
                    valueType: 'percent',
                    data: {
                        profileId: sideProfile.profileId,
                        id: sideProfile.id,
                        count: sideProfile.count,
                    },
                    to: priceSideProfiles.filter(seg => seg.data.id === sideProfile.id).map(seg => seg.id),
                });

            });

        }

        return priceSegments;
    }


    isPricingBasedOnLength(profile, profilePrice) {
        return (profile.type === 'extension' && profile.length && profilePrice.price_piece);
    }

    calculatePricingBasedOnLength(profile, segment, profilePriceBasedOnLength, dimension, sideProfile, profilePrice) {
        if (profile?.options?.includes('bottom') || profile?.options?.includes('top')) {
            profilePriceBasedOnLength = Math.ceil(dimension.width / profile.length * sideProfile.totalQuantity) * profilePrice.price_piece;
        } else {
            profilePriceBasedOnLength = Math.ceil(dimension.height / profile.length * sideProfile.totalQuantity) * profilePrice.price_piece;
        }
        segment.data.count = sideProfile.totalQuantity
        segment.baseValue = profilePriceBasedOnLength
        segment.value = profilePriceBasedOnLength;
        return profilePriceBasedOnLength;
    }

    @PriceFunc({
        shortName: 'alignments',
        data: {
            drawData: 'conf.drawData',
            alignments: 'conf.Alignments',
            mullions: 'conf.Mullions',
            frames: 'conf.Frames',
            sashes: 'conf.Sashes',
            system: 'conf.System',
            width: 'conf.Width',
            height: 'conf.Height',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            colors: 'conf.Colors',
            alushells: 'data.alushells',
            hasAlushell: 'conf.HasAlushell',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppAlignments(
        { PriceElems, NoPriceCauses }: PriceElemsData,
        {
            drawData,
            alignments,
            mullions,
            frames,
            sashes,
            system,
            width,
            height,
            prices,
            profiles,
            colors,
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (!Common.isArray(alignments)) {
            return priceSegments;
        }
        if (Common.isObject(prices) && Common.isArray(alignments) && Common.isArray(sashes)) {
            alignments
                .filter(alignment => alignment)
                .filter(alignment => profiles.find(profile => profile.id === alignment.profileId).type !== 'reversing')
                .forEach((alignment, index) => {
                    priceSegments.push(
                        ...this.priceAlignment(
                            drawData,
                            NoPriceCauses,
                            alignment,
                            profiles,
                            system,
                            colors.frame,
                            prices,
                            frames,
                            alignments,
                            mullions,
                            alushells,
                            hasAlushell,
                            alushellPrices,
                            customPrice
                        )
                    );
                });
            sashes
                .filter(sash => Common.isArray(sash.intAlignments))
                .forEach(sash => {
                    sash.intAlignments
                        .filter(alignment => alignment)
                        .forEach((alignment, index) => {
                            priceSegments.push(
                                ...this.priceAlignment(
                                    drawData,
                                    NoPriceCauses,
                                    alignment,
                                    profiles,
                                    system,
                                    colors.sash,
                                    prices,
                                    sash.frame,
                                    sash.intAlignments,
                                    sash.intMullions,
                                    alushells,
                                    hasAlushell,
                                    alushellPrices,
                                    customPrice
                                )
                            );
                        });
                });
        } else {
            priceSegments.push({
                type: 'alignment',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {},
            });
            NoPriceCauses.push('no prices for alignment');
        }
        return priceSegments;
    }

    @PriceFunc({
        shortName: 'reversing',
        data: {
            drawData: 'conf.drawData',
            alignments: 'conf.Alignments',
            sashes: 'conf.Sashes',
            system: 'conf.System',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            colors: 'conf.Colors',
            customPrice: 'price.Profile',
        },
    })
    suppReversing(
        { PriceElems, NoPriceCauses }: PriceElemsData,
        {
            drawData,
            alignments,
            sashes,
            system,
            prices,
            profiles,
            colors,
            customPrice,
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (!Common.isArray(alignments)) {
            return priceSegments;
        }

        if (Common.isObject(prices) && Common.isArray(alignments) && Common.isArray(sashes)) {
            let constructionPrice = null;
            const pricePice = {};
            alignments
                .filter(alignment => alignment)
                .filter(alignment => profiles.find(profile => profile.id === alignment.profileId).type === 'reversing')
                .forEach(reversing => {
                    const profilPrice = this.profilesPriceService.getProfilePrice(
                        reversing.profileId,
                        'reversing',
                        system,
                        colors.frame,
                        prices,
                        customPrice
                    );
                    if(
                        profilPrice.price_construction !== null
                        && (constructionPrice === null || Number(profilPrice.price_construction) > constructionPrice)
                    ) {
                        constructionPrice = Number(profilPrice.price_construction);
                    }
                    if(profilPrice.price_piece !== null) {
                        if(pricePice[reversing.profileId] === undefined) {
                            pricePice[reversing.profileId] = price(profilPrice.price_piece);
                        } else {
                            pricePice[reversing.profileId] += price(profilPrice.price_piece);
                        }
                    }
                    if(profilPrice.price_length !== null) {
                        const profileLength  = drawData.alignment.find(a => a.alignmentId === reversing.id).length / 1000;
                        const reversingProfile = profiles.find(p => p.id === reversing.profileId);
                        priceSegments.push({
                            type: 'reversing',
                            baseValue: price(profilPrice.price_length, profileLength),
                            value: price(profilPrice.price_length, profileLength),
                            valueType: 'value',
                            data: {
                                profileId: reversing.profileId,
                                priceLevelId: reversingProfile.priceLevelId,
                                id: reversing.id,
                                length: profileLength,
                                unit: 'length',
                            },
                        });
                    }
                });
            if(constructionPrice !== null) {
                priceSegments.push({
                    type: 'reversing',
                    baseValue: price(constructionPrice),
                    value: price(constructionPrice),
                    valueType: 'value',
                    data: {
                        unit: 'construction',
                    },
                });
            }
            if(Object.keys(pricePice).length) {
                Object.keys(pricePice).forEach(profileId => {
                    priceSegments.push({
                        type: 'reversing',
                        baseValue: price(pricePice[profileId]),
                        value: price(pricePice[profileId]),
                        valueType: 'value',
                        data: {
                            profileId,
                            unit: 'piece',
                        },
                    });
                });
            }

        }
        return priceSegments;
    }

    priceAlignment(
        drawData,
        NoPriceCauses,
        alignment,
        profiles,
        system,
        colors,
        prices,
        frames,
        alignments,
        mullions,
        alushells,
        hasAlushell,
        alushellPrices,
        customPrice
    ) {
        const priceSegments = [];
        const alignmentProfile = profiles.find(o => o.id === alignment.profileId);
        const alignmentData = drawData.alignment.find(o => o.alignmentId === alignment.id);
        const alignmentPrice = this.profilesPriceService.getProfilePrice(
            alignment.profileId,
            'alignment',
            system,
            colors,
            prices,
            customPrice
        );
        const alignmentAlushellPrice = this.profilesPriceService.getProfileAlushellPrice(
            alignment.profileId,
            'alignment',
            hasAlushell,
            alushells,
            colors,
            alushellPrices,
            customPrice
        );

        let alignmentLength = Number(alignmentData.length) || 0;

        const sides =
            alignment.direction === 'horizontal' ? { left: 3, right: 1 } : { top: 2, bottom: 0 };

        let profileId, profile;

        const frame = Common.isArray(frames) && frames.find(f => alignment.frameId === f.id);

        Object.keys(sides).forEach(side => {
            if (alignment.perpendicularAlignments[side][0]) {
                // odstęp na wyrównanie w wyrównaniu
                profileId = alignments.find(
                    o => o.id === alignment.perpendicularAlignments[side][0]
                ).profileId;
                profile = profiles.find(o => o.id === profileId);
                alignmentLength += Number(profile.width) - Number(profile.spaceAlignment);
            } else if (alignment.perpendicularMullions[side][0]) {
                // odstęp na wyrównanie w słupku
                profileId = mullions.find(o => o.id === alignment.perpendicularMullions[side][0])
                    .profileId;
                profile = profiles.find(o => o.id === profileId);
                alignmentLength += Number(profile.width) / 2 - Number(profile.spaceAlignment);
            } else if (frames[side]) {
                // odstęp na wyrównanie w skrzydle
                profileId = frames[side].profileId;
                profile = profiles.find(o => o.id === profileId);
                alignmentLength +=
                    Number(profile.width)
                    - Number(profile.rebateWidth)
                    - Number(profile.spaceAlignment);
            } else if (frame && frame[sides[side]]) {
                // odstęp na wyrównanie w ramie
                profileId = frame[sides[side]].profileId;
                profile = profiles.find(o => o.id === profileId);
                alignmentLength += Number(profile.width) - Number(profile.spaceAlignment);
            }
        });

        if (alignmentPrice) {
            priceSegments.push({
                type: 'alignment',
                baseValue: price(alignmentPrice.price_piece),
                value: price(alignmentPrice.price_piece),
                valueType: 'value',
                data: {
                    profileId: alignment.profileId,
                    id: alignment.id,
                    priceLevelId: alignmentProfile.priceLevelId,
                    length: alignmentLength / 1000,
                },
            });
            if (alignmentData) {
                priceSegments.push({
                    type: 'alignment',
                    baseValue: price(alignmentPrice.price_length, alignmentLength / 1000),
                    value: price(alignmentPrice.price_length, alignmentLength / 1000),
                    valueType: 'value',
                    data: {
                        profileId: alignment.profileId,
                        priceLevelId: alignmentProfile.priceLevelId,
                        id: alignment.id,
                        length: alignmentLength / 1000,
                    },
                });
            }
            if (alignmentAlushellPrice) {
                priceSegments.push({
                    type: 'alushell',
                    baseValue: price(alignmentAlushellPrice.price_piece),
                    value: price(alignmentAlushellPrice.price_piece),
                    valueType: 'value',
                    data: {
                        profileId: alignment.profileId,
                        priceLevelId: alignmentProfile.priceLevelId,
                        id: alignment.id,
                        length: alignmentLength / 1000,
                    },
                });
                if (alignmentData) {
                    priceSegments.push({
                        type: 'alushell',
                        baseValue:
                            price(alignmentAlushellPrice.price_length, alignmentLength / 1000),
                        value:
                            price(alignmentAlushellPrice.price_length, alignmentLength / 1000),
                        valueType: 'value',
                        data: {
                            profileId: alignment.profileId,
                            priceLevelId: alignmentProfile.priceLevelId,
                            id: alignment.id,
                            length: alignmentLength / 1000,
                        },
                    });
                }
            }
        } else {
            priceSegments.push({
                type: 'alignment',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {},
            });
            NoPriceCauses.push('no prices for alignment');
        }
        return priceSegments;
    }

    /**
     * Dopłata za stal
     * @param  {Number} price           Cena
     * @param  {Object} PriceElems      Cena elementów
     * @param  {Array} NoPriceCauses    Powody braku cen
     * @param  {Array} constrElems      Elementy konstrukcyjne
     * @param  {Object} steel           Stal
     * @param  {Object} system          System
     * @return {Number}                 Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'steel',
        data: {
            steel: 'conf.Steel',
            system: 'conf.System',
            customPrice: 'price.WindowLine',
        },
    })
    suppSteel(
        {  }: PriceElemsData,
        { steel, system, customPrice }: { steel; system; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let supp = 1;
        if (steel === 'Closed' && ~~system.steel_closed === 0) {
            let steelFactor = parseFloat(system.steel_factor);
            if (Common.isObject(customPrice) && customPrice[system.id]) {
                steelFactor = customPrice[system.id].getPrice('steel_factor');
            }
            supp = 1 + steelFactor / 100;
        }
        return [
            {
                type: 'steel',
                baseValue: supp,
                value: supp,
                valueType: 'percent',
                data: {},
            },
        ] as PriceSegment[];
    }

    @PriceFunc({
        shortName: 'casing',
        data: {
            casing: 'conf.Casing',
            prices: 'data.profilesPrices',
            system: 'conf.System',
            colors: 'conf.Colors',
        }
    })
    suppCasing( {PriceStack, PriceElems, NoPriceCauses, listPrice }: PriceElemsData,
        {
            casing, prices, system, colors
        }: {
            casing; prices; system; colors;
        }
    ): PriceSegment[] {

        const casingPrice = this.profilesPriceService.getProfilePrice(
            casing.id,
            'casing',
            system,
            colors.frame,
            prices,
        );
        let supp = 0;
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        if(casingPrice) {
            supp = parseFloat(getListPrice(casingPrice, 'price_construction', listPrice, listPriceEnableInMarket));
        }

        return [
            {
                type: 'casing',
                baseValue: !isNaN(supp) ? supp : 0,
                value: !isNaN(supp) ? supp : 0,
                valueType: 'value',
                data: {
                    casingId: casing?.id,
                    casingName: (casing?.name),
                    casingInnerWidth: casing?.innerWidth,
                    casingWallOverlap: casing?.wallOverlap
                },
            },
        ] as PriceSegment[];
    }
    /**
     * Dolicza dopłatę za niski próg i kopacza.
     * @param  {number} price       Cena wejsciowa
     * @param  {object} PriceElems  Wycena
     * @param  {number} width       Szerokosć konstrukcji
     * @param  {object} system      System
     * @return {number}             Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'lowThreshold',
        data: {
            drawData: 'conf.drawData',
            colors: 'conf.Colors',
            sashes: 'conf.Sashes',
            system: 'conf.System',
            prices: 'data.profilesPrices',
            pricesForLength: 'data.profilesPricesForLength',
            profiles: 'data.profiles',
            profileSets: 'data.profileSets',
            type: 'conf.type',
            frames: 'conf.Frames',
            customPrice: 'price.Profile',
        },
    })
    suppLowThreshold(
        { PriceStack, PriceElems, NoPriceCauses, listPrice }: PriceElemsData,
        {
            drawData,
            colors,
            sashes,
            system,
            prices,
            pricesForLength,
            profiles,
            profileSets,
            frames,
            type,
            customPrice,
        }: {
            drawData;
            colors;
            sashes;
            system;
            prices;
            pricesForLength;
            profiles;
            profileSets;
            frames: WindowActiveConfiguration['Frames'];
            type;
            customPrice: CustomPricesRecords;
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (!frames.some(f => f.lowThreshold)) {
            return [];
        }
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        const lowThresholdSashes = sashes.filter(
            sash =>
                sash.nearMullions.bottom === -1
                && ([
                    'DK',
                    'D',
                    'DS',
                    'DSC',
                    'DRA',
                    'DRP',
                    'OD',
                    'DOA',
                    'DOP',
                    'ODS',
                    'FD',
                    'FDO',
                    'DSH',
                    'ODSH',
                    'HS',
                ].indexOf(sash.type.type) > -1
                    || (this.config().IccConfig.Configurators.price.includeFixOverLowThreshold
                        && sash.type.type === 'F')
                    || (this.config().IccConfig.Configurators.sliding_door.lowThresholdAvailable && ['HS', 'PSK'].indexOf(sash.type.type) > -1))
        );
        const bottomSashesCount = lowThresholdSashes.length;
        if (Common.isObject(prices)) {
            let constructionPrice = null;
            let lowThresholdId = null
            frames.forEach(frame => {
                const frameData =
                    drawData && drawData.frame && drawData.frame.find(f => f.frameId === frame.id);
                if (type !== 'window') {
                    const sashesSegments = PriceStack.filter(
                        s => s.type === 'sashes' && s.value !== null && s.data && s.data.sashIds
                    );
                    const sashProfileSetMap = sashesSegments.reduce((map, seg) => {
                        seg.data.sashIds.forEach(id => {
                            map[id] = seg.data.profileSetId;
                        });
                        return map;
                    }, {});
                    let lowThresholdPriceTwo = 0;
                    let lowThresholdPriceSash = 0;
                    sashes
                        .filter(sash => sash.frameId === frame.id)
                        .forEach(sash => {
                            if (
                                sash.nearMullions.bottom === -1
                                && ([
                                    'DK',
                                    'D',
                                    'DS',
                                    'DSC',
                                    'DRA',
                                    'DRP',
                                    'OD',
                                    'DOA',
                                    'DOP',
                                    'ODS',
                                    'FD',
                                    'FDO',
                                    'DSH',
                                    'ODSH',
                                    'HS',
                                ].indexOf(sash.type.type) > -1
                                    || (this.config().IccConfig.Configurators.price.includeFixOverLowThreshold
                                        && sash.type.type === 'F')
                                    || (this.config().IccConfig.Configurators.sliding_door.lowThresholdAvailable && ['HS', 'PSK'].indexOf(sash.type.type) > -1))
                            ) {
                                const sashData = drawData.sash.find(o => o.sashId === sash.id);

                                if (sashData && frameData && sashData) {
                                    const profileSetForSash = this.profileSetsService.getProfileSet(
                                        sashProfileSetMap[sash.id],
                                        profileSets
                                    );

                                    const frameProfileId = sashData.parallel.profiles[0];
                                    const profile = this.getProfile(frameProfileId, profiles);
                                    const thresholdLength = Array.isArray(frameData.sides) && frameData.sides.filter(s => s.outerEdge.side === 'bottom' && !s.poly.alt).reduce((a, b) => a + Number(b.length), 0);
                                    const profilePrice = this.profilesPriceService.getThresholdPrice(
                                        frameProfileId,
                                        'threshold',
                                        system,
                                        prices,
                                        customPrice,
                                        pricesForLength,
                                        thresholdLength
                                    );
                                    const profileFromSetPrice = profileSetForSash
                                        ? this.profilesPriceService.getThresholdPrice(
                                              profileSetForSash.threshold,
                                              'threshold',
                                              system,
                                              prices,
                                              customPrice,
                                              pricesForLength,
                                              thresholdLength
                                          )
                                        : null;
                                    if (profilePrice) {
                                        let priceSash = parseFloat(getListPrice(profilePrice, 'price_sash', listPrice, listPriceEnableInMarket));
                                        let priceLength = parseFloat(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket));
                                        let priceTwoSashes = parseFloat(
                                            getListPrice(profilePrice, 'price_two_sashes', listPrice, listPriceEnableInMarket)
                                        );
                                        let priceConstruction = parseFloat(getListPrice(profilePrice, 'price_construction', listPrice, listPriceEnableInMarket));
                                        if (profileFromSetPrice && this.config().IccConfig.Configurators.price.profileFromSetPrice) {
                                            priceSash -= parseFloat(getListPrice(profileFromSetPrice, 'price_sash', listPrice, listPriceEnableInMarket));
                                            priceLength -= parseFloat(
                                                getListPrice(profileFromSetPrice, 'price_length', listPrice, listPriceEnableInMarket)
                                            );
                                            priceTwoSashes -= parseFloat(
                                                getListPrice(profileFromSetPrice, 'price_two_sashes', listPrice, listPriceEnableInMarket)
                                            );
                                            priceConstruction -= parseFloat(getListPrice(profileFromSetPrice, 'price_construction', listPrice, listPriceEnableInMarket));
                                        }

                                        lowThresholdPriceTwo =
                                            priceTwoSashes > lowThresholdPriceTwo
                                                ? priceTwoSashes
                                                : lowThresholdPriceTwo;
                                        lowThresholdPriceSash =
                                            priceSash > lowThresholdPriceSash
                                                ? priceSash
                                                : lowThresholdPriceSash;

                                        if (
                                            this.config().IccConfig.Configurators.price.lowThreshold
                                            === 'count'
                                        ) {
                                            priceSegments.push({
                                                type: 'lowThreshold',
                                                baseValue: price(priceSash),
                                                value: price(priceSash),
                                                valueType: 'value',
                                                data: {
                                                    profileId: frameProfileId,
                                                    sashId: sash.id,
                                                },
                                            });
                                        }

                                        if (
                                            frameData.sides[0]
                                            || (!this.config().IccConfig.Configurators.price
                                                .includeFixOverLowThreshold
                                                && sash.rWidth)
                                        ) {
                                            const profileLength = sash.rWidth / 1000;
                                            priceSegments.push({
                                                type: 'lowThreshold',
                                                baseValue: price(priceLength, profileLength),
                                                value: price(priceLength, profileLength),
                                                valueType: 'value',
                                                data: {
                                                    profileId: frameProfileId,
                                                    length: profileLength,
                                                },
                                            });
                                        }

                                        if (
                                            priceConstruction !== null
                                            && (Number(priceConstruction) > constructionPrice || constructionPrice === null)
                                        ) {
                                            constructionPrice = Number(priceConstruction);
                                            lowThresholdId = frameProfileId;
                                        }
                                    }
                                } else {
                                    priceSegments.push({
                                        type: 'lowThreshold',
                                        baseValue: null,
                                        value: null,
                                        valueType: 'value',
                                        data: {},
                                    });
                                    NoPriceCauses.push('no prices for frame');
                                }
                            }
                        });

                    if (this.config().IccConfig.Configurators.price.lowThreshold === 'count2') {
                        const lowThresholdPriceForTwoSashes =
                            price(lowThresholdPriceTwo, Math.floor(bottomSashesCount / 2))
                            + price(lowThresholdPriceSash * (bottomSashesCount % 2));
                        priceSegments.push({
                            type: 'lowThreshold',
                            baseValue: lowThresholdPriceForTwoSashes,
                            value: lowThresholdPriceForTwoSashes,
                            valueType: 'value',
                            data: {
                                profileId: frame.frame[0].profileId,
                                bottomSashesCount,
                            },
                        });
                    }
                } else {
                    const width = lowThresholdSashes.reduce((prev, sash) => prev + sash.rWidth, 0);
                    const profilesDimensions = frameData && frameData.sides;
                    frame.frame
                        .filter<any>(this.threshold.bind(this, profiles))
                        .forEach((frameProfile, index) => {
                            const profile = this.getProfile(frameProfile.profileId, profiles);
                            const thresholdLength = Array.isArray(frameData.sides) && frameData.sides.filter(s => s.outerEdge.side === 'bottom' && !s.poly.alt).reduce((a, b) => a + Number(b.length), 0);
                            const profilePrice = this.profilesPriceService.getThresholdPrice(
                                frameProfile.profileId,
                                'threshold',
                                system,
                                prices,
                                customPrice,
                                pricesForLength,
                                thresholdLength
                            );
                            if (profilePrice) {
                                if (
                                    this.config().IccConfig.Configurators.price.lowThreshold
                                    === 'count'
                                ) {
                                    priceSegments.push({
                                        type: 'lowThreshold',
                                        baseValue: price(
                                            profilePrice.price_sash,
                                            bottomSashesCount
                                        ),
                                        value: price(profilePrice.price_sash, bottomSashesCount),
                                        valueType: 'value',
                                        data: {
                                            profileId: frameProfile.profileId,
                                            bottomSashesCount,
                                        },
                                    });
                                } else if (
                                    this.config().IccConfig.Configurators.price.lowThreshold
                                    === 'count2'
                                ) {
                                    const lowThresholdPriceTwo =
                                        price(
                                            profilePrice.price_two_sashes,
                                            Math.floor(bottomSashesCount / 2)
                                        )
                                        + price(profilePrice.price_sash * (bottomSashesCount % 2));
                                    priceSegments.push({
                                        type: 'lowThreshold',
                                        baseValue: lowThresholdPriceTwo,
                                        value: lowThresholdPriceTwo,
                                        valueType: 'value',
                                        data: {
                                            profileId: frameProfile.profileId,
                                            bottomSashesCount,
                                        },
                                    });
                                }

                                if (
                                    profilesDimensions[index]
                                    || (!this.config().IccConfig.Configurators.price.includeFixOverLowThreshold
                                        && width)
                                ) {
                                    const priceLength = profilePrice.price_length;
                                    const profileLength =
                                        (this.config().IccConfig.Configurators.price.includeFixOverLowThreshold
                                            ? profilesDimensions[index].outerEdge.length
                                            : width) / 1000;
                                    priceSegments.push({
                                        type: 'lowThreshold',
                                        baseValue: price(priceLength, profileLength),
                                        value: price(priceLength, profileLength),
                                        valueType: 'value',
                                        data: {
                                            profileId: frameProfile.profileId,
                                            length: width / 1000,
                                        },
                                    });
                                }

                                if (
                                    profilePrice.price_construction !== null
                                    && (Number(profilePrice.price_construction) > constructionPrice || constructionPrice === null)
                                ) {
                                    constructionPrice = Number(profilePrice.price_construction);
                                    lowThresholdId = frameProfile.profileId;
                                }
                            }
                        });
                }
            });

            priceSegments.push({
                type: 'lowThreshold',
                baseValue: price(constructionPrice),
                value: price(constructionPrice),
                valueType: 'value',
                data: {
                    profileId: lowThresholdId
                },
            });
        } else {
            priceSegments.push({
                type: 'lowThreshold',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {},
            });
            NoPriceCauses.push('no prices for frame');
        }

        // cena całkowita
        return priceSegments;
    }

    /**
     * Dolicza doplatę za łączniki
     * @param  {number} price       Cena wejściowa
     * @param  {object} PriceElems  Wycena
     * @param  {object} conf        Konstrukcja
     * @param  {object} prices      Ceny slupkow
     * @param  {object} colors      Kolory
     * @return {number}             Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'couplings',
        data: {
            drawData: 'conf.drawData',
            prices: 'data.profilesPrices',
            profiles: 'data.profiles',
            colors: 'conf.Colors',
            couplings: 'conf.couplings',
            system: 'conf.System',
            reinforcements: 'data.reinforcements',
            alushells: 'data.alushells',
            hasAlushell: 'conf.HasAlushell',
            alushellPrices: 'data.profilesAlushellPrices',
            customPrice: 'price.Profile',
        },
    })
    suppCouplings(
        { listPrice }: PriceElemsData,
        {
            drawData,
            couplings,
            prices,
            profiles,
            colors,
            system,
            reinforcements,
            alushells,
            hasAlushell,
            alushellPrices,
            customPrice,
        }: {
            drawData: any;
            couplings: CouplingActive[];
            prices;
            profiles;
            colors;
            system: WindowActiveConfiguration['System'];
            reinforcements;
            alushells;
            hasAlushell: boolean;
            alushellPrices;
            customPrice;
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        if (couplings.length) {
            couplings.forEach((coupling, index) => {
                const couplingData = drawData.coupling.find(o => o.couplingId === coupling.id);
                const couplingProfile = profiles.find(o => o.id === coupling.profileId);
                const profilePrice = this.profilesPriceService.getProfilePrice(
                    coupling.profileId,
                    'coupling',
                    system,
                    colors.frame,
                    prices,
                    customPrice
                );
                const profileAlushellPrice = this.profilesPriceService.getProfileAlushellPrice(
                    coupling.profileId,
                    'coupling',
                    hasAlushell,
                    alushells,
                    colors.frame,
                    alushellPrices,
                    customPrice
                );
                const reinforcement = reinforcements
                    ? reinforcements.find(
                          o =>
                              coupling.reinforcement
                              && Number(o.id) === Number(coupling.reinforcement.id)
                      )
                    : null;

                let length = coupling.length / 1000;
                if (
                    couplingData
                    && this.config().IccConfig.Configurators.price.mullionsLength === 'real'
                ) {
                    const couplingLength = Number(couplingData.length) || 0;
                    length = couplingLength / 1000;
                }

                if (profilePrice) {
                    const pricePiece = parseFloat(getListPrice(profilePrice, 'price_piece', listPrice, listPriceEnableInMarket));
                    const priceLength = parseFloat(getListPrice(profilePrice, 'price_length', listPrice, listPriceEnableInMarket));

                    priceSegments.push({
                        type: 'couplings',
                        baseValue: price(pricePiece),
                        value: price(pricePiece),
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            priceLevelId: couplingProfile.priceLevelId,
                            length,
                        },
                    });
                    priceSegments.push({
                        type: 'couplings',
                        baseValue: price(priceLength, length),
                        value: price(priceLength, length),
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            priceLevelId: couplingProfile.priceLevelId,
                            length,
                        },
                    });
                    if (reinforcement) {
                        let reinforcementLength = length * 1000;
                        if (couplingProfile) {
                            reinforcementLength =
                                length * 1000
                                - couplingProfile.spaceReinforcement * 2
                                - reinforcement.length_correction * 2;
                        }
                        if (reinforcement.length_step) {
                            reinforcementLength =
                                Math.floor(reinforcementLength / reinforcement.length_step)
                                * reinforcement.length_step;
                        }
                        priceSegments.push({
                            type: 'couplings',
                            baseValue: price(getListPrice(reinforcement, 'price', listPrice, listPriceEnableInMarket), reinforcementLength / 1000),
                            value: price(getListPrice(reinforcement, 'price', listPrice, listPriceEnableInMarket), reinforcementLength / 1000),
                            valueType: 'value',
                            data: {
                                profileId: coupling.profileId,
                                couplingId: coupling.id,
                                priceLevelId: couplingProfile.priceLevelId,
                                length: reinforcementLength / 1000,
                            },
                        });
                    }
                }
                if (profileAlushellPrice && hasAlushell) {
                    const pricePiece = parseFloat(getListPrice(profileAlushellPrice, 'price_piece', listPrice, listPriceEnableInMarket));
                    const priceLength = parseFloat(getListPrice(profileAlushellPrice, 'price_length', listPrice, listPriceEnableInMarket));

                    priceSegments.push({
                        type: 'alushell',
                        baseValue: price(pricePiece),
                        value: price(pricePiece),
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            length,
                        },
                    });
                    priceSegments.push({
                        type: 'alushell',
                        baseValue: price(priceLength, length),
                        value: price(priceLength, length),
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            length,
                        },
                    });
                }
                if (!profilePrice && (!hasAlushell || !profileAlushellPrice)) {
                    priceSegments.push({
                        type: 'couplings',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {
                            profileId: coupling.profileId,
                            couplingId: coupling.id,
                            priceLevelId: couplingProfile.priceLevelId,
                            length,
                        },
                    });
                }
            });
        }

        return priceSegments;
    }

    /**
     * Dolicza doplate za listwę przyszybową
     * @param  {number} price         Cena wejściowa
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Eleenty bez wyceny
     * @param  {object} conf          Konfiguracja
     * @param  {object} lipping       Id listwy przyszybowej
     * @param  {object} prices        Ceny profili
     * @param  {object} profiles      Lista profili
     * @return {number}               Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'lipping',
        data: {
            lipping: 'conf.lipping',
            system: 'conf.System',
            lippingColor: 'conf.lippingColor',
            innerLippingColor: 'conf.innerLippingColor',
            prices: 'data.profilesPrices',
        },
    })
    suppLipping(
        { PriceStack, PriceElems, NoPriceCauses, listPrice }: PriceElemsData,
        {
            lipping,
            system,
            lippingColor,
            innerLippingColor,
            prices
        }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];

        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;
        const profilePrice = this.profilesPriceService.getLippingProfilePrice(
            lipping,
            system,
            {inner: innerLippingColor, outer: lippingColor},
            prices
        );
        if(profilePrice) {
            const priceConstruction = parseFloat(getListPrice(profilePrice, 'price_construction', listPrice, listPriceEnableInMarket));
            priceSegments.push({
                type: 'lipping',
                baseValue: price(priceConstruction || 0),
                value: price(priceConstruction || 0),
                valueType: 'value',
                data: {
                    profileId: lipping,
                },
            });
        }

        return priceSegments;
    }

    /**
     * Liczenie miesc laczenia slupkow w szkydle (typy laczenia: +, T)
     * @param  {object} sash skrzydlo w ktorym szukamy
     * @return {array}       lista wspolrzednych miejsc laczenia
     */
    private crossCounter(sash) {
        const counter = [];
        let element = '';
        for (let i = 0; i < sash.intMullions.length; i++) {
            for (let j = 0; j < sash.intMullions.length; j++) {
                if (sash.intMullions[i].direction === 'vertical') {
                    if (sash.intMullions[j].direction === 'horizontal') {
                        if (sash.intMullions[j].rx === sash.intMullions[i].rx) {
                            element = sash.intMullions[j].rx + ',' + sash.intMullions[j].ry;
                        } else if (sash.intMullions[j].ry === sash.intMullions[i].ry) {
                            element = sash.intMullions[i].rx + ',' + sash.intMullions[i].ry;
                        } else if (
                            sash.intMullions[j].rx + sash.intMullions[j].rWidth
                            === sash.intMullions[i].rx
                        ) {
                            element = sash.intMullions[i].rx + ',' + sash.intMullions[j].ry;
                        } else if (
                            sash.intMullions[i].ry + sash.intMullions[i].rHeight
                            === sash.intMullions[j].ry
                        ) {
                            element = sash.intMullions[i].rx + ',' + sash.intMullions[j].ry;
                        }
                        if (counter.indexOf(element) < 0) {
                            counter.push(element);
                        }
                    }
                }
            }
        }
        return counter;
    }

    /**
     * Generowanie pol dla slupkow
     * @param  {object} scanRect        obszar {x, y, szerokosc, wysokosc, kod rodzica}
     * @param  {object} sash            skrzydlo lub cala konstrukcja ze slupkami
     * @param  {number} parentWidth     szerokosc glownego slupka obszru nadrzednego
     * @param  {string} parentDirection kierunek glownego sklupka obszaru nadrzednego
     * @return {object|array}           lista pol dla sklopkow w kazdym mymiarze
     */
    private genDividersAreas(scanRect, sash, profiles, parentWidth?, parentDirection?) {
        // sortowanie słupków/poprzeczek wg szerokości malejąco
        const dividers = (sash.intMullions || sash)
            .map(mullion => {
                const profile = this.getProfile(mullion.profileId, profiles);
                return {
                    x: mullion.rx,
                    y: mullion.ry,
                    width: mullion.rWidth,
                    height: mullion.rHeight,
                    id: mullion.id,
                    profileId: profile ? profile.id : null,
                    profileWidth: profile ? profile.widthOut : null,
                };
            })
            .sort(core.sortByKey('profileWidth', null, true));

        if (dividers.length === 0) {
            return [];
        }
        if (typeof scanRect.code !== 'string') {
            scanRect.code = '';
        }

        const dividersAreas = {};
        let fullDividers = {};
        let fullDividersProfiles: any = {};
        let fullDividerDirection = {};
        let i = 0;
        let scanRegions = [];
        let parentCounter = ~~scanRect.code - 1;
        let steps;

        // szukanie slupka/poprzeczy na całą szerokość w zależności od grubości
        for (i = 0; i < dividers.length; i++) {
            if (dividers[i].width === scanRect.width) {
                if (
                    dividers[i].x >= scanRect.x
                    && dividers[i].width + dividers[i].x <= scanRect.x + scanRect.width
                    && dividers[i].y > scanRect.y
                    && dividers[i].height + dividers[i].y <= scanRect.y + scanRect.height
                ) {
                    fullDividerDirection[dividers[i].profileWidth] = 'horizontal';
                    fullDividersProfiles[dividers[i].profileWidth] =
                        dividers[i].profileId + '_' + dividers[i].id;
                    if (!Array.isArray(fullDividers[dividers[i].profileWidth])) {
                        fullDividers[dividers[i].profileWidth] = [];
                    }
                    fullDividers[dividers[i].profileWidth].push(i);
                }
            }
            if (dividers[i].height === scanRect.height) {
                if (
                    dividers[i].x > scanRect.x
                    && dividers[i].width + dividers[i].x <= scanRect.x + scanRect.width
                    && dividers[i].y >= scanRect.y
                    && dividers[i].height + dividers[i].y <= scanRect.y + scanRect.height
                ) {
                    fullDividerDirection[dividers[i].profileWidth] = 'vertical';
                    fullDividersProfiles[dividers[i].profileWidth] =
                        dividers[i].profileId + '_' + dividers[i].id;
                    if (!Array.isArray(fullDividers[dividers[i].profileWidth])) {
                        fullDividers[dividers[i].profileWidth] = [];
                    }
                    fullDividers[dividers[i].profileWidth].push(i);
                }
            }
        }

        // wybranie tylko tych najgrubszych słpków/poprzeczek
        const searchedKey = Math.max.apply(Math, Object.keys(fullDividerDirection).map(e => ~~e));
        fullDividers = fullDividers[searchedKey];
        fullDividerDirection = fullDividerDirection[searchedKey];
        fullDividersProfiles = fullDividersProfiles[searchedKey];

        // jeżeli nie ma już w obszarze słupków poprzeczek, przerwij poszukiwania
        if (!Array.isArray(fullDividers)) {
            return [];
        }

        // jeżeli najdłuższe słupki są pionowe, utwórz obszary pionowe
        if (fullDividerDirection === 'horizontal') {
            steps = [scanRect.y];
            for (i = 0; i < fullDividers.length; i++) {
                steps.push(dividers[fullDividers[i]].y);
            }
            steps.push(scanRect.height + scanRect.y);
            steps.sort((a, b) => a - b);

            scanRegions = [];
            for (i = 0; i < steps.length - 1; i++) {
                scanRegions.push({
                    x: scanRect.x,
                    y: steps[i],
                    width: scanRect.width,
                    height: steps[i + 1] - steps[i],
                    code:
                        parentDirection === 'vertical' && searchedKey === parentWidth
                            ? (++parentCounter).toString()
                            : scanRect.code + (i + 1).toString(),
                });
            }
            // jeżeli najdłuższe są poprzeczki poziome, utwórz obszary poziome
        } else {
            steps = [scanRect.x];
            for (i = 0; i < fullDividers.length; i++) {
                steps.push(dividers[fullDividers[i]].x);
            }
            steps.push(scanRect.width + scanRect.x);
            steps.sort((a, b) => a - b);

            scanRegions = [];
            for (i = 0; i < steps.length - 1; i++) {
                scanRegions.push({
                    x: steps[i],
                    y: scanRect.y,
                    height: scanRect.height,
                    width: steps[i + 1] - steps[i],
                    code:
                        parentDirection === 'horizontal' && searchedKey === parentWidth
                            ? (++parentCounter).toString()
                            : scanRect.code + (i + 1).toString(),
                });
            }
        }

        // utwórz obiekt z największą szerokościę zawierający listę obszarów
        if (!Array.isArray(dividersAreas[fullDividersProfiles])) {
            dividersAreas[fullDividersProfiles] = [];
        }

        for (i = 0; i < scanRegions.length; i++) {
            // dodaj najgrubsze poprzeczki do talicy
            if (scanRect.code !== scanRegions[i].code) {
                dividersAreas[fullDividersProfiles].push(scanRegions[i].code);
            }

            // poszukaj w każdym z obszarów mniejsze porzeczki/słupki
            const tmpDividersAreas = this.genDividersAreas(
                scanRegions[i],
                sash,
                profiles,
                searchedKey,
                fullDividerDirection
            );
            for (const l in tmpDividersAreas) {
                if (!Array.isArray(dividersAreas[l])) {
                    dividersAreas[l] = [];
                }
                for (let k = 0; k < tmpDividersAreas[l].length; k++) {
                    dividersAreas[l].push(tmpDividersAreas[l][k]);
                }
            }
        }
        return dividersAreas;
    }

    private getMainFrameProfile(frame: FrameProfile[]) {
        const profilesCount = Array.from(frame.reduce((prev, profile) => {
                if (!prev.has(profile.profileId)) {
                    prev.set(profile.profileId, 0);
                }
                prev.set(profile.profileId, prev.get(profile.profileId) + 1);
                return prev;
            }, new Map<number, number>()),
        );
        profilesCount.sort((a, b) => b[1] - a[1]);
        if (profilesCount.length > 0 && profilesCount[0].length > 0) {
            return profilesCount[0][0];
        }
        return null;
    }

    private noThreshold(profiles, frameProfile) {
        if (!frameProfile || !frameProfile.profileId) {
            return false;
        }
        const profile = this.getProfile(frameProfile.profileId, profiles);
        return profile.type !== 'threshold';
    }

    private threshold(profiles, frameProfile) {
        if (!frameProfile || !frameProfile.profileId) {
            return false;
        }
        const profile = this.getProfile(frameProfile.profileId, profiles);
        return profile.type === 'threshold';
    }

    private getProfile(id: number, profiles: Profile[]) {
        return profiles.filter(profile => profile.id === id)[0];
    }
}
