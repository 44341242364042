
<div class="fitting" perfect>
    <mat-form-field>
        <mat-label>{{ 'WINDOW|Wybierz zestaw profili:' | translate }}</mat-label>
        <mat-select [ngModel]='selectedSetId' (ngModelChange)="changeSet($event)">
            <mat-option *ngFor="let set of resolve.profileSets" [value]="set.id">{{ set.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <ng-container *ngFor=" let type of profileTypes">
        <icc-select-box
            *ngIf="haveProfiles(type)"
            [title]="profileTypesTitle[type]"
            [description]="selectedSet[type].id ? selectedSet[type].name : ('INTERFACE|Brak' | translate)"
            [imageUrl]="type !== 'glazingBeadShape' && '/files/profile/' + (selectedSet[type].img || '')"
            [buttonText]="'INTERFACE|Zmień' | translate"
            (select)="changeProfile(type, selectedSet[type].id)"
        ></icc-select-box>
    </ng-container>
</div>
<button mat-raised-button color = "primary" (click)="closeModal()" >
    {{ 'INTERFACE|OK' | translate }}
</button>
