import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { SharedFacade, StepComponent, _ } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
    EventBusService,
    TranslateService,
    ConfigurationsService,
    APP_CONFIG,
    AppConfigFactory,
} from '@icc/common';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { ShutterFacade } from '../+state/shutter.facade';
import { SchemasService } from '@icc/legacy/configurator/steps/roller_shutter/schemas.service';

@Component({
    selector: 'icc-schema',
    templateUrl: './schema.component.html',
    styleUrls: ['./schema.component.scss'],
})
export class SchemaComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Schemat');
    static stepIcon = {
        ligature: 'table_chart',
    };

    public configurator = 'roller_shutter';
    public stepId = 'rollerschema';
    public title = _('CONFIGURATOR|Wybierz schemat');

    schemas: iccListItem[] = [];
    selectedSchemaId$ = this.shutterFacade.selectedSchemaId$;
    modalConfigurator = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private eventBusService: EventBusService,
        private rollerDimensionsService: RollerDimensionsService,
        private shutterFacade: ShutterFacade,
        private schemasService: SchemasService,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private sharedFacade: SharedFacade
    ) {
        super();
        if (this.schemasService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            ),
            this.sharedFacade.getIsModalConfigurator$.subscribe(active => {
                this.modalConfigurator = active;
                this.setSchemas();
            })
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map((el) => el.unsubscribe());
    }

    private init() {
        this.setSchemas();
        this.rollerDimensionsService.updateDimensions();
    }

    changeSchema(item: iccListItem) {
        this.configurationsService.conf.Current.RollerShutter.schemaId = String(item.id);
        this.schemasService.setShuttersBySchema({ nextStep: true });
    }

    setSchemas() {
        this.schemas = [
            {
                id: '1',
                imageUrl: '/application/dist/web/img/layouts/default/r1.png',
                title: this.translateService.instant('ROLLERSHUTTER|Pojedyncza'),
            },
        ];
        if (
            this.configurationsService.conf.Current.RollerShutter.type.type !== 'Z' ||
            this.config().IccConfig.Configurators.roller_shutter.outsideRollerSchemas
        ) {
            if (
                this.config().IccConfig.Configurators.roller_shutter.doubleShutterLayout.includes('all') ||
                this.config().IccConfig.Configurators.roller_shutter.doubleShutterLayout.includes('common')
            ) {
                this.schemas.push({
                    id: '2a',
                    imageUrl: '/application/dist/web/img/layouts/default/r2.png',
                    title: this.translateService.instant(
                        'ROLLERSHUTTER|Podwójna ze wspólną prowadnicą'
                    ),
                });
            }
            if (
                this.config().IccConfig.Configurators.roller_shutter.doubleShutterLayout.includes('all') ||
                this.config().IccConfig.Configurators.roller_shutter.doubleShutterLayout.includes('separate')
            ) {
                this.schemas.push({
                    id: '2b',
                    imageUrl: '/application/dist/web/img/layouts/default/r6.png',
                    title: this.translateService.instant(
                        'ROLLERSHUTTER|Podwójna z osobnymi prowadnicami'
                    ),
                });
            }
            if (
                !this.modalConfigurator &&
                (this.config().IccConfig.Configurators.roller_shutter.doubleShutterLayout.includes('all') ||
                this.config().IccConfig.Configurators.roller_shutter.doubleShutterLayout.includes('separate2'))
            ) {
                this.schemas.push({
                    id: '2c',
                    imageUrl: '/application/dist/web/img/layouts/default/r3.png',
                    title: this.translateService.instant(
                        'ROLLERSHUTTER|Podwójna z osobnymi prowadnicami'
                    ),
                });
            }
            if (
                this.config().IccConfig.Configurators.roller_shutter.tripleShutterLayout.includes('all') ||
                this.config().IccConfig.Configurators.roller_shutter.tripleShutterLayout.includes('common')
            ) {
                this.schemas.push({
                    id: '3b',
                    imageUrl: '/application/dist/web/img/layouts/default/r5.png',
                    title: this.translateService.instant(
                        'ROLLERSHUTTER|Potrójna ze wspólną prowadnicą'
                    ),
                });
            }
            if (
                !this.modalConfigurator &&
                (this.config().IccConfig.Configurators.roller_shutter.tripleShutterLayout.includes('all') ||
                this.config().IccConfig.Configurators.roller_shutter.tripleShutterLayout.includes('separate'))
            ) {
                this.schemas.push({
                    id: '3',
                    imageUrl: '/application/dist/web/img/layouts/default/r4.png',
                    title: this.translateService.instant(
                        'ROLLERSHUTTER|Potrójna z osobnymi prowadnicami'
                    ),
                });
            }
            if (
                this.config().IccConfig.Configurators.roller_shutter.tripleShutterLayout.includes('all') ||
                this.config().IccConfig.Configurators.roller_shutter.tripleShutterLayout.includes('separate2')
            ) {
                this.schemas.push({
                    id: '3a',
                    imageUrl: '/application/dist/web/img/layouts/default/r7.png',
                    title: this.translateService.instant(
                        'ROLLERSHUTTER|Potrójna z osobnymi prowadnicami'
                    ),
                });
            }
        }
    }
}
