import { IccColor, IccConstructColor } from '@icc/common/data-types';
import { CoupledWindowConfigurationLegacy1 } from '../../legacy/CoupledWindowConfigurationLegacy1';
import { core } from '@icc/common/helpers';
import { ICoupledWindowConfiguration } from '../../CoupledWindowConfiguration';
import { changesInVersion15 } from '../window/to-version-15';
import { CouplingActive, CouplingLegacy, SideProfileActive, SideProfileLegacy } from '@icc/window/b2b';
import { changesInVersion19, changesInVersion18, changesInVersion17, changesInVersion16 } from '../window';

import { WindowConfigurationLegacy15 } from '../../legacy/WindowConfigurationLegacy15';
import { WindowConfigurationLegacy16 } from '../../legacy/WindowConfigurationLegacy16';
import { WindowConfigurationLegacy17 } from '../../legacy/WindowConfigurationLegacy17';
import { WindowConfigurationLegacy18 } from '../../legacy/WindowConfigurationLegacy18';

export function changesInVersion2(
    oldConfiguration: CoupledWindowConfigurationLegacy1,
    dataRequiredToUpdate: {
        langCode: string;
        /**
         * Kolory
         */
        colors: Record<IccColor['id'], IccColor>;
        /**
         * Kolor konstrukcji
         */
        windowColorsAll?: IccConstructColor[] | undefined;
    }
): ICoupledWindowConfiguration {
    const configuration: ICoupledWindowConfiguration = {
        ...core.copy(oldConfiguration),
        // jest to używane wyłącznie do wyświetlania w konfiguratorze i jest przeliczane na bieżąco,
        // więc nie ma potrzeby przeliczania tego na konkretną walutę
        priceAfterDiscountsInCurrency: oldConfiguration.priceAfterDiscounts,
        priceBeforePromotions: oldConfiguration.price,
        priceGrossBeforePromotions: oldConfiguration.priceGross,
        priceSegmentsBeforePromotions: oldConfiguration.priceSegments,
        pricePartsBeforePromotions: oldConfiguration.priceParts,
        windows: oldConfiguration.windows.map((window) => ({
            ...window,
            details: changesInVersion19(changesInVersion18(changesInVersion17(changesInVersion16(changesInVersion15(window.details, dataRequiredToUpdate) as WindowConfigurationLegacy15) as WindowConfigurationLegacy16) as WindowConfigurationLegacy17) as WindowConfigurationLegacy18)
        })
        ),
        couplings: oldConfiguration.couplings.map((coupling) => mapCoupling(coupling, dataRequiredToUpdate)),
        sideProfiles: oldConfiguration.sideProfiles.map((sideProfile) => mapSideProfiles(sideProfile, dataRequiredToUpdate)),
        listPrice: 0,
        listPriceNoMargin: 0,
        listPriceAfterDiscountsInCurrency: 0,
        listPriceBeforePromotions: 0,
        listPriceGross: 0,
        listPriceGrossAfterDiscounts: 0,
        listPriceGrossBeforePromotions: 0,
        listPriceSegments: [],
        listPriceSegmentsNoMargin: [],
        listPriceSegmentsBeforePromotions: [],
        listPriceParts: [],
        listPricePartsNoMargin: [],
        listPricePartsBeforePromotions: []
    };

    return configuration;
}


function mapCoupling(coupling: CouplingLegacy, dataRequiredToUpdate: {
    langCode: string;
    /**
     * Kolory
     */
    colors: Record<IccColor['id'], IccColor>;
    /**
     * Kolor konstrukcji
     */
    windowColorsAll?: IccConstructColor[] | undefined;
}): CouplingActive {
    return {
        ...coupling,
        color: coupling.color && {
            outer: dataRequiredToUpdate.windowColorsAll.find(el => +el.id === +coupling.color.outer?.id),
            inner: dataRequiredToUpdate.windowColorsAll.find(el => +el.id === +coupling.color.inner?.id),
            core: coupling.color.core?.id ? dataRequiredToUpdate.colors[coupling.color.core?.id] : null,
            alushell: coupling.color.alushell?.id ? dataRequiredToUpdate.colors[coupling.color.alushell?.id] : null,
        },
        wood: coupling.wood?.id ? {
            id: coupling.wood.id,
            name: coupling.wood.name || '',
        } : null,
    };
}

function mapSideProfiles(
    sideProfile: SideProfileLegacy,
    dataRequiredToUpdate: {
        langCode: string;
        /**
         * Kolory
         */
        colors: Record<IccColor['id'], IccColor>;
        /**
         * Kolor konstrukcji
         */
        windowColorsAll?: IccConstructColor[] | undefined;
    }
): SideProfileActive {
    return {
        ...sideProfile,
        color: sideProfile.color && {
            outer: dataRequiredToUpdate.windowColorsAll.find(el => +el.id === +sideProfile.color.outer?.id),
            inner: dataRequiredToUpdate.windowColorsAll.find(el => +el.id === +sideProfile.color.inner?.id),
            core: sideProfile.color.core?.id ? dataRequiredToUpdate.colors[sideProfile.color.core?.id] : null,
            alushell: sideProfile.color.alushell?.id ? dataRequiredToUpdate.colors[sideProfile.color.alushell?.id] : null,
        },
        wood: sideProfile.wood?.id ? {
            id: sideProfile.wood.id,
            name: sideProfile.wood.name || '',
        } : null,
    };
}
