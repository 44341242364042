import { WindowConfigurationLegacy17 } from '../../legacy/WindowConfigurationLegacy17';
import { IWindowConfigurationLegacy18 } from '../../legacy/WindowConfigurationLegacy18';


// eslint-disable-next-line max-statements
export function changesInVersion18(
    oldConfiguration: WindowConfigurationLegacy17,

): IWindowConfigurationLegacy18 {
    const configuration: IWindowConfigurationLegacy18 = {
        ...oldConfiguration,
        lipping: null,
        valid: {
            ...oldConfiguration.valid,
            panelTrims: null,
        },
        dictionary: {
            ...oldConfiguration.dictionary,
            doorPanelTrims: {},
        }
    };
    return configuration;
}
