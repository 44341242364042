import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ModalService, SharedFacade, StepComponent, _ } from '@icc/configurator/shared';
import { DimensionsOptionsPageComponent } from '../dimensions-options-page/dimensions-options-page.component';
import { ShutterFacade } from '../+state/shutter.facade';
import { DrawService } from '@icc/common/configurators/draw.service';
import {
    core,
    ConfigurationsService,
    TranslateService,
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ConfiguratorsDataService,
} from '@icc/common';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { map, takeUntil } from 'rxjs/operators';
import { DrawResizeDirective } from 'libs/configurator/shared/src/lib/draw-resize/draw-resize.directive';
import { UnitConverterService } from '@icc/configurator/ui';
import { PriceService } from '@icc/price';
import { DrivesService } from '@icc/legacy/configurator/steps/roller_shutter/drives.service';
import { DimensionsInfoComponent } from '../dimensions-info/dimensions-info.component';
import { Subject } from 'rxjs';
import { unitMMFormatter } from '@icc/configurator/shared';
import { GrayPolystyreneInfoComponent } from '../gray-polystyrene-info/gray-polystyrene-info.component';
import { GrayPolystyreneService } from '@icc/legacy/configurator/steps/roller_shutter/gray-polystyrene.service';
import { GrayPolystyrene } from '@icc/common/configurations/parts/roller_shutter';


@Component({
    selector: 'icc-dimensions',
    templateUrl: './dimensions.component.html',
    styleUrls: ['./dimensions.component.scss'],
})
export class DimensionsComponent extends StepComponent implements OnInit {
    static stepName = _('DIMENSION|Rozmiar');
    static stepIcon = {
        ligature: 'settings_overscan',
    };

    public configurator = 'roller_shutter';
    public stepId = 'rollerdimensions';
    public title = _('DIMENSION|Rozmiar');

    public options = [
        {
            title: _('ROLLERSHUTTER|Zaawansowane'),
            component: DimensionsOptionsPageComponent,
            icon: {
                ligature: 'settings',
            },
        },
        {
            title: _('DIMENSION|Resetuj wymiary'),
            icon: {
                ligature : 'refresh',
            },
            show:() => this.showResetDimensionButon(),
            callback:() => {
               this.resetDimensionToWindow();
            },

        }
    ];
    @ViewChild('dimensionsForm') dimensionsForm;
    hasPrices$ = this.shutterFacade.hasPrices$;
    dimensionsInfo = this.config().IccConfig.Configurators.roller_shutter.dimensionsInfo;
    areStepsJoined = this.config().IccConfig.Configurators.roller_shutter.joinTypesAndSystemsStep;
    isInfoPageAvailable = this.config().IccConfig.Configurators.roller_shutter.showDimensionsInfo;
    showDimensionSizeRange = this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction;
    width$ = this.shutterFacade.width$;
    height$ = this.shutterFacade.fullHeight$;
    shutters$ = this.shutterFacade.shutters$;
    systemInfo$ = this.shutterFacade.selectedSystem$.pipe(map(system => system && system.dimensionsInfo));
    addBoxToHeight = false;
    letters = ['A', 'B', 'C'];
    mosquito$ = this.shutterFacade.mosquito$;
    grayPolystyrene$ = this.shutterFacade.grayPolystyrene$;
    concealedStrip$ = this.shutterFacade.concealedStrip$;
    insideConcealedStrip$ = this.shutterFacade.insideConcealedStrip$;

    concealedStripAvailable = this.configurationsService.conf.Current.RollerShutter.system.outside_concealed_strip;
    insideConcealedStripAvailable = this.configurationsService.conf.Current.RollerShutter.system.inside_concealed_strip;

    dimensionUnit = this.unitConverterService.getUnit();
    modalConfigurator = false;
    shutterSizeRangeFull: {minWidthMM: string, maxWidthMM: string, minHeightMM: string, maxHeightMM: string, minWidth: number, maxWidth: number, minHeight: number, maxHeight: number }[] = this.getSizeRange();
    shutterSizeRange: {width: string | null, height: string | null}[] = this.setShutterSizeRange();
    mosquitoSizeRange: {minWidth: string, maxWidth: string, minHeight: string, maxHeight: string } = this.getMosquitoSizeRestriction();
    hasGrayPolystyrene = false;
    grayPolystyrene: GrayPolystyrene | null = null;

    private unsubscribe$ = new Subject<void>();

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private shutterFacade: ShutterFacade,
        public drawService: DrawService,
        private rollerDimensionsService: RollerDimensionsService,
        public configurationsService: ConfigurationsService<'roller_shutter'>,
        private translateService: TranslateService,
        private eventBusService: EventBusService,
        private unitConverterService: UnitConverterService,
        private priceService: PriceService,
        private drivesService: DrivesService,
        private modalService: ModalService,
        private sharedFacade: SharedFacade,
        private configuratorsDataService: ConfiguratorsDataService,
        private grayPolystyreneService: GrayPolystyreneService,
    ) {
        super();
        this.sharedFacade.getIsModalConfigurator$.pipe(takeUntil(this.unsubscribe$)).subscribe(active => {
            this.modalConfigurator = active;
        });
        this.grayPolystyrene$.pipe(takeUntil(this.unsubscribe$)).subscribe(grayPolystyrene => {
            this.grayPolystyrene = grayPolystyrene;
        });
    }

    ngOnInit(conf = this.configurationsService.conf.Current) {
        this.addBoxToHeight = conf.RollerShutter?.type?.measureType === 'with_box';
        this.hasGrayPolystyrene = this.grayPolystyreneService.availableGrayPolystyrene(conf);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * Przełączanie wspólnej prowadnicy
     * @param {object} shutter Roleta
     * @param {object} idx     Index
     */


    updateDimensions(isHeightChanged, changedInputIndex) {
        this.rollerDimensionsService.updateDimensions(isHeightChanged, changedInputIndex, undefined, false);
        this.rollerDimensionsService.loadBoxHeights();
        this.drivesService.setDrive(this.configurationsService.conf.Current.RollerShutter.drive);
        this.priceService.count();
        this.shutterSizeRangeFull = this.getSizeRange();
        this.shutterSizeRange = this.setShutterSizeRange();
        this.mosquitoSizeRange = this.getMosquitoSizeRestriction();
        this.updateValueAndValidityForm()
        this.rollerDimensionsService.updateDimensions();
    }

    updateValueAndValidityForm() {
        Object.keys(this.dimensionsForm.controls).forEach(key => {
            this.dimensionsForm.controls[key].markAsTouched();
            this.dimensionsForm.controls[key].updateValueAndValidity()
          });
    }

    toggleMosquito(value: boolean, index: number) {
        this.rollerDimensionsService.toggleMosquito(value, index);
    }

    toggleGrayPolystyrene({checked}: {checked: boolean}) {
        this.grayPolystyreneService.toggleGrayPolystyrene(checked);
    }

    switchDrawingSide(side: 'inner' | 'outer') {
        this.drawService.init();
    }

    openDimensionsInfoPage() {
        this.modalService
        .open({
            pageComponent: DimensionsInfoComponent,
            resolve: {},
        })
    }

    resetDimensionToWindow() {
        this.rollerDimensionsService.resetDimensionToWindowSizeWithoutRatio();
    }

    showResetDimensionButon() {
        return this.modalConfigurator && this.rollerDimensionsService.showResetDimensionButon();
    }

    setShutterSizeRange() {
        const conf = this.configurationsService.conf.Current
        const shutterSizeRange = this.rollerDimensionsService.getSizeRange(false);
        let boxHeight = conf.RollerShutter.realRollerHeight;
        const boxWidth = conf.RollerShutter.realBoxWidth;
        if (conf.RollerShutter.type.measureType === 'with_box') {
            boxHeight += conf.RollerShutter.realBoxHeight;
        }
        const result = [];
        conf.RollerShutter.shutters.forEach((shutter, index) => {
           result[index] = {width: null, height: null};
            if(boxHeight < this.shutterSizeRangeFull[index].minHeight) {
               result[index].height = this.translateService.instant("ROLLERSHUTTER|Minimalna wysokość to") + " " + (this.formatUnit(this.shutterSizeRangeFull[index].minHeight));
            } else if(boxHeight > this.shutterSizeRangeFull[index].maxHeight) {
               result[index].height = this.translateService.instant("ROLLERSHUTTER|Maksymalna wysokość to") + " " + (this.formatUnit(this.shutterSizeRangeFull[index].maxHeight));
            } else if(shutterSizeRange[index].minHeight !== -1) {
               result[index].height = this.translateService.instant("ROLLERSHUTTER|Przy szerokości {width} wysokość powinna wynosić", { width: this.formatUnit(boxWidth) })
                    + " "
                    + (this.formatUnit(shutterSizeRange[index].minHeight)) + ' - ' + (this.formatUnit(shutterSizeRange[index].maxHeight))
            }

            if(boxWidth < this.shutterSizeRangeFull[index].minWidth) {
               result[index].width = this.translateService.instant("ROLLERSHUTTER|Minimalna szerokość to") + " " + (this.formatUnit(this.shutterSizeRangeFull[index].minWidth));
            } else if(boxWidth > this.shutterSizeRangeFull[index].maxWidth) {
               result[index].width = this.translateService.instant("ROLLERSHUTTER|Maksymalna szerokość to ") + " " + (this.formatUnit(this.shutterSizeRangeFull[index].maxWidth));
            } else if(shutterSizeRange[index].maxWidth !== -1) {
               result[index].width = this.translateService.instant("ROLLERSHUTTER|Przy wysokości {height} szerokość powinna wynosić", { height: this.formatUnit(boxHeight) })
                    + " "
                    + (this.formatUnit(shutterSizeRange[index].minWidth)) + ' - ' + (this.formatUnit(shutterSizeRange[index].maxWidth))
            }
        });

        return result;

    }

    getSizeRange () {
        const shutterSizeRangeFull = this.rollerDimensionsService.getSizeRange();
        return shutterSizeRangeFull.map(sizeRange => (
            {...sizeRange,
                maxWidthMM: this.formatUnit(sizeRange.maxWidth),
                minWidthMM: this.formatUnit(sizeRange.minWidth),
                maxHeightMM: this.formatUnit(sizeRange.maxHeight),
                minHeightMM: this.formatUnit(sizeRange.minHeight),
            }));
    }

    getMosquitoSizeRestriction() {
        const mosquitoSizeRestriction = this.rollerDimensionsService.getMosquitoSizeRestriction();
        return {
            minWidth: this.formatUnit(mosquitoSizeRestriction.minWidth),
            maxWidth: this.formatUnit(mosquitoSizeRestriction.maxWidth),
            minHeight: this.formatUnit(mosquitoSizeRestriction.minHeight),
            maxHeight: this.formatUnit(mosquitoSizeRestriction.maxHeight),
        }
    }

    private formatUnit(value: number) {
        return unitMMFormatter(value, this.config().IccConfig, 1, 0, this.dimensionUnit.unit);
    }

    showGrayPolystyreneInfo() {
        if (this.grayPolystyreneService.availableGrayPolystyrene()) {
            const grayPolystyrene = this.grayPolystyreneService.grayPolystyrene.find(
                (gP) =>
                    gP.windowLinesIds.map(Number).includes(
                        +this.configurationsService.conf.Current.RollerShutter.type.id
                    )
            );
            this.modalService
                .open({
                    pageComponent: GrayPolystyreneInfoComponent,
                    resolve: {
                        data: () => (this.grayPolystyrene || grayPolystyrene)?.description,
                    },
                });
        }
    }


    changeConcealedStrip(concealedStrip: boolean) {
        this.configurationsService.conf.Current.RollerShutter.concealedStrip = concealedStrip;
        this.eventBusService.post({
            key: 'montageOptionsChanged',
            value: null
        });
    }

    changeInsideConcealedStrip(insideConcealedStrip: boolean) {
        this.configurationsService.conf.Current.RollerShutter.insideConcealedStrip = insideConcealedStrip;
        this.eventBusService.post({
            key: 'montageOptionsChanged',
            value: null
        });
    }
}
