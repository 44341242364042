import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { AwningSystem, Fabric, Valance, Drive, Mounting } from './parts/awning';
import { SimpleAccessory, ColorLegacy } from './parts/common';

import { AwningActiveConfiguration } from './AwningActiveConfiguration';

export class AwningConfiguration implements Configuration {
    $version = 5;
    type: 'awning' = 'awning';
    name = '';
    price: number | null = null;
    listPrice: number | null = null;
    priceNoMargin: number | null = null;
    listPriceNoMargin: number | null = null;
    priceAfterDiscountsInCurrency: number | null = null;
    listPriceAfterDiscountsInCurrency: number | null = null;
    priceBeforePromotions: number | null = null;
    listPriceBeforePromotions: number | null = null;
    priceGross: number | null = null;
    listPriceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    listPriceGrossAfterDiscounts: number | null = null;
    priceGrossBeforePromotions: number | null = null;
    listPriceGrossBeforePromotions: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    listPriceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    listPriceSegmentsNoMargin: PriceSegment[] = [];
    priceSegmentsBeforePromotions: PriceSegment[] = [];
    listPriceSegmentsBeforePromotions: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    listPriceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    listPricePartsNoMargin: PricePart[] = [];
    pricePartsBeforePromotions: PricePart[] = [];
    listPricePartsBeforePromotions: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    system: AwningSystem;
    outreach: number;
    width: number;
    color: ColorLegacy;
    fabric: Fabric;
    valance: Valance;
    drive: Drive;
    mounting: Mounting;
    accessories: SimpleAccessory[];

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (AwningConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (AwningActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.listPrice = configuration.ListPrice;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.listPriceNoMargin = configuration.ListPriceNoMargin;
                this.priceAfterDiscountsInCurrency = configuration.PriceAfterDiscountsInCurrency || configuration.Price;
                this.listPriceAfterDiscountsInCurrency = configuration.PriceAfterDiscountsInCurrency || configuration.ListPrice;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.listPriceGross = configuration.PriceGross || configuration.ListPrice;
                this.priceGrossAfterDiscounts = configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.listPriceGrossAfterDiscounts = configuration.PriceGrossAfterDiscounts || configuration.ListPrice;
                this.priceBeforePromotions = configuration.PriceBeforePromotions;
                this.listPriceBeforePromotions = configuration.ListPriceBeforePromotions;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.listPriceSegments = configuration.ListPriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.listPriceSegmentsNoMargin = configuration.ListPriceSegmentsNoMargin;
                this.priceSegmentsBeforePromotions = configuration.PriceSegmentsBeforePromotions;
                this.listPriceSegmentsBeforePromotions = configuration.ListPriceSegmentsBeforePromotions;
                this.priceParts = configuration.PriceParts;
                this.listPriceParts = configuration.ListPriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.listPricePartsNoMargin = configuration.ListPricePartsNoMargin;
                this.pricePartsBeforePromotions = configuration.PricePartsBeforePromotions;
                this.listPricePartsBeforePromotions = configuration.ListPricePartsBeforePromotions;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.attributes = configuration.attributes;

                this.system = {
                    id: Number(configuration.System.id),
                    name: configuration.System.name,
                };
                this.outreach = Number(configuration.Outreach);
                this.width = Number(configuration.Width);
                this.color = {
                    id: Number(configuration.Colour.id),
                    name: configuration.Colour.name,
                    isDefault: false,
                };
                this.fabric = {
                    id: Number(configuration.Fabric.id),
                    name: configuration.Fabric.name,
                    style: {
                        id: Number(configuration.FabricStyle.id),
                        name: configuration.FabricStyle.title,
                    },
                };
                this.valance = {
                    id: Number(configuration.Valance.id),
                    name: configuration.Valance.name,
                    inscription: configuration.ValanceInscription,
                    rollUp: Boolean(configuration.RollUpValance),
                };
                this.drive = {
                    type: configuration.DriveType,
                    side: configuration.DriveSide === 'left' ? 'L' : 'R',
                    id:
                        configuration.DriveType === 'motor'
                            ? Number(configuration.Motor.id)
                            : Number(configuration.Crank.id),
                    name:
                        configuration.DriveType === 'motor'
                            ? configuration.Motor.name
                            : configuration.Crank.name,
                    options: configuration.MotorOptions.map(option => ({
                        id: Number(option.id),
                        name: option.name,
                    })),
                };
                this.mounting = {
                    id: Number(configuration.Mounting.id),
                    name: configuration.Mounting.name,
                    quantity: Number(configuration.MountingQuantity),
                };
                this.accessories = configuration.Addons.map<SimpleAccessory>(addon => ({
                    id: Number(addon.id),
                    name: addon.name,
                    manufacturerCode: addon.manufacturer_code,
                    priceSource: addon.price_model,
                    type: 'awningAccessory',
                }));
            }
        }
    }

    public static is(configuration): configuration is AwningConfiguration {
        return configuration instanceof AwningConfiguration || configuration.$version;
    }
}
