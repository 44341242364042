<icc-list
        [searchable]="true"
        [items]="doorPanelTrims"
        [itemTemplate]="itemTrim"
        (select)="select($event)"
        [selected]="selectedTrim"
        [vertical]="true"
></icc-list>

<ng-template #itemTrim let-item="iccListItem">
    <div class="item-imgTitleDescription item-imgTitleDescription--vertical">
        <p class="item-imgTitleDescription-title">{{item.title}}</p>
        <div class="icc-list-item-img-container">
            <img class="item-imgTitleDescription-img" [src]="item.imageUrl" [alt]="item.title" defaultImg>
        </div>
        <div class="item-imgTitleDescription-price">
            <span *ngIf="item.price != null">
                {{ 'OFFER|Cena' | translate }}:<br>
                <s *ngIf="item.listPrice && item.listPrice !== item.price && listPriceEnable && listPriceEnableInMarket">
                    {{priceMarket(item.listPrice) | currency: currency}}
                </s>
                <span *ngIf="(listPriceEnable && listPriceEnableInMarket) || !listPriceEnable || !item.listPrice">
                    {{priceMarket(item.price) | currency: currency}}
                </span>
                <span *ngIf="listPriceEnable && !listPriceEnableInMarket && item.listPrice">
                    {{priceMarket(item.listPrice) | currency: currency}}
                </span>
              <br>
        </span>
        </div>
    </div>
</ng-template>
