import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    WINDOW_FEATURE_KEY,
    initialState as windowInitialState,
    windowReducer,
} from './+state/window.reducer';
import { WindowEffects } from './+state/window.effects';
import { WindowFacade } from './+state/window.facade';
import { SharedModule } from '@icc/configurator/shared';

import { DimensionsComponent } from './dimensions/dimensions.component';
import { SystemComponent } from './system/system.component';
import { ShapesComponent } from './shapes/shapes.component';
import { LayoutComponent } from './layout/layout.component';
import { ColorsComponent } from './colors/colors.component';
import { GlazingsComponent } from './glazings/glazings.component';
import { MuntinsComponent } from './muntins/muntins.component';
import { FittingComponent } from './fitting/fitting.component';
import { MuntinsLayoutsComponent } from './muntins-layouts/muntins-layouts.component';
import { MuntinsColorsComponent } from './muntins-colors/muntins-colors.component';
import { FillingsListPageComponent } from './fillings-list-page/fillings-list-page.component';
import { ChangeFittingPageComponent } from './change-fitting-page/change-fitting-page.component';
import { DimensionsModule } from '@icc/legacy/configurator/steps/window/dimensions/dimensions.module';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { ProfilesModule } from './profiles/profiles.module';
import { ProfileSetsPageComponent } from './profiles/profile-sets-page/profile-sets-page.component';
import { ProfilesListPageComponent } from './profiles/profiles-list-page/profiles-list-page.component';
import { ProfilesColorsPageComponent } from './profiles/profiles-colors-page/profiles-colors-page.component';
import { ProfilesShapesPageComponent } from './profiles/profiles-shapes-page/profiles-shapes-page.component';
import { LayoutModule } from '@icc/legacy/configurator/layout/layout.module';
import { ColorsModule as CommonColorsModule } from '@icc/common/colors/colors.module';
import { PriceModule } from '@icc/price/b2b';
import { DecoPanelOptionsPageComponent } from './deco-panel-options-page/deco-panel-options-page.component';
import { DoorModule } from '@icc/legacy/configurator/steps/door/door.module';
import { HandlesPageComponent } from './fittings/handles-page/handles-page.component';
import { HingesPageComponent } from './fittings/hinges-page/hinges-page.component';
import { HandlesModule } from '@icc/legacy/configurator/steps/window/handles/handles.module';
import { MuntinsModule } from '@icc/legacy/configurator/steps/window/muntins/muntins.module';
import { MuntinsLayoutEditPageComponent } from './muntins-layout-edit-page/muntins-layout-edit-page.component';
import { GlazingsModule } from '@icc/legacy/configurator/steps/window/glazings/glazings.module';
import { FillingColorsPageComponent } from './filling-colors-page/filling-colors-page.component';
import { GlazingUnitPageComponent } from './glazing-unit-page/glazing-unit-page.component';
import { GlazingBeadPageComponent } from './glazing-bead-page/glazing-bead-page.component';
import { GlazingSpacerPageComponent } from './glazing-spacer-page/glazing-spacer-page.component';
import { SealColorsPageComponent } from './seal-colors-page/seal-colors-page.component';
import { SiliconeColorsPageComponent } from './silicone-colors-page/silicone-colors-page.component';
import { WarmEdgesService } from '@icc/legacy/configurator/steps/window/glazings/warm-edges.service';
import { ColorsPageComponent } from './colors-page/colors-page.component';
import { WoodsPageComponent } from './woods-page/woods-page.component';
import { SashLayoutPageComponent } from './sash-layout-page/sash-layout-page.component';
import { SashTypesPageComponent } from './sash-types-page/sash-types-page.component';
import { MountingMethodPageComponent } from './mounting-method-page/mounting-method-page.component';
import { SystemsModule } from '@icc/legacy/configurator/steps/window/systems/systems.module';
import { ColorsModule } from '@icc/legacy/configurator/steps/window/colors/colors.module';
import { DependenciesModule } from '@icc/legacy/dependencies/dependencies.module';
import { SashesModule } from '@icc/legacy/configurator/steps/window/sashes/sashes.module';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import { GlazingLayoutPageComponent } from './glazing-layout-page/glazing-layout-page.component';
import { EditHandlesPageComponent } from './edit-handles-page/edit-handles-page.component';
import { AccessoriesModule } from '@icc/legacy/configurator/steps/window/accessories/accessories.module';
import { AccessoriesLocationPageComponent } from './accessories-location-page/accessories-location-page.component';
import { HandlesOptionsPageComponent } from './handles-options-page/handles-options-page.component';
import { HingesOptionsPageComponent } from './hinges-options-page/hinges-options-page.component';
import { SystemInfoComponent } from './system-info/system-info.component';
import { PanelGlazingsListPageComponent } from './panel-glazings-list-page/panel-glazings-list-page.component';
import { ConstructionComponent } from './construction/construction.component';
import { GlassTypeVariantsPageComponent } from './glass-type-variants-page/glass-type-variants-page.component';
import { FillingsFiltersPageComponent } from './fillings-filters-page/fillings-filters-page.component';
import { FillingOptionsPageComponent } from './filling-options-page/filling-options-page.component';
import { ProfileOptionsPageComponent } from './profiles/profile-options-page/profile-options-page.component';
import { ReinforcementsModule } from './reinforcements/reinforcements.module';
import { ReinforcementsListPageComponent } from './reinforcements/reinforcements-list-page/reinforcements-list-page.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ConstructionParametersComponent } from './construction-parameters/construction-parameters.component';
import { MeasurementsPageComponent } from './measurements-page/measurements-page.component';
import { DimensionsAdditionalDataPageComponent } from './dimensions-additional-data-page/dimensions-additional-data-page.component';
import { MountingClearanceComponent } from './mounting-clearance/mounting-clearance.component';
import { MuntinsTypesModalComponent } from './muntins-types-modal/muntins-types-modal.component';
import { SystemFiltersComponent } from './system-filters/system-filters.component';
import { SystemPacketsComponent } from './system-packets/system-packets.component';
import { ThresholdsColorsComponent } from './profiles/thresholds-colors-page/thresholds-colors.component';
import { FillingsFacade } from './glazings/fillings.facade';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';
import { PanelOptionsFacade } from './deco-panel-options-page/panel-options.facade';
import { MosquitoComponent } from './mosquito/mosquito.component';
import { SashMosquitoComponent } from './sash-mosquito/sash-mosquito.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { MuntinsInfoComponent } from './muntins-info-component/muntins-info.component';
import { NewColorsService } from './colors/new-colors.service';
import { AlushellService } from './construction/alushell.service';
import { GlassTypesPageComponent } from './glass-types-page/glass-types-page.component';
import { PanelTrimsListPageComponent } from './panel-trims-list-page/panel-trims-list-page.component';

@NgModule({
    declarations: [
        DimensionsComponent,
        SystemComponent,
        ShapesComponent,
        LayoutComponent,
        ColorsComponent,
        GlazingsComponent,
        MuntinsComponent,
        FittingComponent,
        MuntinsLayoutsComponent,
        MuntinsColorsComponent,
        FillingsListPageComponent,
        ChangeFittingPageComponent,
        ProfileSetsPageComponent,
        ProfilesListPageComponent,
        ProfilesColorsPageComponent,
        ProfilesShapesPageComponent,
        DecoPanelOptionsPageComponent,
        HandlesPageComponent,
        HingesPageComponent,
        MuntinsLayoutEditPageComponent,
        FillingColorsPageComponent,
        GlazingUnitPageComponent,
        GlazingBeadPageComponent,
        GlazingSpacerPageComponent,
        SealColorsPageComponent,
        SiliconeColorsPageComponent,
        ColorsPageComponent,
        WoodsPageComponent,
        SashLayoutPageComponent,
        SashTypesPageComponent,
        MountingMethodPageComponent,
        GlazingLayoutPageComponent,
        EditHandlesPageComponent,
        AccessoriesLocationPageComponent,
        HandlesOptionsPageComponent,
        HingesOptionsPageComponent,
        SystemInfoComponent,
        ProfileInfoComponent,
        PanelGlazingsListPageComponent,
        PanelTrimsListPageComponent,
        ConstructionComponent,
        WoodsPageComponent,
        GlassTypeVariantsPageComponent,
        GlassTypesPageComponent,
        FillingsFiltersPageComponent,
        FillingOptionsPageComponent,
        ProfileOptionsPageComponent,
        ReinforcementsListPageComponent,
        ProductDetailsComponent,
        ConstructionParametersComponent,
        MeasurementsPageComponent,
        DimensionsAdditionalDataPageComponent,
        MountingClearanceComponent,
        ProductDetailsComponent,
        ConstructionParametersComponent,
        MuntinsTypesModalComponent,
        SystemFiltersComponent,
        SystemPacketsComponent,
        ThresholdsColorsComponent,
        MosquitoComponent,
        SashMosquitoComponent,
        MuntinsInfoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorPagesModule,
        StoreModule.forFeature(WINDOW_FEATURE_KEY, windowReducer, {
            initialState: windowInitialState,
        }),
        EffectsModule.forFeature([WindowEffects]),

        DependenciesModule,
        DimensionsModule,
        ProfilesModule,
        LayoutModule,
        CommonColorsModule,
        PriceModule,
        DoorModule,
        HandlesModule,
        MuntinsModule,
        GlazingsModule,
        SystemsModule,
        ColorsModule,
        SashesModule,
        AccessoriesModule,
        ReinforcementsModule,
    ],
    providers: [WindowFacade, NewColorsService, AlushellService, ParametersService, FillingsFacade, PanelOptionsFacade],
    exports: [
        PanelGlazingsListPageComponent,
        GlassTypeVariantsPageComponent,
        GlassTypesPageComponent,
        FillingsFiltersPageComponent,
        FillingOptionsPageComponent,
        ProfileOptionsPageComponent,
        MeasurementsPageComponent,
        DimensionsAdditionalDataPageComponent,
        MountingClearanceComponent,
        MuntinsTypesModalComponent,
        SystemFiltersComponent,
        SystemPacketsComponent,
        ThresholdsColorsComponent,
        MosquitoComponent,
        SashMosquitoComponent,
    ],
    entryComponents: [
        DimensionsComponent,
        SystemComponent,
        ShapesComponent,
        LayoutComponent,
        ColorsComponent,
        GlazingsComponent,
        MuntinsComponent,
        FittingComponent,
        MuntinsLayoutsComponent,
        MuntinsColorsComponent,
        FillingsListPageComponent,
        ChangeFittingPageComponent,
        ProfileSetsPageComponent,
        ProfilesListPageComponent,
        ProfilesColorsPageComponent,
        ProfilesShapesPageComponent,
        DecoPanelOptionsPageComponent,
        HandlesPageComponent,
        HingesPageComponent,
        MuntinsLayoutEditPageComponent,
        FillingColorsPageComponent,
        GlazingUnitPageComponent,
        GlazingBeadPageComponent,
        GlazingSpacerPageComponent,
        SealColorsPageComponent,
        SiliconeColorsPageComponent,
        SashLayoutPageComponent,
        SashTypesPageComponent,
        MountingMethodPageComponent,
        GlazingLayoutPageComponent,
        EditHandlesPageComponent,
        ColorsPageComponent,
        AccessoriesLocationPageComponent,
        HandlesOptionsPageComponent,
        HingesOptionsPageComponent,
        SystemInfoComponent,
        ProfileInfoComponent,
        PanelGlazingsListPageComponent,
        PanelTrimsListPageComponent,
        ConstructionComponent,
        WoodsPageComponent,
        GlassTypeVariantsPageComponent,
        GlassTypesPageComponent,
        FillingsFiltersPageComponent,
        FillingOptionsPageComponent,
        ProfileOptionsPageComponent,
        ReinforcementsListPageComponent,
        ProductDetailsComponent,
        ConstructionParametersComponent,
        MeasurementsPageComponent,
        DimensionsAdditionalDataPageComponent,
        MountingClearanceComponent,
        ProductDetailsComponent,
        ConstructionParametersComponent,
        MuntinsTypesModalComponent,
        SystemFiltersComponent,
        SystemPacketsComponent,
        ThresholdsColorsComponent,
        MosquitoComponent,
        SashMosquitoComponent,
        MuntinsInfoComponent
    ],

})
export class ConfiguratorWindowModule {}
