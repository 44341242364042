<ng-container *ngIf="mode === 'list'">
    <icc-list
        [searchable]="true"
        [items]="sashActiveModels$ | async"
        [tabs]="modelCategories$ | async"
        [itemTemplate]="'itemImgTitle'"
        [selected]="selectedModelForSashActive$ | async"
        (showInfo)="showInfo($event)"
        (select)="selectModel($event)"
        itemImgHeight="high"
    ></icc-list>
</ng-container>
<ng-container *ngIf="mode === 'all'">
    <icc-select-box
        [title]="doorActiveModelBox?.title"
        [description]="doorActiveModelBox?.name"
        [imageUrl]="doorActiveModelBox?.img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [buttonText2]="((doorActiveModelBox.type === 'deco_panels' ) && 'INTERFACE|Opcje' | translate ) || ((doorActiveModelBox.type === 'door_panels') && 'INTERFACE|Szklenie' | translate)"
        [buttonText3]="(doorActiveModelBox.type === 'door_panels' && 'DOOR|Listwa' | translate )"
        [descriptionTemplate]="fillingBoxTemplate"
        [descriptionContext]="{box: doorActiveModelBox}"
        *ngIf="doorActiveModelBox?.show"
        [disabledButton1]="isActiveModelButton1Disabled()"
        [disabledButton2]="isActiveModelButton2Disabled()"
        [disabledButton3]="isPanelTrimsButtonDisabled()"
        (select)="changeOuterModel('doorActive')"
        (select2)="doorActiveModelBox?.type === 'door_panels' ? changePanelGlazing('doorActive') : openModalDecoPanelOptions('doorActive')"
        (select3)="changePanelTrim('doorActive')"
    ></icc-select-box>
    <icc-select-box
        [title]="doorActiveInnerModelBox?.title"
        [description]="doorActiveInnerModelBox?.name"
        [imageUrl]="doorActiveInnerModelBox?.img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [buttonText2]="(doorActiveInnerModelBox.type === 'deco_panels' || doorActiveInnerModelBox.type === 'door_panels') && 'DOOR|Listwa' | translate"
        [descriptionTemplate]="fillingBoxTemplate"
        [descriptionContext]="{box: doorActiveInnerModelBox}"
        [disabledButton1]="isDoorActiveInnerButton1Disabled()"
        [disabledButton2]="isPanelTrimsButtonDisabled('doorActiveInner')"
        *ngIf="doorActiveInnerModelBox.show"
        (select)="changeOuterModel('doorActiveInner')"
        (select2)="changePanelTrim('doorActiveInner')"
    ></icc-select-box>
    <icc-select-box
        [title]="doorPassiveModelBox?.title"
        [description]="doorPassiveModelBox?.name"
        [imageUrl]="doorPassiveModelBox?.img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [buttonText2]="((doorPassiveModelBox?.type === 'deco_panels') && 'INTERFACE|Opcje' | translate) || (doorPassiveModelBox?.type === 'door_panels' && 'INTERFACE|Szklenie' | translate)"
        [buttonText3]="(doorPassiveModelBox?.type === 'door_panels' && 'DOOR|Listwa' | translate )"
        [descriptionTemplate]="fillingBoxTemplate"
        [descriptionContext]="{box: doorPassiveModelBox}"
        *ngIf="doorPassiveModelBox?.show"
        [disabledButton2]="!doorPassiveModelBox?.showOptions"
        [disabledButton3]="isPanelTrimsButtonDisabled('doorPassive')"
        (select)="changeOuterModel('doorPassive')"
        (select2)="doorPassiveModelBox?.type === 'door_panels' ? changePanelGlazing('doorPassive') : openModalDecoPanelOptions('doorPassive')"
        (select3)="changePanelTrim('doorPassive')"
    ></icc-select-box>
    <icc-select-box
        [title]="doorPassiveInnerModelBox?.title"
        [description]="doorPassiveInnerModelBox?.name"
        [imageUrl]="doorPassiveInnerModelBox?.img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [buttonText2]="(doorPassiveInnerModelBox.type === 'deco_panels' || doorPassiveInnerModelBox.type === 'door_panels') && 'DOOR|Listwa' | translate"
        [descriptionTemplate]="fillingBoxTemplate"
        [descriptionContext]="{box: doorPassiveInnerModelBox}"
        *ngIf="doorPassiveInnerModelBox.show"
        [disabledButton1]="isDoorPassiveInnerButton1Disabled()"
        [disabledButton2]="isPanelTrimsButtonDisabled('doorPassiveInner')"
        (select)="changeOuterModel('doorPassiveInner')"
        (select2)="changePanelTrim('doorPassiveInner')"
    ></icc-select-box>
</ng-container>

<ng-template #fillingBoxTemplate let-box="box">
    <div class="decoPanels" *ngIf="box.type === 'deco_panels'">
        <p class="glassTypeDescription"
            *ngIf="box.oneGlazing">
            <span *ngIf="box.boxType !== 'doorActiveInner'"><b>{{ 'WINDOW|Rodzaj:' | translate }}</b>&nbsp;
                <span *ngIf="box.panelType == 'Inset'">{{'DOOR|Wypełnienie wsadowe' | translate }}</span>
                <span *ngIf="box.panelType == 'Outer'">{{'DOOR|Nakładka zewnętrzna' | translate }}</span>
                <span *ngIf="box.panelType == 'Double'">{{'DOOR|Nakładka obustronna' | translate }}</span>
                <span *ngIf="box.panelType == 'Inner'">{{'DOOR|Nakładka wewnętrzna' | translate }}</span>
                <br>
            </span>
            <span><b>{{ 'WINDOW|Model:' | translate }}</b>&nbsp;{{box.name}}<br></span>
            <span *ngIf="box.panelGlazingName"><b>{{ 'WINDOW|Szklenie:' | translate }}</b>&nbsp;{{box.panelGlazingName}}<br></span>
            <span *ngIf="box.panelTrimName"><b>{{ 'DOOR|Listwa zewnętrzna:' | translate }}</b>&nbsp;{{box.panelTrimName}}<br></span>
            <span *ngIf="box.innerPanelTrimName"><b>{{ 'DOOR|Listwa wewnętrzna:' | translate }}</b>&nbsp;{{box.innerPanelTrimName}}<br></span>
            <span><b>{{ 'WINDOW|Kolor wypełnienia:' | translate }}</b>&nbsp;{{box.color}}<br></span>
            <span *ngIf="box.color2"><b>{{ 'WINDOW|Kolor wypełnienia 2:' | translate }}</b>&nbsp;{{box.color2}}<br></span>
        </p>
        <p class="glassTypeDescription" *ngIf="box.panelType != 'Outer' && !box.oneGlazing">
            {{ 'WINDOW|Różne pakiety w poszczególnych kwaterach, szczegóły w podsumowaniu.' | translate }}
        </p>
    </div>
    <div class="doorPanels" *ngIf="box.type === 'door_panels'">
        <p class="glassTypeDescription">
            <span><b>{{ 'WINDOW|Model:' | translate }}</b>&nbsp;{{box.name}}<br></span>
            <span *ngIf="box.panelGlazingName"><b>{{ 'WINDOW|Szklenie:' | translate }}</b>&nbsp;{{box.panelGlazingName}}<br></span>
            <span *ngIf="box.panelTrimName"><b>{{ 'DOOR|Listwa:' | translate }}</b>&nbsp;{{box.panelTrimName}}<br></span>
        </p>
    </div>
</ng-template>
