import { WindowConfigurationLegacy18 } from '../../legacy/WindowConfigurationLegacy18';
import { IWindowConfiguration } from '../../WindowConfiguration';


// eslint-disable-next-line max-statements
export function changesInVersion19(
    oldConfiguration: WindowConfigurationLegacy18,

): IWindowConfiguration {
    const configuration: IWindowConfiguration = {
        ...oldConfiguration,
        oneFilling: {...oldConfiguration.oneFilling, defaultWindow: oldConfiguration.oneFilling.window }
    };
    return configuration;
}
