import { Common } from '@icc/common/Common';
const snakeCase = require('snake-case');
import { logger, core } from '@icc/common/helpers';
import { ModalService, SharedFacade } from '@icc/configurator/shared';
import { IccConstructColor, IccColorGroup } from '@icc/common/data-types';
import {
    ConfigurationsService,
    ConfiguratorsDataService,
    EventBusService,
    APP_CONFIG,
    AppConfigFactory,
    WindowActiveConfiguration,
    ColorsGroupsService,
} from '@icc/common';
import { PriceService } from '@icc/price';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { Inject, Injectable } from '@angular/core';
import { ColorsPageComponent } from 'libs/configurator/window/src/lib/colors-page/colors-page.component';

/**
 * Serwis kolorów rolet
 */
@Injectable()
export class ColorsService {
    private colors: IccConstructColor[] = [];
    loadedData = false;

    private configurators = ['window', 'hs', 'roller_shutter', 'external_blind', 'sliding_door', 'folding_door'];

    private modalConfigurator = false;

    constructor(
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind' | 'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceService: PriceService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private colorsMappingService: ColorMappingService,
        private eventBusService: EventBusService,
        private colorsGroupsService: ColorsGroupsService,
        private sharedFacade: SharedFacade,
    ) {
        'ngInject';

        if (
            this.configuratorsDataService.loaded
            && this.configurators.indexOf(this.currentConfiguratorService.conf) > -1
        ) {
            this.init(true);
        }

        this.eventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => {
            this.init(true);
        });
        this.eventBusService.subscribeWithoutConfiguration(
            ['saveGuideProfile', 'saveSlatProfile', 'montageOptionsChanged'],
            () => {
                this.init();
            }
        );
        if (this.config().IccConfig.Configurators.roller_shutter.boxHeightColorsGroups) {
            this.eventBusService.subscribeWithoutConfiguration(
                ['rollerBoxHeightChanged'],
                () => {
                    this.init(false, false);
                }
            );
        }
        this.eventBusService.subscribe(['setConstructionColor'], data => {
            if (WindowActiveConfiguration.is(data?.activeConfiguration) && data?.activeConfiguration.RollerShutter?.colors) {
                this.setDefaultsColorsOnChangeColor('window', false, data?.activeConfiguration, data?.activeConfiguration);
            }
        });
        this.sharedFacade.getIsModalConfigurator$.subscribe(active => {
            this.modalConfigurator = active;
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    // eslint-disable-next-line max-statements
    public init(
        first = false,
        setDefaultsColorsOnChangeColor = true,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        if (
            Common.isArray(this.configuratorsDataService.data.windowColorsAll)
            && Common.isArray(this.configuratorsDataService.data.windowColorGroups)
        ) {
            this.colors = this.configuratorsDataService.data.windowColorsAll;

            const guideRailsIds = this.getGuideRailsIds(conf);
            const slatsIds = this.getSlatsIds(conf);
            this.colorsGroupsService.boxGroups = [];
            this.colorsGroupsService.guideRailGroups = [];
            this.colorsGroupsService.revisionGroups = [];
            this.colorsGroupsService.boxInnerGroups = [];
            this.colorsGroupsService.boxGuideOuterGroups = [];
            this.colorsGroupsService.profileGroups = [];
            this.colorsGroupsService.endslatGroups = [];
            this.colorsGroupsService.boxSideSurfaceGroups = [];
            this.configuratorsDataService.data.windowColorGroups.forEach((el) => {
                if (el.roller_systems
                    && el.roller_systems.indexOf(conf.RollerShutter.system?.id) > -1
                    && Common.isArray(el.roller_element)
                ) {
                    if (el.roller_element.indexOf('box') > -1) {
                        this.colorsGroupsService.boxGroups.push(el);
                    }
                    if (
                        el.roller_element.indexOf('guide_rail') > -1
                        && (!this.config().IccConfig.Configurators.roller_shutter.guides
                            || (this.config().IccConfig.Configurators.roller_shutter.guides
                                && guideRailsIds?.length > 0
                                && el.profiles
                                && el.profiles.some(r => guideRailsIds.includes(Number(r))))
                        )
                    ) {
                        this.colorsGroupsService.guideRailGroups.push(el);
                    }
                    if (el.roller_element.indexOf('revision') > -1) {
                        this.colorsGroupsService.revisionGroups.push(el);
                    }
                    if (el.roller_element.indexOf('box_inner') > -1) {
                        this.colorsGroupsService.boxInnerGroups.push(el);
                    }
                    if (el.roller_element.indexOf('box_guide_outer') > -1) {
                        this.colorsGroupsService.boxGuideOuterGroups.push(el);
                    }
                    if (
                        el.roller_element.indexOf('profile') > -1
                        && ((el.profiles && el.profiles.indexOf(conf.RollerShutter.profile.id) > -1)
                            || this.config().IccConfig.Configurators.roller_shutter
                                .autoProfileSelection)
                    ) {
                        this.colorsGroupsService.profileGroups.push(el);
                    }
                    if (
                        el.roller_element.indexOf('endslat') > -1
                        && (!this.config().IccConfig.Configurators.roller_shutter.slats
                            || (this.config().IccConfig.Configurators.roller_shutter.slats
                                && slatsIds?.length > 0
                                && el.profiles
                                && el.profiles.some(r => slatsIds.includes(Number(r))))
                        )
                    ) {
                        this.colorsGroupsService.endslatGroups.push(el);
                    }
                    if (el.roller_element.indexOf('box_side_surface') > -1) {
                        this.colorsGroupsService.boxSideSurfaceGroups.push(el);
                    }
                }
            });
        }
        conf.RollerShutter.guideType = 'pvc';
        defaultConf.RollerShutter.guideType = 'pvc';
        if (!(this.configurationsService.conf.Edit && first)) {

            if (this.configurationsService.conf.Current.type === 'window' && !this.configurationsService.conf.Current.hasRoller) {
                this.setDefaultsColors(conf, defaultConf, setDefaultsColorsOnChangeColor);
            } else if (['roller_shutter', 'external_blind'].indexOf(this.configurationsService.conf.Current.type) > -1 && !this.configurationsService.conf.Current.RollerShutter.hasRoller) {
                this.setDefaultsColors(conf, defaultConf, setDefaultsColorsOnChangeColor);
            }

        }
        this.eventBusService.post({
            key: 'setShutterColor',
            value: null,
        });
        this.loadedData = true;
    }

    /**
     * Funkcja ładujaca kolor dla systemów
     */
    public loadColorsForSystem() {
        this.init();
    }

    /**
     * Funkcja ustawiajaca domyślny kolor
     */
    public setDefaultsColors(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
        setDefaultsColorsOnChangeColor = true
    ) {
        let parts = [];
        if (conf.type === 'roller_shutter' || conf.type === 'external_blind') {
            parts = [
                'profile',
                'box',
                'boxInner',
                'boxGuideOuter',
            ];
        } else {
            parts = [
                'box',
                'boxInner',
                'boxGuideOuter',
                'profile',
            ];
        }
        parts = parts.concat([
            'guideRail',
            'endslat',
            'revision',
            'boxSideSurface',
        ]);
        const colorsForParts = this.getColorsForParts(parts, conf);
        for (let i = 0; i < parts.length; i++) {
            const matchingColors = colorsForParts[parts[i]] || [];
            const currentColor = matchingColors.find(el => el.id === conf.RollerShutter.colors[parts[i]]?.id);

            this.setColor(
                currentColor ?? this.getDefaultColorForGroup(parts[i], conf, matchingColors),
                null,
                parts[i],
                currentColor ? conf.RollerShutter.colors[parts[i]]?.isDefault : true,
                conf,
                defaultConf,
                setDefaultsColorsOnChangeColor
            );
        }
    }

    /**
     * Ustawia domyślne kolory pasujące do danego koloru.
     *
     * @param {any}     group              Miejsce koloru
     * @param {boolean} [setDefault=false] Czy ustawić w tym miejscu domyślny.
     */
    public setDefaultsColorsOnChangeColor(
        group,
        setDefault = false,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Current
    ) {
        if (group !== 'window') {
            if (
                setDefault
                && (!conf.RollerShutter.colors[group] || conf.RollerShutter.colors[group].isDefault)
            ) {
                this.setColor(
                    this.getDefaultColorForGroup(group, conf),
                    null,
                    group,
                    true,
                    conf,
                    defaultConf
                );
            }
        }
        if (group === 'window') {
            this.setDefaultsColorsOnChangeColor('boxInner', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('revision', true, conf, defaultConf);
            if (this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
                this.setDefaultsColorsOnChangeColor('guideRail', true, conf, defaultConf);
            } else {
                this.setDefaultsColorsOnChangeColor('boxGuideOuter', true, conf, defaultConf);
            }
        } else if (group === 'boxGuideOuter') {
            if (
                !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                && conf.RollerShutter.type.type === 'N'
            ) {
                this.setDefaultsColorsOnChangeColor('profile', true, conf, defaultConf);
                this.setDefaultsColorsOnChangeColor('boxInner', true, conf, defaultConf);
                this.setDefaultsColorsOnChangeColor('box', true, conf, defaultConf);
                this.setDefaultsColorsOnChangeColor('revision', true, conf, defaultConf);
                this.setDefaultsColorsOnChangeColor('endslat', true, conf, defaultConf);
                this.setDefaultsColorsOnChangeColor('boxSideSurface', true, conf, defaultConf);
            }
        } else if (group === 'guideRail') {
            this.setDefaultsColorsOnChangeColor('profile', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('boxGuideOuter', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('boxInner', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('box', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('revision', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('endslat', true, conf, defaultConf);
            this.setDefaultsColorsOnChangeColor('boxSideSurface', true, conf, defaultConf);
        }
    }

    /**
     * Funkcja ładujaca domyślny profil koloru
     */
    public loadDefaultProfileColor() {
        this.setDefaultsColors();
    }

    /**
     * Funkcja ustawiajaca kolor
     * @param {object} color Kolor
     * @param {object} group Grupa
     * @param {object} prop  Prop
     */
    public setColor(
        color,
        group,
        prop,
        isDefault = false,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
        setDefaultsColorsOnChangeColor = true
    ) {
        if (!Common.isObject(color)) {
            conf.RollerShutter.colors[prop + 'Charge'] = null;
            defaultConf.RollerShutter.colors[prop + 'Charge'] = null;

            conf.RollerShutter.colors[prop + 'ChargeM2'] = null;
            defaultConf.RollerShutter.colors[prop + 'ChargeM2'] = null;

            conf.RollerShutter.colors[prop + 'ChargeH'] = null;
            defaultConf.RollerShutter.colors[prop + 'ChargeH'] = null;

            conf.RollerShutter.colors[prop + 'ChargeHWithBox'] = null;
            defaultConf.RollerShutter.colors[prop + 'ChargeHWithBox'] = null;

            conf.RollerShutter.colors[prop + 'ChargeW'] = null;
            defaultConf.RollerShutter.colors[prop + 'ChargeW'] = null;
            return;
        }
        color = core.copy(color);
        color.isDefault = isDefault;
        conf.RollerShutter.colors[prop] = color;
        defaultConf.RollerShutter.colors[prop] = color;

        const groupsForColor = this.colorsGroupsService.findGroupForColor(color, this.colorsGroupsService[prop + 'Groups'], true);
        const { colorFactor: charge, colorGroupId: colorGroupId } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_percent');
        const { colorFactor: chargeM2, colorGroupId: colorGroupIdM2 } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_m2');
        const { colorFactor: chargeH, colorGroupId: colorGroupIdH } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_h');
        const { colorFactor: chargeHWithBox, colorGroupId: colorGroupIdHWithBox } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_h_with_box');
        const { colorFactor: chargeW, colorGroupId: colorGroupIdW } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_w');
        if (!Common.isString(group)) {
            group = this.getColorGroup(color, groupsForColor);
        }

        conf.RollerShutter.colors[prop + 'Group'] = group;
        defaultConf.RollerShutter.colors[prop + 'Group'] = group;

        conf.RollerShutter.colors[prop + 'Charge'] = charge;
        conf.RollerShutter.colors[prop + 'ColorGroupId'] = colorGroupId;
        defaultConf.RollerShutter.colors[prop + 'Charge'] = charge;
        defaultConf.RollerShutter.colors[prop + 'ColorGroupId'] = colorGroupId;

        conf.RollerShutter.colors[prop + 'ChargeM2'] = chargeM2;
        conf.RollerShutter.colors[prop + 'ColorGroupIdM2'] = colorGroupIdM2;
        defaultConf.RollerShutter.colors[prop + 'ChargeM2'] = chargeM2;
        defaultConf.RollerShutter.colors[prop + 'ColorGroupIdM2'] = colorGroupIdM2;

        conf.RollerShutter.colors[prop + 'ChargeH'] = chargeH;
        conf.RollerShutter.colors[prop + 'ColorGroupIdH'] = colorGroupIdH;
        defaultConf.RollerShutter.colors[prop + 'ChargeH'] = chargeH;
        defaultConf.RollerShutter.colors[prop + 'ColorGroupIdH'] = colorGroupIdH;

        conf.RollerShutter.colors[prop + 'ChargeHWithBox'] = chargeHWithBox;
        conf.RollerShutter.colors[prop + 'ColorGroupIdHWithBox'] = colorGroupIdHWithBox;
        defaultConf.RollerShutter.colors[prop + 'ChargeHWithBox'] = chargeHWithBox;
        defaultConf.RollerShutter.colors[prop + 'ColorGroupIdHWithBox'] = colorGroupIdHWithBox;

        conf.RollerShutter.colors[prop + 'ChargeW'] = chargeW;
        conf.RollerShutter.colors[prop + 'ColorGroupIdW'] = colorGroupIdW;
        defaultConf.RollerShutter.colors[prop + 'ChargeW'] = chargeW;
        defaultConf.RollerShutter.colors[prop + 'ColorGroupIdW'] = colorGroupIdW;

        if (setDefaultsColorsOnChangeColor) {
            this.setDefaultsColorsOnChangeColor(prop, false, conf, defaultConf);
        }
        this.eventBusService.post({
            key: 'setShutterColor',
            value: null,
        });
        this.priceService.count();
    }

    /**
     * Funkcja pobierajaca max grupe kolorow
     * @param  {object} colorGroups Grupa kolorow
     * @param  {object} prop        Prop
     */
    getMaxColorGroupFactor(colorGroups, prop) {
        let colorFactor = null;
        let colorGroupId = null;
        if (Common.isDefined(colorGroups)) {
            for (let i = 0; i < colorGroups.length; i++) {
                if (colorFactor === null || colorGroups[i][prop] * 1 > colorFactor) {
                    colorFactor = (colorGroups[i][prop] || 0) * 1;
                    colorGroupId = colorGroups[i].id;
                }
            }
        }

        return { colorFactor, colorGroupId };
    }

    /**
     * Zwraca grupę koloru - bez dopłaty, za dopłata.
     *
     * @param      {object}  color   Kolor
     * @return     {string}  Grupa koloru
     */
    getColorGroup(color, colorGroups) {
        const groupsForColor = this.colorsGroupsService.findGroupForColor(color, colorGroups);
        const { colorFactor: charge } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_percent');
        const { colorFactor: chargeM2 } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_m2');
        const { colorFactor: chargeH } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_h');
        const { colorFactor: chargeHWithBox } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_h_with_box');
        const { colorFactor: chargeW } = this.getMaxColorGroupFactor(groupsForColor, 'rs_price_w');
        let group = 'color';
        if (charge > 0 || chargeM2 > 0 || chargeH > 0 || chargeW > 0 || chargeHWithBox > 0) {
            group = 'charge';
        }
        return group;
    }


    private getGuideRailsIds(conf) {
        const guideRailsIds = [];
        if (conf.RollerShutter.guideRails && conf.RollerShutter.guideRails.length) {
            for (let i = 0; i < conf.RollerShutter.guideRails.length; i++) {
                if (!guideRailsIds.includes(Number(conf.RollerShutter.guideRails[i].id))
                    && conf.RollerShutter.guideRails[i].id !== null) {
                    guideRailsIds.push(Number(conf.RollerShutter.guideRails[i].id));
                }
            }
        }
        return guideRailsIds;
    }

    private getSlatsIds(conf) {
        const slatsIds = [];
        if (conf.RollerShutter.slats && conf.RollerShutter.slats.length) {
            for (let i = 0; i < conf.RollerShutter.slats.length; i++) {
                if (!slatsIds.includes(Number(conf.RollerShutter.slats[i].id))
                    && conf.RollerShutter.slats[i].id !== null) {
                    slatsIds.push(Number(conf.RollerShutter.slats[i].id));
                }
            }
        }
        return slatsIds;
    }

    /**
     * Funkcja służaca do sortowania kolorów w kolejnosci pierwsze bez dopłaty, potem te za dopłata.
     *
     * @param      {object}  a       Kolor 1
     * @param      {object}  b       Kolor 2
     * @return     {number}  Wynik porównania
     */
    compareColors(colorGroups, a, b) {
        const groupA = this.getColorGroup(a, colorGroups);
        const groupB = this.getColorGroup(b, colorGroups);
        if (groupA === 'color' && groupB === 'charge') {
            return -1;
        } else if (groupA === groupB) {
            return a.order - b.order;
        } else if (groupA === 'charge' && groupB === 'color') {
            return 1;
        }
        return 0;
    }

    /**
     * Funkcja pobierajaca grupe kolorow
     * @param  {object}  part     Grupa
     * @param  {object}  hasCharge Załadowana
     * @param  {object}  conf      Konfiguracja
     */
    getColorsForGroup(part, conf) {
        let colorGroups = this.configuratorsDataService.data.windowColorGroups;
        switch (part) {
            case 'box':
                colorGroups = this.colorsGroupsService.boxGroups;
                break;
            case 'guideRail':
                colorGroups = this.colorsGroupsService.guideRailGroups;
                break;
            case 'revision':
                colorGroups = this.colorsGroupsService.revisionGroups;
                break;
            case 'boxInner':
                colorGroups = this.colorsGroupsService.boxInnerGroups;
                break;
            case 'boxGuideOuter':
                colorGroups = this.colorsGroupsService.boxGuideOuterGroups;
                break;
            case 'profile':
                colorGroups = this.colorsGroupsService.profileGroups;
                break;
            case 'endslat':
                colorGroups = this.colorsGroupsService.endslatGroups;
                break;
            case 'boxSideSurface':
                colorGroups = this.colorsGroupsService.boxSideSurfaceGroups;
                break;
        }

        const groupsIds = new Map();

        for (let i = colorGroups.length - 1; i >= 0; i--) {
            groupsIds.set(Number(colorGroups[i].id), true);
        }

        const boxHeightsColorsGroups = new Map();
        conf.RollerShutter?.system?.box_heights?.find(el => el.height === conf.RollerShutter.realBoxHeight)?.colorGroups?.forEach(el => boxHeightsColorsGroups.set(Number(el), true));
        const availColors = [];
        (this.configuratorsDataService.data.windowColorsAll || []).forEach((color) => {
            if (
                color.groups.some(colorGroup =>
                    groupsIds.has(Number(colorGroup))
                    && (!this.config().IccConfig.Configurators.roller_shutter
                            .boxHeightColorsGroups ||
                            (part !== 'box' &&
                                part !== 'boxInner' &&
                                part !== 'boxGuideOuter'
                            ) ||
                            boxHeightsColorsGroups.has(Number(colorGroup))
                        ) && (
                            part !== 'boxGuideOuter' && part !== 'boxInner' ||
                            part === 'boxGuideOuter'
                                && (
                                    (conf.RollerShutter.system.outside_concealed_strip
                                        && conf.RollerShutter.concealedStrip) === Boolean(color.concealed)
                                ) ||
                            part === 'boxInner'
                                && (
                                    (conf.RollerShutter.system.inside_concealed_strip
                                        && conf.RollerShutter.insideConcealedStrip) === Boolean(color.concealed)
                                )
                        )
                )
            ) {
                availColors.push({...color, colorGroups: color.groups});
            }
        });
        availColors.sort(this.compareColors.bind(this, colorGroups));

        return availColors;
    }

    getColorsForParts(parts, conf) {
        const colorsForParts = {};
        const groupsIds = {};

        parts.forEach(part => {
            groupsIds[part] = new Map();
            this.colorsGroupsService[part + 'Groups'].forEach(group => {
                groupsIds[part].set(group.id, true);
            });
        });

        const boxHeightsColorsGroups = new Map();
        conf.RollerShutter?.system?.box_heights?.find(el => el.height === conf.RollerShutter.realBoxHeight)?.colorGroups?.forEach(el => boxHeightsColorsGroups.set(Number(el), true));
        (this.configuratorsDataService.data.windowColorsAll || []).forEach(color => {
            if (color.groups) {
                parts.forEach(part => {
                    if (
                        color.groups.some(colorGroup =>
                            groupsIds[part].has(Number(colorGroup))
                            && (!this.config().IccConfig.Configurators.roller_shutter
                                    .boxHeightColorsGroups ||
                                    (part !== 'box' &&
                                        part !== 'boxInner' &&
                                        part !== 'boxGuideOuter'
                                    ) ||
                                    boxHeightsColorsGroups.has(Number(colorGroup))
                                ) && (
                                    part !== 'boxGuideOuter' && part !== 'boxInner' ||
                                    part === 'boxGuideOuter'
                                        && (
                                            (conf.RollerShutter.system.outside_concealed_strip
                                                && conf.RollerShutter.concealedStrip) === Boolean(color.concealed)
                                        ) ||
                                    part === 'boxInner'
                                        && (
                                            (conf.RollerShutter.system.inside_concealed_strip
                                                && conf.RollerShutter.insideConcealedStrip) === Boolean(color.concealed)
                                        )
                                )
                        )
                    ) {
                        if (!colorsForParts[part]) {
                            colorsForParts[part] = [];
                        }
                        colorsForParts[part].push({...color, colorGroups: color.groups});
                    }
                });
            }
        });
        parts.forEach(part => {
            if (colorsForParts[part]) {
                colorsForParts[part] = colorsForParts[part].sort(this.compareColors.bind(this, this.colorsGroupsService[part + 'Groups']));
            }
        });
        return colorsForParts;
    }

    /**
     * Funckaj pobierajaca domyslny kolor dla grupy
     * @param  {object} group Grupa
     * @param  {object} conf  Konfiguracja
     */
    getDefaultColorForGroup(group, conf, colorsForGroup = null) {
        let matchedColors = [];
        let windowConf;

        ['door', 'hs', 'folding_door', 'sliding_door', 'window'].forEach((type) => {
            if (this.configurationsService.configurators[type]?.Current?.RollerShutter && this.configurationsService.configurators[type]?.Current?.Sashes?.length) {
                windowConf = this.configurationsService.configurators[type].Current;
                return;
            }
        });

        if ((conf.type === 'roller_shutter' || conf.type === 'external_blind') && (!windowConf || !this.modalConfigurator)) {
            if (group !== 'guideRail' && group !== 'boxGuideOuter') {
                // z prowadnic - guideRail lub boxGuideOuter
                let guideColorId = null;
                if (
                    conf.RollerShutter.type.type === 'N'
                    && !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                    && conf.RollerShutter.colors.boxGuideOuter
                ) {
                    guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
                } else if (conf.RollerShutter.colors.guideRail) {
                    guideColorId = conf.RollerShutter.colors.guideRail.id;
                }
                matchedColors = this.colorsMappingService.getColors(
                    guideColorId,
                    'window',
                    'window'
                );
            } else if (group === 'boxGuideOuter') {
                if (
                    conf.RollerShutter.type.type !== 'N'
                    || this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                ) {
                    // z prowadnic
                    let guideColorId = null;
                    if (conf.RollerShutter.colors.guideRail) {
                        guideColorId = conf.RollerShutter.colors.guideRail.id;
                    }
                    matchedColors = this.colorsMappingService.getColors(
                        guideColorId,
                        'window',
                        'window'
                    );
                }
            }
        } else {
            if (
                windowConf
                 && ((group === 'boxGuideOuter'
                    && !this.config().IccConfig.Configurators.roller_shutter
                        .boxColorGuideColor)
                || group === 'guideRail')
            ) {
                // z okna zewn.
                const windowColorId = this.colorsMappingService.getWindowColorId(windowConf, 'outer');
                matchedColors = this.colorsMappingService.getColors(
                    windowColorId,
                    'window',
                    'window'
                );
            } else if (windowConf && (group === 'boxInner' || group === 'revision')) {
                // z okna wewn.
                const windowColorId = this.colorsMappingService.getWindowColorId(windowConf, 'inner');
                matchedColors = this.colorsMappingService.getColors(
                    windowColorId,
                    'window',
                    'window'
                );
            } else {
                // z prowadnic - guideRail lub boxGuideOuter
                let guideColorId = null;
                if (
                    !this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
                    && conf.RollerShutter.colors.boxGuideOuter
                ) {
                    guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
                } else if (conf.RollerShutter.colors.guideRail) {
                    guideColorId = conf.RollerShutter.colors.guideRail.id;
                }
                matchedColors = this.colorsMappingService.getColors(
                    guideColorId,
                    'window',
                    'window'
                );
            }
        }

        if (colorsForGroup === null) {
            colorsForGroup = this.getColorsForGroup(group, conf);
        }
        if (colorsForGroup.length > 0) {
            const matchedColorsToGroup = matchedColors
                .map(m => colorsForGroup.filter(c => c && Number(c.id) === m)[0])
                .filter(m => m);
            const concealedColorsToGroup = colorsForGroup.filter(c => c && c.concealed);
            if (
                group === 'boxGuideOuter'
                && conf.RollerShutter.system.outside_concealed_strip
                && conf.RollerShutter.concealedStrip
                && concealedColorsToGroup.length ||
                group === 'boxInner'
                && conf.RollerShutter.system.inside_concealed_strip
                && conf.RollerShutter.insideConcealedStrip
                && concealedColorsToGroup.length
            ) {
                return concealedColorsToGroup[0];
            }
            if (
                matchedColorsToGroup.length
                && this.config().IccConfig.Configurators.colorsMapping.roller_shutter
            ) {
                return matchedColorsToGroup[0];
            }
            return colorsForGroup[0];
        }
        return null;
    }

    /**
     * Funkcja otwierajaca okno modalne
     * @param  {object} colorType Typ koloru
     * @param  {object} conf      Konfiguracja
     */
    public openModalRollerColor(colorType, stepName, conf = this.configurationsService.conf.Current) {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalColorPVC.html',
            controller: 'ModalColorPVCCtrl as mColorPVC',
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => this.getColorsForGroup(colorType, conf),
                colorsRAL: () => [],
                selectedColor: () => conf.RollerShutter.colors[colorType],
                colorGroups: () => this.colorsGroupsService[colorType + 'Groups'].filter(el => el.target.indexOf('show_roller') > -1),
                colorsSashExt: () => false,
                type: () => false,
                stepName: () => stepName,
            },
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'color',
            });
        }

        modalInstance.result.then(data => {
            if (Common.isDefined(data)) {
                this.setColor(data.color, data.group, colorType);
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }
}
