<form class="mat-elevation-z3" #dimensionsForm="ngForm">
    <ng-container *ngFor="let shutter of shutters$ | async; index as index">
        <div class="inputs-container">
            <div class="input-label">
                <h4 *ngIf="(shutters$ | async).length > 1">{{ 'ROLLERSHUTTER|Segment' | translate }} {{letters[index]}}</h4>
                <h4 *ngIf="(shutters$ | async).length === 1">{{ 'ROLLERSHUTTER|Wymiar rolety' | translate }}</h4>
            </div>
            <div class="input-container">
                <mat-form-field>
                    <mat-label [ngClass]="{
                        'label-valid':
                            shutterSizeRangeFull[index]['minWidth'] <= shutter.realWidth
                            && shutterSizeRangeFull[index]['maxWidth'] >= shutter.realWidth
                            && this.showDimensionSizeRange
                        }">
                        <div class="input-container__label">
                            {{ 'ROLLERSHUTTER|Szerokość' | translate }}
                        </div>
                        <div class="input-container__label--extra" *ngIf="shutterSizeRangeFull[index] && this.showDimensionSizeRange && shutter.realWidth && shutter.realHeight">
                            {{ 'ROLLERSHUTTER|Pełny zakres rozmiarów' | translate }}
                            {{ shutterSizeRangeFull[index]['minWidthMM'] }} -
                            {{ shutterSizeRangeFull[index]['maxWidthMM'] }}
                        </div>
                    </mat-label>
                    <input
                        matInput
                        [iccDimensionsValidator]="shutter.realWidth"
                        [isWidthDimension]="true"
                        [rollerShutterIndex]="index"
                        iccDimensionInput
                        type="number"
                        [name]="'shutter-width' + index"
                        [ngModel]="shutter.realWidth"
                        (ngModelChange)="shutter.realWidth = $event; updateDimensions(false, index)"
                        [ngModelOptions]="{ updateOn: 'blur' }"
                        [disabled]="(shutters$ | async).length === 1 && modalConfigurator"
                        #shutterWidth="ngModel"
                        [ngClass]="{ 'input-invalid': shutterWidth.invalid && this.showDimensionSizeRange, 'input-container__input':  this.showDimensionSizeRange && shutter.realWidth && shutter.realHeight}"
                    />
                    <mat-error *ngIf="shutterWidth.invalid && !this.showDimensionSizeRange">{{
                        'CONFIGURATOR|Podane wymiary są nieprawidłowe.' | translate
                    }}</mat-error>
                    <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
                    <mat-error *ngIf="shutterWidth.invalid && shutterSizeRange[index].width !== null && this.showDimensionSizeRange" >
                        {{ shutterSizeRange[index].width }}
                    </mat-error>
                    <mat-hint *ngIf="!shutterWidth.invalid && shutterSizeRange[index].width !== null && this.showDimensionSizeRange && shutter.realWidth && shutter.realHeight">
                        {{ shutterSizeRange[index].width }}
                    </mat-hint>
                    <mat-hint *ngIf="this.showDimensionSizeRange && (!shutter.realWidth || !shutter.realHeight)">
                        {{ 'ROLLERSHUTTER|Pełny zakres rozmiarów' | translate }}
                        {{ shutterSizeRangeFull[index]['minWidthMM'] }} -
                        {{ shutterSizeRangeFull[index]['maxWidthMM'] }}
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="input-container">
                <mat-form-field>
                    <mat-label
                        [ngClass]="{
                            'label-valid':
                                shutterSizeRangeFull[index]['minHeight'] <= shutter.realHeight
                                && shutterSizeRangeFull[index]['maxHeight'] >= shutter.realHeight
                                && this.showDimensionSizeRange
                        }"
                    >
                        <div class="input-container__label">
                            <span *ngIf="addBoxToHeight" translate>ROLLERSHUTTER|Wysokość <b>wraz ze skrzynką</b></span>
                            <span *ngIf="!addBoxToHeight" translate>ROLLERSHUTTER|Wysokość <b>bez skrzynki</b></span>
                        </div>
                        <div class="input-container__label--extra"  *ngIf="!shutter.commonRail && shutterSizeRangeFull[index] && this.showDimensionSizeRange && shutter.realWidth && shutter.realHeight">
                            {{ 'ROLLERSHUTTER|Pełny zakres rozmiarów' | translate }}
                            {{ shutterSizeRangeFull[index]['minHeightMM'] }} -
                            {{ shutterSizeRangeFull[index]['maxHeightMM'] }}
                        </div>
                    </mat-label>
                    <input
                        matInput
                        [iccDimensionsValidator]="shutter.realHeight"
                        [isWidthDimension]="false"
                        [rollerShutterIndex]="index"
                        iccDimensionInput
                        type="number"
                        step="0.1"
                        [name]="'shutter-length' + index"
                        [ngModel]="
                            this.addBoxToHeight
                                ? shutter.realHeight +
                                  this.configurationsService.conf.Current.RollerShutter.realBoxHeight
                                : shutter.realHeight
                        "
                        (ngModelChange)="shutter.realHeight = $event; updateDimensions(true, index)"
                        [ngModelOptions]="{ updateOn: 'blur' }"
                        [disabled]="shutter.commonRail || modalConfigurator"
                        #shutterLength="ngModel"
                        [ngClass]="{'input-invalid': shutterLength.invalid && this.showDimensionSizeRange, 'input-container__input' : this.showDimensionSizeRange && shutter.realWidth && shutter.realHeight}"
                    />
                    <mat-error *ngIf="shutterLength.invalid && !this.showDimensionSizeRange">{{
                        'CONFIGURATOR|Podane wymiary są nieprawidłowe.' | translate
                    }}</mat-error>
                    <mat-error
                        *ngIf="
                            shutterLength.invalid &&
                            shutterSizeRange[index].height !== null &&
                            this.showDimensionSizeRange
                        "
                    >
                        {{ shutterSizeRange[index].height }}
                    </mat-error>
                    <mat-hint *ngIf="!shutterLength.invalid && shutterSizeRange[index].height !== null && this.showDimensionSizeRange && shutter.realWidth && shutter.realHeight">
                        {{ shutterSizeRange[index].height }}
                    </mat-hint>
                    <mat-hint *ngIf="this.showDimensionSizeRange && (!shutter.realWidth || !shutter.realHeight)">
                        {{ 'ROLLERSHUTTER|Pełny zakres rozmiarów' | translate }}
                        {{ shutterSizeRangeFull[index]['minHeightMM'] }} -
                        {{ shutterSizeRangeFull[index]['maxHeightMM'] }}
                    </mat-hint>
                    <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
    <p class="dimensions-info" (click)="openDimensionsInfoPage()" *ngIf="isInfoPageAvailable">
        {{ 'CONFIGURATOR|Jak mierzyć rolete?' | translate }} <mat-icon>info</mat-icon>
    </p>
    <p *ngIf="dimensionsInfo && (systemInfo$ | async)">
        <mat-icon>info</mat-icon>
        {{ systemInfo$ | async }}
    </p>
    <div *ngIf="(mosquito$ | async) && this.areStepsJoined" id="mosquito-form">
        <form class="mosquito-form">
            <h4>{{ 'ROLLERSHUTTER|Siatka antyinsektowa:' | translate }}</h4>
            <h6 *ngIf="this.showDimensionSizeRange">
                <p>
                    {{ 'ROLLERSHUTTER|Zakres szerokości rolety:' | translate }}
                    {{ mosquitoSizeRange['minWidth'] }} - {{ mosquitoSizeRange['maxWidth'] }}
                </p>
                <p>
                    {{ 'ROLLERSHUTTER|Zakres wysokości rolety:' | translate }}
                    {{ mosquitoSizeRange['minHeight'] }} - {{ mosquitoSizeRange['maxHeight'] }}
                </p>
            </h6>
            <mat-checkbox
                *ngFor="let shutter of shutters$ | async; index as index"
                [name]="'shutter-mosquito-' + index"
                [ngModel]="shutter.mosquito"
                (ngModelChange)="toggleMosquito($event, index)"
            >
                {{ 'ROLLERSHUTTER|Kwatera' | translate }} {{ letters[index] }}
            </mat-checkbox>
        </form>
    </div>
    <div *ngIf="hasGrayPolystyrene || concealedStripAvailable || insideConcealedStripAvailable" id="gray-polystyrene-form">
        <form class="gray-polystyrene-form">
            <mat-checkbox
                *ngIf="hasGrayPolystyrene"
                name="gray-polystyrene"
                [checked]="grayPolystyrene != null"
                (change)="toggleGrayPolystyrene($event)"
            >
                <span class="gray-polystyrene-label"
                    >{{ 'ROLLERSHUTTER|Szary styropian' | translate
                    }}<mat-icon class="mat-16" (click)="showGrayPolystyreneInfo()"
                        >info</mat-icon
                    ></span
                >
            </mat-checkbox>
            <mat-checkbox
                name="concealedStrip"
                [ngModel]="concealedStrip$ | async"
                (ngModelChange)="changeConcealedStrip($event)"
                *ngIf="concealedStripAvailable"
            >
                {{
                    'ROLLERSHUTTER|Listwa tynkowa zewnętrzna' | translate
                }}
            </mat-checkbox>

            <mat-checkbox
                *ngIf="insideConcealedStripAvailable"
                name="insideConcealedStrip"
                [ngModel]="insideConcealedStrip$ | async"
                (ngModelChange)="changeInsideConcealedStrip($event)"
            >
                {{
                    'ROLLERSHUTTER|Listwa tynkowa wewnętrzna' | translate
                }}
            </mat-checkbox>
        </form>
    </div>
</form>
