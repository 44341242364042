<icc-select-box *ngIf="(cylinderMainLock$ | async)"
    [title]="'DOOR|Rodzaj wkładki zamka głównego' | translate"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [imageUrl]="(cylinderMainLockBoxSelect$ | async)?.imagePath"
    [buttonText2]="((isKeyAvailableForMainLock || cylindersBoxSelect?.cylinders?.color) && 'INTERFACE|Opcje' | translate)"
    (select)="changeCylinderToMainLock()"
    (select2)="changeCylinderToMainLockOptions()"
    [descriptionTemplate]="cylindersMainLockBoxTemplate"
    [descriptionContext]="cylindersBoxSelect"
    [disabledButton1]="isCylinderMainLockButtonDisabled()"
    [disabledButton2]="isCylinderMainLockButton2Disabled()"
></icc-select-box>
<icc-select-box
    *ngIf="(cylinderAdditionalLock$ | async)"
    [title]="'DOOR|Rodzaj wkładki zamka dodatkowego' | translate"
    [imageUrl]="(cylinderAdditionalLockBoxSelect$ | async)?.imagePath"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="((isKeyAvailableForAdditionalLock) && 'INTERFACE|Opcje' | translate)"
    (select)="changeCylinderToAdditionalLock()"
    (select2)="changeCylinderToAdditionalLockOptions()"
    [descriptionTemplate]="cylindersAdditionalLockBoxTemplate"
    [descriptionContext]="cylindersBoxSelect"
    [disabledButton1]="isCylinderAdditionalLockButtonDisabled()"
></icc-select-box>
<!--
    /*
    Hidden until client describes how the cylinder system is supposed to work.
    */
<icc-select-box
    *ngIf="(cylinderSystem$ | async)"
    [title]="'DOOR|System wkładek' | translate"
    [description]="(cylinderSystem$ | async)?.name"
    [imageUrl]="'/files/windowaccessorysystem/' + ((cylinderSystem$ | async)?.image || '')"
    [disabledButton1]="isCylinderSystemButtonDisabled()"
></icc-select-box> -->
<icc-select-box
    [title]="'DOOR|Typ elektronicznej wkładki' | translate"
    [imageUrl]="electronicLock.imagePath"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="changeElectronicLock()"
    (select2)="changeElectronicLockOptions()"
    *ngIf = "(cylinderMainLock$ | async)?.electronic_door_lock"
    [disabledButton1]="isElectronickLockButtonDisabled()"
    [disabledButton2]="hasElectronicLockColors()"
    [descriptionTemplate]="electronicLockBoxTemplate"
    [descriptionContext]="electronicLock"
></icc-select-box>

<ng-template #cylindersMainLockBoxTemplate let-cylinders="cylinders">
    <p *ngIf="cylinders?.mainLockName"> <b>{{ 'DOOR|Typ' | translate }}</b> {{cylinders.mainLockName}}</p>
    <p *ngIf="cylinders?.color">
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{cylinders?.color}}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>

<ng-template #cylindersAdditionalLockBoxTemplate let-cylinders="cylinders">
    <p *ngIf="cylinders?.additionalLockName"> <b>{{ 'DOOR|Typ' | translate }}</b> {{cylinders.additionalLockName}}</p>
    <p ng-bind-html="description"></p>
</ng-template>

<ng-template #electronicLockBoxTemplate>
    <p *ngIf="electronicLock?.electronicLockName">
        <b>{{ 'DOOR|Typ' | translate }}</b> {{ electronicLock?.electronicLockName }}
    </p>
    <p *ngIf="electronicLock?.electronicLockColor">
        <b>{{ 'COLOR|Kolor: ' | translate }}</b> {{ electronicLock?.electronicLockColor }}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>
