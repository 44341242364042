import { IEnvConfig, IIccConfig } from '@icc/config';
import { ConfigurationImpl, ActiveConfigurationImpl } from '@icc/common/configurations/configurations.service';
import { Observable, Subject } from 'rxjs';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { Product } from 'libs/product-codes-generator/src/lib/product-feature';

export type ReturnButtonVariant = 'ADD' | 'SAVE' | 'OK' | 'GENERATE_THUMBNAIL' | 'ADD_TO_BASKET' | 'ADD_TO_OFFER' | 'PRINT';
export type ReturnButton = {notifyUrl: string, returnUrl: string, returnButton: string, materialIcon: string}

export interface ConfiguratorBaseOptions {
    type: ConfigurationType;
    preset: 'b2c' | 'b2b';
    userId?: string;
    homeButtonText?: string;
    clientId?: number;
    dealerSellerId?: number;
    userLevel: 'klient' | 'dealer' | 'seller' | null;
    userName: string;
    marketId?: number;
    currency?: any;
    hidePricesInB2cConfigurator: boolean;
    languageCode: string;
    languageCodes: any;
    browserLanguage: boolean;
    returnCallback?: (data: any) => any;
    notifyCallback?: (data: any) => Observable<any>;
    homeUrl?: string;
    lastUrl?: string;
    returnUrl?: string;
    errorUrl?: string;
    notifyUrl?: string;
    actions?: {
        title: string;
        show: (data: any) => boolean;
        url?: string;
        callback?: (data: any) => any;
    }[];
    returnButton: ReturnButtonVariant | (() => ReturnButtonVariant);
    additionalReturnButtons?: ReturnButton[] | (() => ReturnButton[]);
    projectName: string;
    config: IIccConfig;
    mode: 'loadStartState' | 'loadAllDefaults' | 'loadDefaultsFromStateState' | 'layoutThumbnail' | 'loadDefaultsFromStateByShortcode';
    startState?: ConfigurationImpl;
    startStateRef?: string;
    configurationNotFound?: boolean;
    configurationEncryptedId?: string;
    configurationAction?: 'EDIT';
    configurationShortcode?: string;
    defaultState?: ActiveConfigurationImpl;
    defaultWindowLayoutId?: number;
    dimensionUnit: 'mm' | 'inch';
    numberOfPlacesAfterDecimalSeparator: number;
    weightUnit: 'kg' | 'lbs';
    filterAccessoriesByCategories: boolean;
    theme?: IIccConfig['Theme'];
    logo: string;
    startStep?: 'FIRST' | 'DEFAULT';
    embeddedInB2B?: boolean;
    secretToken?: string;
    secretTokenHeader?: string;
    payloadEncoding?: 'json' | 'base64';
    multipliers?: any;
    notificationHeaders?: NotificationHeader[];
    offer?: any;
    userDescription?: string;
    quantity?: number;
    flipSymbol?: boolean;
    skipPayload?: boolean;
}

export interface ConfiguratorOptions {
    type: ConfigurationType;
    preset: 'b2c' | 'b2b';
    userData?: any;
    userLevel: 'klient' | 'dealer' | 'seller' | null;
    userName: string;
    user?: any;
    machine?: string;
    marketId?: number;
    currency?: any;
    currencies?: any;
    hidePricesInB2cConfigurator: boolean;
    languageCode: string;
    languageCodes: any;
    browserLanguage: boolean;
    returnCallback?: (data: any) => any;
    notifyCallback?: (data: any) => Observable<any>;
    saveCoupledWindow?: (data: any) => Observable<any>;
    homeUrl?: string;
    lastUrl?: string;
    returnUrl?: string;
    notifyUrl?: string;
    actions?: {
        title: string;
        show: (configuration: ActiveConfigurationImpl, isBlocked: boolean) => boolean;
        url?: string;
        callback?: (data: any) => any;
        resetConfigurator?: boolean;
    }[];
    returnButton: ReturnButtonVariant | ((configuration: ActiveConfigurationImpl, options: ConfiguratorOptions, data: LoadedConfiguratorsDataValue) => ReturnButtonVariant);
    additionalReturnButtons?: ReturnButton[] | ((configuration: ActiveConfigurationImpl, options: ConfiguratorOptions, data: LoadedConfiguratorsDataValue) => ReturnButton[]);
    projectName: string;
    config: IIccConfig;
    env: IEnvConfig,
    mode: 'loadStartState' | 'loadAllDefaults' | 'loadDefaultsFromStateState' | 'layoutThumbnail' | 'loadDefaultsFromStateByShortcode';
    startState?: ConfigurationImpl | ActiveConfigurationImpl;
    defaultState?: ActiveConfigurationImpl;
    configurationNotFound?: boolean;
    configurationEncryptedId?: string;
    configurationAction?: 'EDIT';
    configurationShortcode?: string;
    defaultWindowLayoutId?: number;
    dimensionUnit: 'mm' | 'inch';
    numberOfPlacesAfterDecimalSeparator: number | undefined;
    weightUnit: 'kg' | 'lbs';
    filterAccessoriesByCategories: boolean;
    theme?: IIccConfig['Theme'];
    logo: string;
    startStep?: 'FIRST' | 'DEFAULT';
    init$?: Subject<any>;
    configuratorDataLoaded?: boolean;
    homeButtonText?: string
    embeddedInB2B?: boolean;
    editedPositionPrices?: {
        clientPrice: number;
        dealerPrice: number;
    }
    domain?: {
        id: number;
        domain: string;
        insert_head_begin: string;
        insert_head_end: string;
        insert_body_end: string;
        theme_logo: string;
        theme_favicon: string;
    };
    measurementsAttachments?: any[];
    multipliers?: any;
    offer?: any;
    stepsDescriptions?: any;
    editMode?: boolean;
    userDescription?: string;
    quantity?: number;
    maxLengthShortSide?: number;
    maxLengthLongerSide?: number;
    listPriceEnableInMarket?: boolean;
    flipSymbol?: boolean;
    skipPayload?: boolean;
}

export type ConfigurationType =
    | 'accessory'
    | 'awning'
    | 'complementary_goods'
    | 'coupled_window'
    | 'door'
    | 'folding_door'
    | 'garage_door'
    | 'hs'
    | 'sliding_door'
    | 'mosquito'
    | 'roller_shutter'
    | 'external_blind'
    | 'pleated_blind'
    | 'window'
    | 'winter_garden';

export const initialOptions: ConfiguratorOptions = {
    type: 'window',
    preset: 'b2c',
    hidePricesInB2cConfigurator: false,
    userLevel: null,
    userName: '',
    languageCode: '',
    languageCodes: '',
    browserLanguage: false,
    mode: 'loadAllDefaults',
    config: null,
    env: null,
    projectName: 'icc',
    dimensionUnit: 'mm',
    numberOfPlacesAfterDecimalSeparator: 0,
    weightUnit: 'kg',
    filterAccessoriesByCategories: false,
    logo: '',
    returnButton: 'ADD',
    editMode: false,
    userDescription: '',
    quantity: 1
};

export interface NotificationHeader {
    name: string;
    value: string;
}

export interface NotificationHeaderDictionary {
    [index: string]: string;
}


export interface ConfiguratorNotifyOption {
    storedConfigurationRef: string,
    encryptedConfigurationId: string,
    currencyCode: string,
    payloadVersion: number,
    payloadEncoding: 'json' | 'base64',
    payload: string,
    imageOuter: string,
    imageInner: string,
    quantity: number,
    products: Product[],
    userDescription: string,
    maxAltProductionTime: number;
    customName?: string,
    syncDate?: {
        lastUtcConfiguratorData: string,
        lastUtcPrice: string
    }
}
