import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import { HardwareService } from '../hardware/hardware.service';
import { DoorFacade } from '../+state/door.facade';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { Subscription } from 'rxjs';

interface CylindersSelectBox {
    cylinders: {
        imagePath: string;
        mainLockName: string;
        additionalLockName: string;
        color: string;
        system: string;
        electronicLock: string | null;
    }
}

@Component({
    selector: 'icc-cylinders-page',
    templateUrl: './cylinders-page.component.html',
    styleUrls: ['./cylinders-page.component.scss'],
})
export class CylindersPageComponent extends PageComponent implements OnInit {
    public title = _('DOOR|Wkładki');
    public options = [];

    cylinderMainLock$ = this.doorFacade.cylinderMainLock$;
    cylindersBoxSelect: CylindersSelectBox | null = null;
    cylinderAdditionalLock$ = this.doorFacade.cylinderAdditionalLock$;
    cylinderSystem$ = this.doorFacade.cylinderSystem$;
    electronicLock$ = this.doorFacade.electronicLock$;
    electronicLock: any;
    cylinderAdditionalLockBoxSelect$ = this.doorFacade.cylinderAdditionalLockBoxSelect$;
    cylinderMainLockBoxSelect$ = this.doorFacade.cylinderMainLockBoxSelect$;
    isKeyAvailableForMainLock: boolean;
    isKeyAvailableForAdditionalLock: boolean;

    private subscriptions: Subscription[] = [];
    constructor(
        private hardwareService: HardwareService,
        private doorFacade: DoorFacade,
        private configurationsService: ConfigurationsService<'door'>,
    ) {
        super();
    }

    ngOnInit() {
        const conf = this.configurationsService.conf.Current;
        this.isKeyAvailableForMainLock = conf.doorHardware.cylinders.isKeyAvailableForMainLock;
        this.isKeyAvailableForAdditionalLock = conf.doorHardware.cylinders.isKeyAvailableForAdditionalLock;

        this.subscriptions.push(this.doorFacade.cylindersBoxSelect$.subscribe((box) => {
            this.cylindersBoxSelect = box;
        }));

        this.subscriptions.push(this.doorFacade.electronicLockBoxSelect$.subscribe((box) => {
            this.electronicLock = box;
        }))
    }

    changeCylinderToMainLock() {
        this.hardwareService.openCylinderToMainLockModal();
    }

    changeCylinderToAdditionalLock() {
        this.hardwareService.openCylinderToAdditionalLockModal();
    }

    changeCylinderSystem() {
        this.hardwareService.openCylinderSystemModal();
    }

    changeElectronicLock() {
        this.hardwareService.openElectronicLockModal();
    }

    changeElectronicLockOptions() {
        this.hardwareService.openElectronicLockOptions();
    }

    changeDecorativeHingeCover() {
        this.hardwareService.openModalDecorativeHingeCoverOptions();
    }

    changeCylinderToMainLockOptions() {
        this.hardwareService.openModalCylinderToMainLockOptions();
    }

    changeCylinderToAdditionalLockOptions() {
        this.hardwareService.openModalCylinderToAdditionalLockOptions();
    }

    isCylinderMainLockButtonDisabled() {
        const cylindersToMainLock = this.hardwareService.getCylindersToMainLock();
        const cylindersToMainLockLength = cylindersToMainLock.length;

        return cylindersToMainLockLength <= 1;
    }

    isCylinderMainLockButton2Disabled(conf = this.configurationsService.conf.Current) {
        const cylinderId = conf.doorHardware?.cylinders?.mainLock;
        const cylindersToMainLock = this.hardwareService.getCylindersToMainLock();
        const selectedCylinderToMainLock = cylindersToMainLock.find(p => Number(p.id) === Number(cylinderId));
        const cylinderColorsLength = selectedCylinderToMainLock?.colors_ids?.length;

        return cylinderColorsLength <= 1 && !selectedCylinderToMainLock.additional_key_for_the_cylinder;
    }

    isCylinderAdditionalLockButtonDisabled() {
        const cylindersToAdditionalLock = this.hardwareService.getCylindersToAdditionalLock();
        const cylindersToAdditonalLockLength = cylindersToAdditionalLock.length;
        if (cylindersToAdditonalLockLength > 1) {
            return false;
        }
        return true;
    }

    isCylinderSystemButtonDisabled() {
        const cylidnerSystem = this.hardwareService.getCylinderSystems();
        const cylinderSystemLength = cylidnerSystem.length;
        if (cylinderSystemLength > 1) {
            return false;
        }
        return true;
    }

    isElectronickLockButtonDisabled() {
        const electronicLocks = this.hardwareService.electronicLocks;

        if (electronicLocks.length > 1) {
            return false;
        }
        return true;
    }

    hasElectronicLockColors(conf = this.configurationsService.conf.Current) {
        const chosenElectronicLockId = conf.doorHardware.electronicLock?.id;
        const electronicLockDetails = this.hardwareService.electronicLocks.find(lock => Number(lock.id) === chosenElectronicLockId);

        if (electronicLockDetails?.colors_ids?.length) {
            return false;
        }
        return true;
    }
}
