const snakeCase = require('snake-case');
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfigFactory } from '../config';
import { ConfiguratorsDataService } from './configurators-data.service';
import { IccColorGroup } from '../data-types/ColorGroup';
import { Common } from '../Common';


@Injectable({
    providedIn: 'root',
})
export class ColorsGroupsService {

    boxGroups: IccColorGroup[] = [];
    guideRailGroups: IccColorGroup[] = [];
    revisionGroups: IccColorGroup[] = [];
    boxInnerGroups: IccColorGroup[] = [];
    boxGuideOuterGroups: IccColorGroup[] = [];
    profileGroups: IccColorGroup[] = [];
    endslatGroups: IccColorGroup[] = [];
    boxSideSurfaceGroups: IccColorGroup[] = [];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {
    }

    /**
     * Funkcja znajdujaca grupe kolorow
     * @param  {object} color Kolor
     * @param  {object} conf  Konfiguracja
     */
    findGroupForColor(color, windowColorGroups, forPrices = false) {
        let gr: IccColorGroup[] = [];
        if (!Common.isObject(color)) {
            return gr;
        }
        gr = windowColorGroups.filter(
            el =>
                color.groups && color.groups.indexOf(+el.id) > -1
                && (!forPrices
                    || el.target.indexOf('price_roller') > -1)
        );
        return gr;
    }

    private getGuideRailsIds(conf) {
        const guideRailsIds = [];
        if (conf.RollerShutter.guideRails && conf.RollerShutter.guideRails.length) {
            for (let i = 0; i < conf.RollerShutter.guideRails.length; i++) {
                if (!guideRailsIds.includes(Number(conf.RollerShutter.guideRails[i].id))
                    && conf.RollerShutter.guideRails[i].id !== null) {
                    guideRailsIds.push(Number(conf.RollerShutter.guideRails[i].id));
                }
            }
        }
        return guideRailsIds;
    }

    private getSlatsIds(conf) {
        const slatsIds = [];
        if (conf.RollerShutter.slats && conf.RollerShutter.slats.length) {
            for (let i = 0; i < conf.RollerShutter.slats.length; i++) {
                if (!slatsIds.includes(Number(conf.RollerShutter.slats[i].id))
                    && conf.RollerShutter.slats[i].id !== null) {
                    slatsIds.push(Number(conf.RollerShutter.slats[i].id));
                }
            }
        }
        return slatsIds;
    }
}
