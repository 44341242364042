import { PriceFunc, PriceElemsData, PriceSegment, getListPrice } from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import { PriceShapeService } from './price-shape.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { Injectable, Inject } from '@angular/core';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { WindowActiveConfiguration } from '@icc/common';
import { DoorSizes } from '@icc/window';
import { IccSideColors } from '@icc/common/data-types';

@Injectable()
export class PriceGlazingService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private PriceShapeService: PriceShapeService,
        private colorsDefaultsService: ColorsDefaultsService
    ) {}

    /** Dopłata za obszar szklenia
     * @param  {Number} area            Obszar
     * @param  {Number} width           Szerokość
     * @param  {Number} height          Wysokość
     * @param  {Array}  supplements     Dopłaty
     * @return {Object}                 Czynnik
     */
    getGlazingAreaSupp(supplements, height, width, listPrice) {
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;
        let factor = 0;
        if (Common.isArray(supplements)) {
            supplements = supplements.sort((a, b) => b.factor - a.factor);
            for (let i = 0; i < supplements.length; i++) {
                if (
                    (supplements[i].min_width != null
                        && supplements[i].min_height != null
                        && Number(supplements[i].min_height) <= Number(height)
                        && Number(supplements[i].min_width) <= Number(width))
                    || (Number(supplements[i].area)
                        && Number(supplements[i].area) <= (width * height) / 1000000)
                ) {
                    factor = getListPrice(supplements[i], 'factor', listPrice, listPriceEnableInMarket) * 1;
                    break;
                }
            }
        }
        return factor;
    }

    /**
     * Cena ciepłej ramki
     * @param  {Object} PriceElems      Elementy wyceny
     * @param  {Number} width           Szerokość konstrukcji
     * @param  {Number} height          Wysokość konstrukcji
     * @param  {Object} warmEdge        Ciepły róg
     * @param  {Number} glazingCount    Ilość oszklenia
     * @param  {Object} system          Wybrany system
     * @return {Number}                 Wycena
     */
    priceWarmEdge(
        PriceElems,
        warmEdge,
        system,
        customPrice: CustomPricesRecords,
        field,
        sashId,
        intSashId?,
        listPrice?,
    ): PriceSegment {
        let supp = NaN;
        const glazingCount = ~~field.glazing.glazing_count;
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        let area, width, height;
        if (this.config().IccConfig.Configurators.price.warmEdge.areaOf === 'glazing') {
            height = field.glazingSizes.height;
            width = field.glazingSizes.width;
        } else {
            height = parseFloat(field.shape.height);
            width = parseFloat(field.shape.width);
        }

        area = core.round10(
            (width * parseFloat(height)) / 1000000,
            this.config().IccConfig.Configurators.fillingAreaPrecision
        );
        if (
            area < parseFloat(field.glazing.minBillingArea)
            && this.config().IccConfig.Configurators.price.warmEdge.minBillingArea
        ) {
            area = core.round10(
                parseFloat(field.glazing.minBillingArea),
                this.config().IccConfig.Configurators.fillingAreaPrecision
            );
        }

        if (Common.isArray(warmEdge.prices) && glazingCount > 1) {
            const prices = warmEdge.prices
                .map((el, index) => {
                    el.id = index;
                    return el;
                })
                .filter(el => ~~el.count === glazingCount);
            const priceBySystem = prices.filter(e => ~~e.window_line_id === ~~system.id);
            const priceNoSystem = prices.filter(e => !e.window_line_id);
            let warmEdgePrice = NaN;
            if (priceBySystem.length > 0) {
                if (Common.isObject(customPrice) && customPrice[warmEdge.id]) {
                    warmEdgePrice = customPrice[warmEdge.id].getPrice(
                        'prices',
                        priceBySystem[0].id,
                        'price'
                    );
                    if (warmEdgePrice === null) {
                        warmEdgePrice = parseFloat(getListPrice(priceBySystem[0], 'price', listPrice, listPriceEnableInMarket));
                    }
                } else {
                    warmEdgePrice = parseFloat(getListPrice(priceBySystem[0], 'price', listPrice, listPriceEnableInMarket));
                }
            } else if (priceNoSystem.length > 0) {
                if (Common.isObject(customPrice) && customPrice[warmEdge.id]) {
                    warmEdgePrice = customPrice[warmEdge.id].getPrice(
                        'prices',
                        priceNoSystem[0].id,
                        'price'
                    );
                } else {
                    warmEdgePrice = parseFloat(priceNoSystem[0].price);
                }
            } else if (prices.length > 0) {
                if (Common.isObject(customPrice) && customPrice[warmEdge.id]) {
                    warmEdgePrice = customPrice[warmEdge.id].getPrice(
                        'prices',
                        prices[0].id,
                        'price'
                    );
                } else {
                    warmEdgePrice = parseFloat(prices[0].price);
                }
            }
            supp = warmEdgePrice * area;
        }
        if (glazingCount < 2) {
            supp = 0;
        }

        PriceElems.warmEdge.push({
            id: warmEdge.id,
            name: warmEdge.name,
            area,
            price: supp,
        });
        return {
            type: 'warmEdge',
            baseValue: supp,
            value: supp,
            valueType: 'value',
            data: {
                id: warmEdge.id,
                name: warmEdge.name,
                area,
                sashId,
                isashId: intSashId,
            },
        };
    }

    /**
     * Dolicza dopłatę za ciepłą ramkę.
     * @param  {number} price       Cena wejściowa
     * @param  {object} PriceElems  Wycena
     * @param  {number} width       Szerokosć konstrukcji
     * @param  {number} height      Wysokosć konstrukcji
     * @return {number}             Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'warmEdge',
        data: {
            sashes: 'conf.Sashes',
            system: 'conf.System',
            warmEdge: 'conf.WarmEdge',
            hasWarmEdge: 'conf.HasWarmEdge',
            customPrice: 'price.WindowWarmEdge',
        },
    })
    suppWarmEdge(
        { PriceElems, listPrice }: PriceElemsData,
        {
            warmEdge,
            sashes,
            system,
            customPrice,
            hasWarmEdge,
        }: { warmEdge; sashes; system; hasWarmEdge; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let sash, i, j;
        const priceSegments: PriceSegment[] = [];

        if (!warmEdge || !hasWarmEdge) {
            return [];
        }

        for (i = 0; i < sashes.length; i++) {
            sash = sashes[i];
            if (Common.isUndefined(sash.intSashes) || sash.intSashes.length === 0) {
                if (Common.isDefined(sashes[i].glazing)) {
                    priceSegments.push(
                        this.priceWarmEdge(
                            PriceElems,
                            warmEdge,
                            system,
                            customPrice,
                            sashes[i],
                            sashes[i].id,
                            null,
                            listPrice
                        )
                    );
                }
            } else {
                for (j = 0; j < sash.intSashes.length; j++) {
                    if (Common.isDefined(sash.intSashes[j].glazing)) {
                        priceSegments.push(
                            this.priceWarmEdge(
                                PriceElems,
                                warmEdge,
                                system,
                                customPrice,
                                sash.intSashes[j],
                                sashes[i].id,
                                sash.intSashes[j].id,
                                listPrice
                            )
                        );
                    }
                }
            }
        }
        return priceSegments;
    }

    @PriceFunc({
        shortName: 'filling',
        data: {
            sashes: 'conf.Sashes',
            system: 'conf.System',
            shape: 'conf.Shape',
            conf: 'conf.type',
            shapeSupps: 'data.windowShapesFactors',
            areaSupps: 'data.glazingAreaFactors',
            dividers: 'conf.Mullions',
            colors: 'conf.Colors',
            model: 'conf.Model',
            customPrice: 'price.Filling',
            bondedGlazingUnitPrice: 'data.bondedGlazingUnitPrice',
            drawData: 'conf.drawData',
        },
    })
    suppFilling(
        { PriceElems, NoPriceCauses, listPrice }: PriceElemsData,
        {
            sashes,
            system,
            shape,
            conf,
            shapeSupps,
            areaSupps,
            dividers,
            colors,
            model,
            bondedGlazingUnitPrice,
            customPrice,
            drawData,
        }
    ): PriceSegment[] {
        const withBeads = this.config().IccConfig.Configurators.glazingPriceWithBeads;
        const priceSegments: PriceSegment[] = [];
        sashes.forEach((sash, index) => {
            let sashWidth = sash.rWidth;
            if (!sash.intSashes || sash.intSashes.length === 0) {
                priceSegments.push(
                    ...this.priceFillingSash(PriceElems, NoPriceCauses, listPrice, {
                        sash,
                        parentSash: sash,
                        shape,
                        shapeSupps,
                        sashWidth,
                        areaSupps,
                        system,
                        index,
                        withBeads,
                        colors,
                        model,
                        conf,
                        customPrice,
                        bondedGlazingUnitPrice,
                        drawData,
                    })
                );
            } else {
                sash.intSashes.forEach(intSash => {
                    sashWidth = intSash.rWidth;
                    priceSegments.push(
                        ...this.priceFillingSash(PriceElems, NoPriceCauses, listPrice, {
                            sash: intSash,
                            parentSash: sash,
                            shape,
                            shapeSupps,
                            sashWidth,
                            areaSupps,
                            system,
                            index,
                            withBeads,
                            colors,
                            model,
                            conf,
                            customPrice,
                            bondedGlazingUnitPrice,
                            drawData,
                        })
                    );
                });
            }
        });
        return priceSegments;
    }

    priceFillingSash(
        PriceElems,
        NoPriceCauses,
        listPrice = false,
        {
            sash,
            parentSash,
            shape,
            shapeSupps,
            sashWidth,
            areaSupps,
            system,
            index,
            withBeads,
            colors,
            model,
            conf,
            customPrice,
            bondedGlazingUnitPrice,
            drawData,
        }
    ): PriceSegment[] {
        let priceSegment: PriceSegment[] = [
            {
                type: 'filling',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {},
            },
        ];
        if (Common.isObject(sash.glazing)) {
            if (sash.glazing.type === 'glazing') {
                priceSegment = this.priceGlazing(PriceElems, NoPriceCauses, listPrice, {
                    sash,
                    parentSash,
                    shape,
                    shapeSupps,
                    sashWidth,
                    areaSupps,
                    system,
                    index,
                    withBeads,
                    model,
                    conf,
                    customPrice,
                    bondedGlazingUnitPrice,
                    drawData,
                });
            } else if (sash.glazing.type === 'pvc_panels') {
                priceSegment = this.pricePvcPanel(PriceElems, NoPriceCauses, listPrice, {
                    sash,
                    parentSash,
                    shape,
                    shapeSupps,
                    sashWidth,
                    areaSupps,
                    system,
                    index,
                    conf,
                    customPrice,
                });
            } else if ((sash.glazing.type === 'deco_panels' || sash.glazing.type === 'door_panels')) {
                priceSegment = this.priceDecoPanel(PriceElems, NoPriceCauses, listPrice, {
                    sash,
                    parentSash,
                    shape,
                    shapeSupps,
                    sashWidth,
                    system,
                    index,
                    colors,
                    conf,
                    customPrice,
                });
            }
        }
        return priceSegment;
    }

    // eslint-disable-next-line max-statements
    priceGlazing(
        PriceElems,
        NoPriceCauses,
        listPrice = false,
        {
            sash,
            parentSash,
            shape,
            shapeSupps,
            sashWidth,
            areaSupps,
            system,
            index,
            withBeads,
            model,
            conf,
            customPrice,
            bondedGlazingUnitPrice,
            drawData,
        }
    ): PriceSegment[] {
        if (
            Common.isDefined(sash)
            && Common.isDefined(sash.glazing)
            && Common.isDefined(sash.glazing.id)
        ) {
            const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

            let glazingPrice = parseFloat(getListPrice(sash.glazing, 'price_sq_m', listPrice, listPriceEnableInMarket));
            const glazingId = sash.glazing.id.split('.')[0];
            if (Common.isObject(customPrice) && customPrice[glazingId]) {
                glazingPrice = parseFloat(customPrice[glazingId].getPrice('price_sq_m'));
            }
            let beadsPrice = 0;
            if (
                Common.isObject(sash.glazing.system_prices)
                && !isNaN(parseFloat(sash.glazing.system_prices[system.id]))
            ) {
                const systemPrices = getListPrice(sash.glazing, 'system_prices', listPrice, listPriceEnableInMarket)
                let priceFromSystem = parseFloat(systemPrices[system.id]);
                if (Common.isObject(customPrice) && customPrice[glazingId]) {
                    priceFromSystem = parseFloat(
                        customPrice[glazingId].getPrice('WindowLine', system.id)
                    );
                }
                if (withBeads) {
                    beadsPrice = priceFromSystem;
                } else {
                    glazingPrice = priceFromSystem;
                }
            }
            if (
                (parentSash.type.type === 'DRA'
                    || parentSash.type.type === 'DRP'
                    || parentSash.type.type === 'DOA'
                    || parentSash.type.type === 'DOP')
                && (this.config().IccConfig.Configurators.door.version === 1
                    && !model.standard_fills)
                && Common.isObject(sash.glazing.door_model_prices)
                && Common.isDefined(sash.glazing.door_model_prices[model.door_models_group_id])
            ) {
                glazingPrice = parseFloat(
                    sash.glazing.door_model_prices[model.door_models_group_id]
                );
                if (Common.isObject(customPrice) && customPrice[glazingId]) {
                    glazingPrice = parseFloat(
                        customPrice[glazingId].getPrice('DoorModel', model.door_models_group_id)
                    );
                }
            }
            const confShapeSupps = this.PriceShapeService.getSuppShape(shape, system, shapeSupps);
            const sashShape = sash.shape.shape;
            const shapeSashSupps = this.PriceShapeService.getSuppShape(
                shape,
                system,
                shapeSupps,
                sash,
                sashShape
            );

            let area, width, height;
            if (
                this.config().IccConfig.Configurators.price.filling.glazing[conf].source[
                    system.type
                ] === 'glazing'
            ) {
                area = core.round10(
                    (sash.glazingSizes.width * sash.glazingSizes.height) / 1000000,
                    this.config().IccConfig.Configurators.fillingAreaPrecision
                );
                height = sash.glazingSizes.height;
                width = sash.glazingSizes.width;
            } else {
                area = core.round10(
                    (sashWidth * parseFloat(sash.shape.height)) / 1000000,
                    this.config().IccConfig.Configurators.fillingAreaPrecision
                );
                height = parseFloat(sash.shape.height);
                width = sashWidth;
            }

            const perimeter =
                ((parseFloat(sash.glazingSizes.width) + parseFloat(sash.glazingSizes.height)) * 2)
                / 1000;
            if (area < parseFloat(sash.glazing.minBillingArea)) {
                area = core.round10(
                    parseFloat(sash.glazing.minBillingArea),
                    this.config().IccConfig.Configurators.fillingAreaPrecision
                );
            }

            let unit = 'm2';
            if (
                parentSash.type.type === 'DRA'
                || parentSash.type.type === 'DRP'
                || parentSash.type.type === 'DOA'
                || parentSash.type.type === 'DOP'
            ) {
                if (this.config().IccConfig.Configurators.door.version > 1) {
                    unit = this.config().IccConfig.Configurators.price.filling.glazing.door.unit;
                } else {
                    if (
                        model.standard_fills
                        && this.config().IccConfig.Configurators.price.filling.glazing.door.unit
                            === 'unit+m2'
                    ) {
                        unit = this.config().IccConfig.Configurators.price.filling.glazing.door
                            .unit;
                    } else if (
                        !model.standard_fills
                        && this.config().IccConfig.Configurators.price.filling.glazing.door.unit
                            !== 'unit+m2'
                    ) {
                        unit = this.config().IccConfig.Configurators.price.filling.glazing.door
                            .unit;
                    } else {
                        unit = 'm2';
                    }
                }
            } else {
                unit = this.config().IccConfig.Configurators.price.filling.glazing.window.unit;
            }

            const areaSupp = this.getGlazingAreaSupp(areaSupps, height, width, listPrice);
            let priceOversize = (glazingPrice * areaSupp) / 100;
            if (
                this.config().IccConfig.Configurators.price.filling.suppOversizeToBase
                && this.config().IccConfig.Settings.glazingBasePrice
            ) {
                priceOversize =
                    ((this.config().IccConfig.Settings.glazingBasePrice + glazingPrice) * areaSupp)
                    / 100;
            }

            let bondedGlazingPrice = 0;
            if (
                this.config().IccConfig.Configurators.bondedGlazingAvailable
                && sash.bondedGlazing
            ) {
                const fillingDimensionsData = drawData.filling.find(o => o.sashId === sash.id);
                const realPerimeter = fillingDimensionsData.polyEdge;
                bondedGlazingPrice = realPerimeter * parseFloat(bondedGlazingUnitPrice);
            }

            const priceM2 =
                (glazingPrice + priceOversize) * area + beadsPrice * perimeter + bondedGlazingPrice;
            const priceUnit = glazingPrice + beadsPrice * perimeter + bondedGlazingPrice;
            let price = priceM2;
            if (unit === 'unit') {
                price = priceUnit;
            } else if (unit === 'unit+m2') {
                price = priceUnit + priceM2;
            }

            let priceShape = glazingPrice * area;
            if (unit === 'unit') {
                priceShape = glazingPrice;
            } else if (unit === 'unit+m2') {
                priceShape = glazingPrice + glazingPrice * area;
            }
            if (
                this.config().IccConfig.Settings.glazingBasePrice
                && this.config().IccConfig.Configurators.price.filling.suppShapeToBase
            ) {
                priceShape = this.config().IccConfig.Settings.glazingBasePrice;
            }

            let shapeSupp = confShapeSupps[0];
            if (shapeSashSupps.length) {
                shapeSupp = shapeSashSupps[0];
            }
            if (shapeSupp && !isNaN(shapeSupp.factor_glazing)) {
                priceShape *= shapeSupp.factor_glazing / 100;
            } else {
                priceShape = 0;
            }

            return [
                {
                    type: 'filling',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        u: sash.glazing.u,
                        sashId: parentSash.id,
                        sashIndex: parentSash.index,
                        isashId: sash.parentId != null ? sash.id : null,
                        isashIndex: sash.parentId != null ? sash.index : null,
                        factor: 0,
                        factorArea: areaSupp,
                        name: sash.glazing.name,
                        producer: sash.glazing.producer,
                        area,
                        perimeter,
                        index,
                        glazingCount: sash.glazing.glazing_count,
                        glazingPrice,
                        beadsPrice,
                    },
                },
                {
                    type: 'fillingShape',
                    baseValue: priceShape,
                    value: priceShape,
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        u: sash.glazing.u,
                        sashId: parentSash.id,
                        sashIndex: parentSash.index,
                        isashId: sash.parentId != null ? sash.id : null,
                        isashIndex: sash.parentId != null ? sash.index : null,
                        factor: shapeSupp ? shapeSupp.factor_glazing : 0,
                        factorArea: areaSupp,
                        shape: shape.shape,
                        name: sash.glazing.name,
                        producer: sash.glazing.producer,
                        area,
                        perimeter,
                        index,
                        glazingCount: sash.glazing.glazing_count,
                        glazingPrice,
                        beadsPrice,
                    },
                },
            ];
        } else {
            NoPriceCauses.push('no glazing in sash');
            return [
                {
                    type: 'filling',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {},
                },
            ];
        }
    }

    pricePvcPanel(
        PriceElems,
        NoPriceCauses,
        listPrice = false,
        {
            sash,
            parentSash,
            shape,
            shapeSupps,
            sashWidth,
            areaSupps,
            system,
            index,
            conf,
            customPrice,
        }
    ): PriceSegment[] {
        if (
            Common.isDefined(sash)
            && Common.isDefined(sash.glazing)
            && Common.isDefined(sash.glazing.id)
        ) {
            let panelPrice = this.getPanelPrice(
                sash.glazing,
                system,
                sash.glazing.selectedColor,
                customPrice,
                listPrice
            );
            if (
                Common.isObject(sash.glazing.system_prices)
                && !isNaN(parseFloat(sash.glazing.system_prices[system.id]))
                && panelPrice !== null
            ) {
                const glazingId = sash.glazing.id.split('.')[0];
                const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

                let priceFromSystem = parseFloat(getListPrice(sash.glazing, 'system_prices', listPrice, listPriceEnableInMarket, null, system.id));
                if (Common.isObject(customPrice) && customPrice[glazingId]) {
                    priceFromSystem = parseFloat(
                        customPrice[glazingId].getPrice('WindowLine', system.id)
                    );
                }
                panelPrice += priceFromSystem;
            }
            if (isNaN(parseFloat(panelPrice))) {
                NoPriceCauses.push('no price for panel');
                panelPrice = NaN;
            }

            const confShapeSupps = this.PriceShapeService.getSuppShape(shape, system, shapeSupps);
            const sashShape = sash.shape.shape;
            const shapeSashSupps = this.PriceShapeService.getSuppShape(
                shape,
                system,
                shapeSupps,
                sash,
                sashShape
            );

            let area, height, width;
            if (
                this.config().IccConfig.Configurators.price.filling.glazing[conf].source[
                    system.type
                ] === 'glazing'
            ) {
                area = core.round10(
                    (sash.glazingSizes.width * sash.glazingSizes.height) / 1000000,
                    this.config().IccConfig.Configurators.fillingAreaPrecision
                );
                height = sash.glazingSizes.height;
                width = sash.glazingSizes.width;
            } else {
                area = core.round10(
                    (sashWidth * parseFloat(sash.shape.height)) / 1000000,
                    this.config().IccConfig.Configurators.fillingAreaPrecision
                );
                height = parseFloat(sash.shape.height);
                width = sashWidth;
            }

            if (area < parseFloat(sash.glazing.minBillingArea)) {
                area = core.round10(
                    parseFloat(sash.glazing.minBillingArea),
                    this.config().IccConfig.Configurators.fillingAreaPrecision
                );
            }

            const areaSupp = this.getGlazingAreaSupp(areaSupps, height, width, listPrice);
            let priceOversize = (panelPrice * areaSupp) / 100;
            if (
                this.config().IccConfig.Configurators.price.filling.suppOversizeToBase
                && this.config().IccConfig.Settings.glazingBasePrice
            ) {
                priceOversize =
                    ((this.config().IccConfig.Settings.glazingBasePrice + panelPrice) * areaSupp)
                    / 100;
            }

            const price = (panelPrice + priceOversize) * area;

            let priceShape = price;
            if (
                this.config().IccConfig.Settings.glazingBasePrice
                && this.config().IccConfig.Configurators.price.filling.suppShapeToBase
            ) {
                priceShape = this.config().IccConfig.Settings.glazingBasePrice;
            }
            let shapeSupp = confShapeSupps[0];
            if (shapeSashSupps.length) {
                shapeSupp = shapeSashSupps[0];
            }
            if (shapeSupp && !isNaN(shapeSupp.factor_glazing)) {
                priceShape *= shapeSupp.factor_glazing / 100;
            } else {
                priceShape = 0;
            }

            return [
                {
                    type: 'filling',
                    baseValue: isNaN(price) ? null : price,
                    value: isNaN(price) ? null : price,
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        u: sash.glazing.u,
                        sashId: parentSash.id,
                        sashIndex: parentSash.index,
                        isashId: sash.parentId != null ? sash.id : null,
                        isashIndex: sash.parentId != null ? sash.index : null,
                        factor: 0,
                        factorArea: areaSupp,
                        name: sash.glazing.name,
                        producer: sash.glazing.producer,
                        area,
                        index,
                        glazingCount: sash.glazing.glazing_count,
                    },
                },
                {
                    type: 'fillingShape',
                    baseValue: isNaN(priceShape) ? null : priceShape,
                    value: isNaN(priceShape) ? null : priceShape,
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        u: sash.glazing.u,
                        sashId: parentSash.id,
                        sashIndex: parentSash.index,
                        isashId: sash.parentId != null ? sash.id : null,
                        isashIndex: sash.parentId != null ? sash.index : null,
                        factor: shapeSupp ? shapeSupp.factor_glazing : 0,
                        factorArea: areaSupp,
                        shape: shape.shape,
                        name: sash.glazing.name,
                        producer: sash.glazing.producer,
                        area,
                        index,
                        glazingCount: sash.glazing.glazing_count,
                    },
                },
            ];
        } else {
            NoPriceCauses.push('no glazing in sash');
            return [
                {
                    type: 'filling',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {},
                },
            ];
        }
    }

    priceDecoPanel(
        PriceElems,
        NoPriceCauses,
        listPrice = false,
        { sash, parentSash, shape, shapeSupps, sashWidth, system, index, colors, conf, customPrice }
    ): PriceSegment[] {
        if (
            Common.isDefined(sash)
            && Common.isDefined(sash.glazing)
            && Common.isDefined(sash.glazing.id)
        ) {
            const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

            const panelColor = core.copy(sash.glazing.selectedColor);
            if (sash.glazing.type === 'door_panels'
                && parentSash.panelInner
                && parentSash.panelInner.id
                && parentSash.panelInner.selectedColor
            ) {
                panelColor.inner = parentSash.panelInner.selectedColor.inner;
            }

            let panelPrice = this.getPanelPrice(
                sash.glazing,
                system,
                panelColor,
                customPrice,
                listPrice
            );
            const fillingPrice = core.parseJson(sash.glazing.fillingPrices);
            const secondFillingPrice = core.parseJson(sash.glazing.secondFillingPrices);
            const panelInnerId = sash.panelInner?.id.split('.')[0];
            let addPriceForMixPanel = true;
            if(fillingPrice && fillingPrice[panelInnerId]) {
                fillingPrice[panelInnerId].fillingPrices = core.parseJson(fillingPrice[panelInnerId].fillingPrices) || {};
            }
            if(secondFillingPrice && secondFillingPrice[panelInnerId]) {
                secondFillingPrice[panelInnerId].fillingPrices = core.parseJson(secondFillingPrice[panelInnerId].fillingPrices) || {};
            }
            if(fillingPrice?.[panelInnerId]?.fillingPrices?.[system.id]
                && fillingPrice?.[panelInnerId]?.fillingPrices?.[system.id].firstPanelPrice !== null
                && (
                    (!fillingPrice[panelInnerId].doubleSide && fillingPrice?.[panelInnerId]?.fillingPrices?.[system.id].secondPanelPrice !== null)
                    || (fillingPrice[panelInnerId].doubleSide && fillingPrice?.[panelInnerId]?.fillingPrices?.[system.id].secondPanelInnerPrice !== null)
                )
            ) {
                addPriceForMixPanel = false;
                const glazingPriceFromSystem = parseFloat(getListPrice(fillingPrice[panelInnerId].fillingPrices[system.id], 'firstPanelPrice', listPrice, listPriceEnableInMarket, 'listFirstPanelPrice'));
                let panelInnerPriceFromSystem;
                if(fillingPrice[panelInnerId].doubleSide) {
                    panelInnerPriceFromSystem = parseFloat(getListPrice(fillingPrice[panelInnerId].fillingPrices[system.id], 'secondPanelInnerPrice', listPrice, listPriceEnableInMarket, 'listSecondPanelInnerPrice'));
                } else {
                    panelInnerPriceFromSystem = parseFloat(getListPrice(fillingPrice[panelInnerId].fillingPrices[system.id], 'secondPanelPrice', listPrice, listPriceEnableInMarket, 'listSecondPanelPrice'));
                }
                panelPrice += glazingPriceFromSystem
                panelPrice += panelInnerPriceFromSystem
            } else if(secondFillingPrice?.[panelInnerId]?.fillingPrices?.[system.id]
                && secondFillingPrice?.[panelInnerId]?.fillingPrices?.[system.id].secondPanelPrice !== null
                && (
                    (!secondFillingPrice[panelInnerId].doubleSide && secondFillingPrice?.[panelInnerId]?.fillingPrices?.[system.id].firstPanelPrice !== null)
                    || (secondFillingPrice[panelInnerId].doubleSide && secondFillingPrice?.[panelInnerId]?.fillingPrices?.[system.id].firstPanelInnerPrice !== null)
                )
            ) {
                addPriceForMixPanel = false;
                const glazingPriceFromSystem = parseFloat(getListPrice(secondFillingPrice[panelInnerId].fillingPrices[system.id], 'secondPanelPrice', listPrice, listPriceEnableInMarket, 'listSecondPanelPrice'));
                let panelInnerPriceFromSystem;
                if(secondFillingPrice[panelInnerId].doubleSide) {
                    panelInnerPriceFromSystem = parseFloat(getListPrice(secondFillingPrice[panelInnerId].fillingPrices[system.id], 'firstPanelInnerPrice', listPrice, listPriceEnableInMarket, 'listFirstPanelInnerPrice'));
                } else {
                    panelInnerPriceFromSystem = parseFloat(getListPrice(secondFillingPrice[panelInnerId].fillingPrices[system.id], 'firstPanelPrice', listPrice, listPriceEnableInMarket, 'listFirstPanelPrice'));
                }
                panelPrice += glazingPriceFromSystem
                panelPrice += panelInnerPriceFromSystem
            } else if (
                Common.isObject(sash.glazing.system_prices)
                && !isNaN(parseFloat(sash.glazing.system_prices[system.id]))
                && panelPrice !== null
            ) {
                const glazingId = sash.glazing.id.split('.')[0];
                let priceFromSystem = parseFloat(getListPrice(sash.glazing, 'system_prices', listPrice, listPriceEnableInMarket, null, system.id));
                if (Common.isObject(customPrice) && customPrice[glazingId]) {
                    priceFromSystem = parseFloat(
                        customPrice[glazingId].getPrice('WindowLine', system.id)
                    );
                }
                panelPrice += priceFromSystem;
            }
            if (
                this.config().IccConfig.Settings.glazingBasePrice
                && this.config().IccConfig.Configurators.price.filling.subBaseFromDeco
                && panelPrice !== null
            ) {
                let area;
                if (
                    this.config().IccConfig.Configurators.price.filling.glazing[conf].source[
                        system.type
                    ] === 'glazing'
                ) {
                    area = core.round10(
                        (sash.glazingSizes.width * sash.glazingSizes.height) / 1000000,
                        this.config().IccConfig.Configurators.fillingAreaPrecision
                    );
                } else {
                    area = core.round10(
                        (sashWidth * parseFloat(sash.shape.height)) / 1000000,
                        this.config().IccConfig.Configurators.fillingAreaPrecision
                    );
                }

                if (area < parseFloat(sash.glazing.minBillingArea)) {
                    area = core.round10(
                        parseFloat(sash.glazing.minBillingArea),
                        this.config().IccConfig.Configurators.fillingAreaPrecision
                    );
                }
                panelPrice -= this.config().IccConfig.Settings.glazingBasePrice * area;
            }

            if (sash.glazing.type === 'door_panels' && parentSash.panelInner && parentSash.panelInner.id && panelPrice !== null && addPriceForMixPanel) {
                if (parentSash.panelInner.id === sash.glazing.id) {
                    panelPrice += parseFloat(sash.glazing.door_double_side_price) || 0;
                } else if (parentSash.panelInner.is_flat || sash.glazing.is_flat) {
                    panelPrice += parseFloat(sash.glazing.door_single_side_price) || 0;
                } else if (parentSash.panelInner.id !== sash.glazing.id) {
                    panelPrice += parseFloat(sash.glazing.door_mixed_side_price) || 0;
                }
            }

            if (isNaN(parseFloat(panelPrice))) {
                NoPriceCauses.push('no price for panel');
                panelPrice = NaN;
            }

            let glazingPrice = 0;
            if (parentSash.panelGlazing && parentSash.panelGlazing.price_sq_m) {
                glazingPrice = parseFloat(getListPrice(parentSash.panelGlazing, 'price_sq_m', listPrice, listPriceEnableInMarket));
            }
            let trimPrice = 0;
            if (parentSash.panelTrim && parentSash.panelTrim.price) {
                trimPrice += parseFloat(getListPrice(parentSash.panelTrim, 'price', listPrice, listPriceEnableInMarket));
            }
            if (sash.glazing.type === 'door_panels' && parentSash.panelInner && parentSash.panelInner.id && parentSash.innerPanelTrim && parentSash.innerPanelTrim.price) {
                trimPrice += parseFloat(getListPrice(parentSash.innerPanelTrim, 'price', listPrice, listPriceEnableInMarket));
            }

            const confShapeSupps = this.PriceShapeService.getSuppShape(shape, system, shapeSupps);
            const sashShape = sash.shape.shape;
            const shapeSashSupps = this.PriceShapeService.getSuppShape(
                shape,
                system,
                shapeSupps,
                sash,
                sashShape
            );

            let priceShape = panelPrice + glazingPrice + trimPrice;
            if (
                this.config().IccConfig.Settings.glazingBasePrice
                && this.config().IccConfig.Configurators.price.filling.suppShapeToBase
            ) {
                priceShape = this.config().IccConfig.Settings.glazingBasePrice;
            }
            let shapeSupp = confShapeSupps[0];
            if (shapeSashSupps.length) {
                shapeSupp = shapeSashSupps[0];
            }
            if (shapeSupp && !isNaN(shapeSupp.factor_glazing)) {
                priceShape *= getListPrice(shapeSupp, 'factor_glazing', listPrice, listPriceEnableInMarket) / 100;
            } else {
                priceShape = 0;
            }
            const price = panelPrice + glazingPrice + trimPrice;

            return [
                {
                    type: 'filling',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        u: sash.glazing.u,
                        sashId: parentSash.id,
                        sashIndex: parentSash.index,
                        isashId: sash.parentId != null ? sash.id : null,
                        isashIndex: sash.parentId != null ? sash.index : null,
                        factor: 0,
                        name: sash.glazing.name,
                        producer: sash.glazing.producer,
                        index,
                        glazingCount: sash.glazing.glazing_count,
                        panelPrice,
                        glazingPrice,
                        trimPrice,
                    },
                },
                {
                    type: 'fillingShape',
                    baseValue: priceShape,
                    value: priceShape,
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        u: sash.glazing.u,
                        sashId: parentSash.id,
                        sashIndex: parentSash.index,
                        isashId: sash.parentId != null ? sash.id : null,
                        isashIndex: sash.parentId != null ? sash.index : null,
                        factor: shapeSupp ? shapeSupp.factor_glazing : 0,
                        shape: shape.shape,
                        name: sash.glazing.name,
                        producer: sash.glazing.producer,
                        index,
                        glazingCount: sash.glazing.glazing_count,
                        panelPrice,
                        glazingPrice,
                        trimPrice,
                    },
                },
            ];
        } else {
            NoPriceCauses.push('no glazing in sash');
            return [
                {
                    type: 'filling',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {},
                },
            ];
        }
    }

    getPanelPrice(glazing, system, colors: IccSideColors, customPrice, listPrice = false) {
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        const panelPrices = core.parseJson(glazing.panel_prices) || [];
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';

        if (colors?.inner?.colorGroups && colors?.outer?.isCore && !colors?.inner?.isCore) {
            colorGroups = colors.inner.colorGroups.map(Number);
            side = 'single';
        } else if (colors?.outer?.colorGroups && colors?.inner?.isCore && !colors?.outer?.isCore) {
            colorGroups = colors.outer.colorGroups.map(Number);
            side = 'single';
        } else if (colors?.inner?.colorGroups && colors?.outer?.colorGroups && colors?.inner.id === colors?.outer.id) {
            colorGroups = colors.inner.colorGroups.map(Number);
        } else if (colors?.inner?.colorGroups && colors?.outer?.colorGroups && colors?.inner.id !== colors?.outer.id) {
            colorGroups = colors.inner.colorGroups.map(Number);
            colorGroupsOut = colors.outer.colorGroups.map(Number);
            side = 'bicolor';
        } else {
            return null;
        }
        const panelPrice = panelPrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter(
                p =>
                    p.material === system.type
                    && Common.isArray(colorGroups)
                    && colorGroups.indexOf(Number(p.colorGroup)) > -1
                    && (side !== 'bicolor'
                        || (side === 'bicolor'
                            && Common.isArray(colorGroupsOut)
                            && colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1))
                    && p.side === side
            )[0];
        if (panelPrice) {
            let panelPriceCustom;
            if (Common.isObject(customPrice) && customPrice[glazing.id]) {
                panelPriceCustom = parseFloat(
                    customPrice[glazing.id].getPrice('panel_prices', panelPrice.id, 'price')
                );
            }
            if (panelPriceCustom && !isNaN(panelPriceCustom)) {
                return panelPriceCustom;
            }
            return getListPrice(panelPrice, 'price', listPrice, listPriceEnableInMarket, 'listPrice');
        }
        return NaN;
    }

    @PriceFunc({
        shortName: 'panelType',
        data: {
            sashes: 'conf.Sashes',
            settings: 'data.panelsSettings',
        },
    })
    suppPanelType({ PriceElems, listPrice }: PriceElemsData, { sashes, settings }): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        sashes
            .filter(sash => sash.glazing && sash.glazing.type === 'deco_panels')
            .forEach(sash => {
                const supp = parseFloat(getListPrice(sash.glazing, 'price', listPrice, listPriceEnableInMarket));
                PriceElems.panelTypes.push({
                    id: sash.glazing.id,
                    sashId: sash.id,
                    sashIndex: sash.index,
                    isashId: null,
                    isashIndex: null,
                    price: supp,
                    panelType: sash.panelType,
                });
                priceSegments.push({
                    type: 'panelTypes',
                    baseValue: supp,
                    value: supp,
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        sashId: sash.id,
                        sashIndex: sash.index,
                        isashId: null,
                        isashIndex: null,
                        panelType: sash.panelType,
                    },
                });
            });
        return priceSegments;
    }

    @PriceFunc({
        shortName: 'panelSize',
        data: {
            sashes: 'conf.Sashes',
            system: 'conf.System',
            panelSizes: 'data.panelSizes',
            doorSizes: 'conf.doorSizes'
        },
    })
    suppPanelSize({ PriceElems, listPrice }: PriceElemsData, { sashes, panelSizes, system, doorSizes }): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? this.config().listPriceEnableInMarket : true;

        sashes
            .filter(sash => sash.glazing && (sash.glazing.type === 'deco_panels' || sash.glazing.type === 'door_panels'))
            .forEach(sash => {
                const glazingSizes = sash.intSashes && sash.intSashes[0] && sash.intSashes[0].glazingSizes || sash.glazingSizes;
                const panelWidth = glazingSizes.width || sash.shape.width;
                const panelHeight = glazingSizes.height || sash.shape.height;

                let doorSizeId = doorSizes?.sashSizeId;
                if (sash.type && (sash.type.type === 'DRP' || sash.type.type === 'DOP')) {
                    doorSizeId = doorSizes?.passiveSashSizeId;
                }
                if (!Common.isArray(panelSizes)) {
                    panelSizes = [];
                }
                const supp = panelSizes
                    .filter((el) => {
                        if (isNaN(parseInt(el.min_width))) {
                            el.width_from = 0;
                        }
                        if (isNaN(parseInt(el.max_width))) {
                            el.width_to = Infinity;
                        }
                        if (isNaN(parseInt(el.min_height))) {
                            el.height_from = 0;
                        }
                        if (isNaN(parseInt(el.max_height))) {
                            el.height_to = Infinity;
                        }
                        return (
                            el.material === system.type
                            && ((Number(panelWidth) <= Number(el.max_width) && Number(panelWidth) >= Number(el.min_width))
                                || (Number(panelHeight) <= Number(el.max_height) && Number(panelHeight) >= Number(el.min_height))
                                || this.isSystemWithSashSizeWithoutShorteningSelected(doorSizeId, doorSizes, el, system)
                                || this.isSystemWithSashSizeAndShorteningSelected(doorSizeId, doorSizes, el, system)
                            )
                            && (
                                !el.window_lines_ids
                                || !el.window_lines_ids.length
                                || el.window_lines_ids.map(Number).includes(Number(system.id))
                            )
                        );
                    })
                    .reduce(
                        (prev, cur) => {
                            if (parseFloat(cur.price) > parseFloat(prev.price)) {
                                prev.price = getListPrice(cur, 'price', listPrice, listPriceEnableInMarket);
                            }
                            if (parseFloat(cur.price_percent) > parseFloat(prev.price_percent)) {
                                prev.price_percent = getListPrice(cur, 'price_percent', listPrice, listPriceEnableInMarket);
                            }
                            return prev;
                        },
                        { price: 0, price_percent: 0 }
                    );

                priceSegments.push({
                    type: 'panelSizes',
                    baseValue: parseFloat(supp.price),
                    value: parseFloat(supp.price),
                    valueType: 'value',
                    data: {
                        id: sash.glazing.id,
                        sashId: sash.id,
                        sashIndex: sash.index,
                        isashId: null,
                        isashIndex: null,
                        panelType: sash.panelType,
                        width: panelWidth,
                        height: panelHeight,
                    },
                });

                priceSegments.push({
                    type: 'panelSizes',
                    baseValue: (parseFloat(supp.price_percent) + 100) / 100,
                    value: (parseFloat(supp.price_percent) + 100) / 100,
                    valueType: 'percent',
                    data: {
                        id: sash.glazing.id,
                        sashId: sash.id,
                        sashIndex: sash.index,
                        isashId: null,
                        isashIndex: null,
                        panelType: sash.panelType,
                        width: panelWidth,
                        height: panelHeight,
                    },
                });
            });
        return priceSegments;
    }

    private isSystemWithSashSizeWithoutShorteningSelected(doorSizeId: number, doorSizes: DoorSizes, el, system) {
        return (
            doorSizeId &&
            doorSizeId === Number(el.door_size_id) &&
            el.shortening_max == null &&
            el.shortening_min == null &&
            system.shortening.length === 0
        );
    }

    private isSystemWithSashSizeAndShorteningSelected(doorSizeId: number, doorSizes: DoorSizes, el, system) {
        return (
            doorSizeId &&
            doorSizeId === Number(el.door_size_id) &&
            el.shortening_max != null && doorSizes?.shortening <= Number(el.shortening_max) &&
            el.shortening_min != null && doorSizes?.shortening >= Number(el.shortening_min) &&
            system.shortening.length > 0
        );
    }

    getFillingsForConstructionPart(part, conf: WindowActiveConfiguration) {
        const fillingsIds = conf.Sashes.filter(
            sash => part.sashIds.map(Number).indexOf(Number(sash.id)) > -1
        ).reduce(
            (ids, sash) => {
                ['left', 'right', 'top', 'bottom'].forEach(side => {
                    if (
                        sash.glazing
                        && sash.glazing.id
                        && ids.indexOf(parseInt(sash.glazing.id)) === -1
                    ) {
                        ids.push(parseInt(sash.glazing.id));
                    }
                });
                return ids;
            },
            []
        );

        return fillingsIds;
    }
}
