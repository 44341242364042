<icc-select-box
    *ngIf="hardwareSetSelect"
    [title]="'DOOR|Zestaw osprzętu' | translate"
    [description]="hardwareSetSelect.name"
    [imageUrl]="'/files/hardwareset/' + (hardwareSetSelect?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeHardwareSet()"
    [descriptionTemplate]="hardwareSetBoxTemplate"
    [descriptionContext]="hardwareSetSelect"
    [disabledButton1]="isChangeHardwareSetDisabled()"
></icc-select-box>

<icc-select-box
    *ngIf="(lock$ | async)"
    [title]="'DOOR|Zamek' | translate"
    [description]="(lock$ | async)?.name"
    [imageUrl]="'/files/windowaccessory/' + ((lock$ | async)?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeLock()"
    [descriptionTemplate]="locksBoxTemplate"
    [descriptionContext]="lockBoxSelect"
    [disabledButton1]="isLockButtonDisabled()"
></icc-select-box>
<icc-select-box
    [title]="'DOOR|Przystosowanie pod pochwyt' | translate"
    [description]="(doorHandleAdjustment$ | async)?.name"
    [imageUrl]="'/files/windowaccessory/' + ((doorHandleAdjustment$ | async)?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeDoorHandleAdjustment()"
    *ngIf = "(showWindowLeverBox$ | async) && (showDoorHandleAdjustmentBox$ | async)"
></icc-select-box>
<icc-select-box
    [title]="'DOOR|Typ osprzętu' | translate"
    [description]="(selectedHardwareType$ | async)?.name"
    [imageUrl]="'/files/windowaccessory/' + ((selectedHardwareType$ | async)?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="(selectedHardwareType$ | async)?.id && ('INTERFACE|Opcje' | translate)"
    (select)="selectType()"
    (select2)="changeTypeOptions()"
    *ngIf = "showWindowLeverBox$ | async"
    [descriptionTemplate]="typeBoxTemplate"
    [descriptionContext]="hardwareTypeBoxSelect"
></icc-select-box>
<icc-select-box
    *ngIf = "(pull$ | async) && ((hardwareSet$ | async) === 'leverPull' && ((doorHandleAdjustment$ | async)?.id && (showWindowLeverBox$ | async) || !(showWindowLeverBox$ | async))) || (hardwareSet$ | async) === 'doublePull'"
    [title]="'DOOR|Pochwyt' | translate"
    [imageUrl]="pullBoxSelect.pullType.imagePath"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="isPullColorRelatedToPlate !== true ? ('INTERFACE|Opcje' | translate) : false"
    (select)="changePull()"
    (select2)="openPullsOptions()"
    [descriptionTemplate]="pullBoxTemplate"
    [descriptionContext]="pullBoxSelect"
    [disabledButton1]="isPullButtonDisabled()"
    [disabledButton2]="isPullButton2Disabled()"
    ></icc-select-box>
<icc-select-box
    *ngIf="integratedPull"
    [title]="'DOOR|Pochwyt' | translate"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    [descriptionTemplate]="integratedPullBoxTemplate"
    [disabledButton1]="true"
    [disabledButton2]="true"
    [minHeight]="true"
    ></icc-select-box>
<icc-select-box
    [title]="'DOOR|Typ elektronicznej wkładki' | translate"
    [description]="(electronicLock$ | async)?.name"
    [imageUrl]="'/files/windowaccessory/' + ((electronicLock$ | async)?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeElectronicLock()"
    *ngIf = "(selectedHardwareType$ | async)?.electronic_door_lock"
></icc-select-box>
<icc-select-box
    [title]="'DOOR|Typ oszyldowania' | translate"
    [description]="(plateType$ | async)?.name"
    [imageUrl]="'/files/windowaccessory/' + ((plateType$ | async)?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changePlateType()"
    *ngIf = "(showWindowLeverBox$ | async) && (selectedHardwareType$ | async)?.id"
></icc-select-box>
<icc-select-box
    *ngIf=" ((plateMainLock$ | async) || (plateAdditionalLock$ | async)) && !(showWindowLeverBox$ | async) && (hardwareSet$ | async)"
    [title]="'DOOR|Szyldy' | translate"
    [imageUrl]="(plateMainLockBoxSelect$ | async)?.imagePath"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="openPlatesOptions()"
    (select2)="changePlateToMainLockOptions()"
    [descriptionTemplate]="platesBoxTemplate"
    [descriptionContext]="platesBoxSelect"
    [disabledButton1]="isPlatesButtonDisabled()"
    [disabledButton2]="isPlatesToMainLockButton2Disabled()"
></icc-select-box>
<icc-select-box
    [title]="'DOOR|Wkładki' | translate"
    [imageUrl]="(cylindersBoxSelect?.cylinders.imagePath || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="((isKeyAvailableForMainLock || cylindersBoxSelect?.cylinders?.color) && 'INTERFACE|Opcje' | translate)"
    (select)="openCylindersOptions()"
    (select2)="changeCylinderToMainLockOptions()"
    *ngIf = "!(showWindowLeverBox$ | async) && (hardwareSet$ | async) && isCylinderBoxVisible()"
    [descriptionTemplate]="cylindersBoxTemplate"
    [descriptionContext]="cylindersBoxSelect"
    [disabledButton1]="isCylinderMainLockButtonDisabled()"
    [disabledButton2]="isCylinderMainLockButton2Disabled()"
></icc-select-box>
<icc-select-box
    [title]="'DOOR|Wizjer' | translate"
    [description]="(doorViewer$ | async)?.name"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [imageUrl]="(doorViewer$ | async)?.img ? ('/files/windowaccessory/' + ((doorViewer$ | async)?.img)) : '/img/door/wizjer_brak.jpg'"
    (select)="changeDoorViewer()"
    *ngIf="(showDoorViewer$ | async)"
    [disabledButton1]="isDoorViewerButtonDisabled()"
></icc-select-box>

<mat-card  *ngIf="(contextInfoInDoorViewer$ | async)">
    <mat-card-content>
      <p>
        {{(contextInfoInDoorViewer$ | async)}}
      </p>
    </mat-card-content>
</mat-card>

<mat-slide-toggle
    (change)="changeDoorViewerPunching($event)"
    [checked]="doorViewerPunching$ | async"
    *ngIf="!(showWindowLeverBox$ | async) && (doorViewer$ | async)?.id && (showDoorViewerPunchingOption$ | async)"
>
    {{ 'DOOR|Otworowanie pod wizjer' | translate }}
</mat-slide-toggle>

<icc-select-box
    *ngIf="(hinge$ | async)"
    [title]="'DOOR|Zawiasy' | translate"
    [description]="(hinge$ | async)?.name"
    [imageUrl]="(hingeBoxSelect$ | async)?.imagePath"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeHinges()"
    [disabledButton1]="isHingeButtonDisabled()"
></icc-select-box>
<icc-select-box
    [title]="'DOOR|Ozdobne nakładki zawiasów' | translate"
    [description]="(decorativeHingeCoversColor$ | async)?.name"
    [color]="'#' + (decorativeHingeCoversColor$ | async)?.color"
    [imageUrl]="'/files/windowhandlescolor/' + ((decorativeHingeCoversColor$ | async)?.color_img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeDecorativeHingeCover()"
    *ngIf="((showDecorativeHingeCovers$ | async) && (decorativeHingeCoversColor$ | async))"
    [colorSelect]="true"
    [disabledButton1]="isDecorativeHingeCoverButtonDisabled()"
></icc-select-box>

<ng-template #typeBoxTemplate let-name="name" let-color="color" let-description="description">
    <p> <b>{{ 'DOOR|Typ osprzętu:' | translate }}</b> {{name || ('INTERFACE|Brak' | translate)}}</p>
    <p *ngIf="color">
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{color}}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>


<ng-template #platesBoxTemplate let-mainLockName="mainLockName" let-additionalLockName="additionalLockName" let-color="color" let-description="description">
    <p *ngIf="mainLockName"> <b>{{ 'DOOR|Typ szyldu zamka głównego' | translate }}</b> {{mainLockName}}</p>
    <p *ngIf="additionalLockName"> <b>{{ 'DOOR|Typ szyldu zamka dodatkowego' | translate }}</b> {{additionalLockName}}</p>
    <p *ngIf="color">
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{color}}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>

<ng-template #cylindersBoxTemplate let-cylinders="cylinders">
    <p *ngIf="cylinders?.mainLockName"> <b>{{ 'DOOR|Rodzaj wkładki zamka głównego' | translate }}</b> {{cylinders.mainLockName}}</p>
    <p *ngIf="cylinders?.additionalLockName"> <b>{{ 'DOOR|Rodzaj wkładki zamka dodatkowego' | translate }}</b> {{cylinders.additionalLockName}}</p>
    <p *ngIf="cylinders?.color">
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{cylinders?.color}}
    </p>

    <!--
        /*
        Hidden until client describes how the cylinder system is supposed to work.
        */
    <p *ngIf="cylinders?.system"> <b>{{ 'DOOR|System wkładek' | translate }}</b> {{cylinders.system}}</p>-->
    <p *ngIf="electronicLockBoxSelect?.electronicLockName">
        <b>{{ 'DOOR|Typ elektronicznej wkładki'   | translate }}</b> {{ electronicLockBoxSelect.electronicLockName }}
    </p>
    <p *ngIf="electronicLockBoxSelect?.electronicLockColor">
        <b> {{ 'DOOR|Kolor' | translate}} </b> {{ electronicLockBoxSelect.electronicLockColor }}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>

<ng-template #pullBoxTemplate let-pull="pullType" let-color="color">
    <p> <b>{{ 'DOOR|Typ pochwytu' | translate }}</b> {{ pullBoxSelect.pullType?.name }} </p>
    <p *ngIf="pullBoxSelect.pullType?.color"> <b>{{ 'COLOR|Kolor:' | translate }}</b> {{ pullBoxSelect.pullType?.color}} </p>
</ng-template>
<ng-template #integratedPullBoxTemplate>
    <p> <b>{{ 'DOOR|Typ pochwytu' | translate }}</b> {{ 'DOOR|Pochwyt zintegrowany' | translate }} </p>
</ng-template>

<ng-template #locksBoxTemplate let-lock="lock">
    <p> <b>{{ 'DOOR|Typ zamka' | translate }}</b> {{ lockBoxSelect.lock.name }} </p>
</ng-template>

<ng-template #hardwareSetBoxTemplate let-hardwareSet="hardwareSet">
    <p> {{ hardwareSetSelect.name }} </p>
    <p> {{ hardwareSetSelect.shortDescription }} </p>
</ng-template>
